.uikit {
  h1.ui-kit-heading {
    @include margin-padding(bottom, 3.2rem, null, null);
  }

  .container-box {
    @include margin-padding(null, null, all, 4rem);
  }

  .buttons {
    h1 {
      @include margin-padding(top, 3rem, null, null);
    }

    button {
      display: block;
      @include margin-padding(bottom, 1.6rem, null, null);
    }

    p {
      margin: $no-margin;
    }
  }

  .form-elements {
    h1 {
      @include margin-padding(top, 5rem, null, null);
    }

    p.label {
      @include margin-padding(top, 2.4rem, null, null);
    }
  }

  .typography {
    .heading {
      @include margin-padding(all, 6.5rem 0 2.7rem 0, null, null);
    }

    p {
      @include margin-padding(bottom, 3.2rem, null, null);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include margin-padding(bottom, 0.8rem, null, null);
    }
  }

  .icons {
    .heading {
      @include margin-padding(all, 6.5rem 0 2.7rem 0, null, null);
    }

    .mat-list-base {
      float: left;

      .mat-list-item {
        @include margin-padding(null, null, all, 1.6rem);
      }
    }
  }

  .elements {
    .heading {
      @include margin-padding(all, 5rem 0 2.4rem, null, null);
    }
  }
}

.parent-div {
  @include margin-padding(right, 3rem, null, null);
}

.box {
  height: 8rem;
  width: 8rem;
  border-radius: $default-radius;
  display: inline-block;
  font-size: 1.4rem;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);

  &+p {
    @include margin-padding(top, 1rem, null, null);
  }
}
