.green-room {

  p.banner-quote {
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name p {
    color: color("shades", "white");
    //margin: 1rem 1rem 2rem 1rem !important;
    border-radius: 8px;
    font-size: 18px;
    background-color: rgb(17 17 17 / 75%);
    //margin: 0 1rem 0 0;
    padding: 0.8rem 1.4rem;
    border-radius: 4rem;
    // min-width:60%;
    max-width: 90%;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
  }

  .name {
    margin: 0px;
  }
}

/*==== Search css starts ====*/
.search {
  max-width: 29rem;
  width: 100%;

  @include breakpoint(x2large) {
    max-width: calc(100% - 18rem);
  }

  @include breakpoint(small) {
    max-width: calc(100% - 16rem);
  }

  &.mat-form-field-appearance-fill .mat-form-field-infix {
    @include margin-padding(null, null, all, 0);
    border: none;
  }

  input.mat-input-element {
    background-color: color("shades", "white");
    @include margin-padding(all, 0, left, 4rem);
    // @include box-shadow($box-shadow-white-container);

    &+.material-icons {
      @include abs-position(1.15rem, auto, 0, 1.5rem);
    }
  }
}

/*==== Search css ends ====*/

/*==== Login css starts ====*/
.login {
  .link {
    @include margin-padding(bottom, 0, null, null);
  }
}

.half-center {
  background-color: color("shades", "white");
  @include box-shadow($box-shadow-white-container);
  border-radius: $default-radius;
  max-width: 48.8rem;
  width: calc(100% - 4rem);
  @include margin-padding(all, 3rem auto, all, 4rem 6.4rem);

  .button {
    @include margin-padding(top, 1rem, null, null);

    &+p.link {
      @include margin-padding(all, 1.6rem 0 0, null, null);
    }
  }

  h1.mat-dialog-title {
    @include margin-padding(bottom, 1.2rem, null, null !important);

    @include breakpoint(small) {
      @include margin-padding(null, null, right, 5rem);
      font-size: 2.4rem;
    }

    @include breakpoint(small) {
      @include margin-padding(null, null, right, 0);
    }
  }
}

header.logo {
  height: 7rem;
  background-color: color("shades", "white");
  // border-radius: $default-radius;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);

  &.mat-dialog-container {
    @include margin-padding(null, null, all, 0 !important);
    height: 7rem !important;

    .logo-image {
      @include margin-padding(all, auto, null, null);
    }

    .mat-button.close {
      top: 0;
    }
  }
}

/*==== Login css ends ====*/

/*==== Sign up css starts ====*/

.signup.half-center {
  max-width: 56rem;

  .link {
    @include margin-padding(bottom, 0, null, null);
  }
}

/*==== Sign up css ends ====*/

/*==== Forgot passsword css starts ====*/

.forgot-password {
  .back {
    height: 5rem;
    width: 5rem;
    border: 0.1rem solid color("grey", "lighten-7");
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    border-radius: $default-radius;
    @include margin-padding(all, 0 0 1.6rem, null, null);
    position: static;
    background-color: color("shades", "transparent");

    .material-icons {
      font-size: 3rem;
    }
  }

  &.half-center {
    max-width: 53rem;
  }
}

/*==== Forgot passsword css ends ====*/

/*==== Plan css starts ====*/

.choose-plan {
  @include breakpoint(medium) {
    .row.flex.no-margin {
      display: block;
    }
  }

  .outer-div {
    border: 0.1rem solid color("grey", "lighten-7");
    background-color: color("shades", "white");

    .col.m6 {
      border-bottom: 0.1rem solid color("grey", "lighten-7");
      border-right: 0.1rem solid color("grey", "lighten-7");
    }

    &.col.l4 {
      border-left: 0;
      border-top-right-radius: 1.2rem;
      border-bottom-right-radius: 1.2rem;

      @include breakpoint(medium) {
        border-radius: 0;
        border-left: 0.1rem solid color("grey", "lighten-7");
        @include margin-padding(bottom, 3rem, null, null);
      }
    }

    &.col.l8 {
      border-right: 0;
      border-bottom: 0;
      border-top-left-radius: 1.2rem;
      border-bottom-left-radius: 1.2rem;

      @include breakpoint(medium) {
        border-radius: 0;
      }
    }

    .row {
      @include flexbox;
      flex-wrap: wrap;
    }
  }

  @include margin-padding(top, 4.6rem, null, null);

  .plan-content {
    @include margin-padding(null, null, all, 3rem);
    text-align: left;

    @media (max-width:#{$large-desktop-up}) {
      @include margin-padding(null, null, all, 2rem);
    }

    .note {
      h3 {
        @include margin-padding(top, 2rem, null, null);
      }

      p {
        line-height: 1.5;
        @include margin-padding(all, 0.2rem 0 0, null, null);
      }
    }

    @include breakpoint(medium) {
      @include margin-padding(null, null, all, 1.5rem 1rem);
    }

    .material-icons {
      font-size: 3rem;
      @include margin-padding(bottom, 2rem, null, null);
      height: 5rem;
      width: 5rem;
      background-color: color("grey", "lighten-8");
      border-radius: $circular-radius;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      cursor: default;

      @media (max-width:#{$large-desktop-up}) {
        @include margin-padding(bottom, 1.5rem, null, null);
      }

      &.top-icon {
        @include margin-padding(bottom, 2rem, null, null);
        background-color: color("primary", "light");
        color: color("accent", "base");
        font-size: 5rem;
        height: 8rem;
        width: 8rem;

        @media (max-width:#{$large-desktop-up}) {
          font-size: 4rem;
          height: 6rem;
          width: 6rem;
        }
      }
    }

    h2.big-text {
      font-size: 3rem;
    }

    p {
      line-height: 2.4rem;
      opacity: 0.7;
    }

    .mat-list {
      opacity: 0.7;

      .material-icons {
        font-size: 2.4rem;
        background-color: transparent;
        width: auto;
        height: auto;
        @include margin-padding(all, 0 0.8rem 0 0, null, null);
      }

      .mat-list-item {
        line-height: 3.4rem;

        .mat-list-item-content {
          padding: $no-padding;
        }
      }
    }

    h3 {
      text-align: left;
      @include margin-padding(all, 3.3rem 0 0, null, null);

      @media (max-width:#{$large-desktop-up}) {
        @include margin-padding(all, 2.3rem 0 0, null, null);
      }
    }
  }

  .continue {
    @include margin-padding(top, 2rem, null, null);
  }
}

/*==== Plan css ends ====*/

/*==== Payment css starts ====*/

.payment.half-center {
  max-width: 64rem;

  .back {
    height: 5rem;
    width: 5rem;
    border: 0.1rem solid color("grey", "lighten-7");
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    border-radius: $default-radius;
    @include margin-padding(all, 0 0 1.6rem, null, null);
    position: static;
    background-color: color("shades", "transparent");

    .material-icons {
      font-size: 3rem;
    }
  }

  .mat-list {
    background-color: color("grey", "lighten-9");
    border-radius: $default-radius;
    @include margin-padding(all, 1.6rem 0 1.2rem, null, null);
    border: 0.1rem solid color("grey", "lighten-7");

    .mat-list-item {
      border-bottom: 0.1rem solid color("grey", "lighten-7");
      @include margin-padding(null, null, all, 1.6rem);
      height: auto;

      .mat-list-item-content {
        display: block;
        width: 100%;
        clear: both;
        overflow: hidden;

        p {
          font-size: 1.8rem;
          overflow: hidden;
          @include margin-padding(all, 0, null, null);
        }
      }

      .title {
        width: 30%;
        float: left;
      }

      .description {
        font-weight: 600;
        width: 70%;
        float: left;
        line-height: 1.5;

        span {
          display: block;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }
}

/*==== Payment css ends ====*/

/*==== Dashboard css starts ====*/

.plan-expiry-reminder {
  background-color: color("accent", "base");
  @include flexbox;

  @include align-items(center);
  @include justify-content(flex-start);
  height: auto;
  width: calc(100% - 38.5rem);
  margin-top: 0 !important;
  margin-bottom: 0.5px !important;
  @include margin-padding(null, null, all, 0 4rem);
  padding: 0 2.5rem !important;
  float: right;
  position: relative;

  p {
    margin: 0;

    .material-icons {
      @include margin-padding(right, 0.8rem, null, null);
      color: color("shades", "white");

      @media (max-width:#{$medium-screen}) {
        @include abs-position(12px, auto, null, 10px);
      }
    }

    @include flexbox;
    @include align-items(center);
    padding: 0;
    color: color("shades", "white");

    @media (max-width:#{$medium-screen}) {
      display: block !important;
      margin: 1.5rem;
    }

    a {

      color: color("shades", "white");
      text-decoration: underline;
    }
  }

  .mat-button.mat-button-base {
    height: 3rem;
    color: color("shades", "white");
    border: 0.1rem solid color("shades", "white");
    line-height: 1.5;
    background-color: color("shades", "transparent") !important;

    // @include abs-position(0.4rem, 5.5rem, null, null);
    // @media (max-width:#{$medium-screen}) {
    //   position: absolute !important;
    //   bottom: 10px !important;
    //   left: 42px !important;
    //   top: inherit !important;
    //   right: inherit !important;
    // }
    @media (max-width:#{$medium-screen}) {
      align-items: center;
    }
  }

  .plan-expiry-reminder-reminder-button {
    width: 4rem !important;
  }
}

.filter-heading {
  margin-bottom: 2rem;

  background-color: color("shades", "white");

  .button-search-wrap {
    padding: 1rem 2.5rem !important;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  .row.flex {
    @include breakpoint(large) {
      // flex-wrap: wrap;
      justify-content: space-between;

      .row .col.l8 {
        width: 100%;
      }
    }

    @include breakpoint(small) {
      .col.m12.s12.flex {
        flex: 0 0 calc(100% - 5.8rem);
        @include justify-content(flex-end);
        flex-wrap: wrap;
      }
    }
  }

  @include breakpoint(x2large) {
    .justify-content-flex-end {
      @include justify-content(space-between !important);
    }
  }

  @include breakpoint(small) {
    flex-wrap: wrap;

    .button-medium {
      font-size: 1.4rem !important;

      .material-icons {
        display: none;
      }
    }
  }

  @media (max-width:#{$small-screen-up}) {
    .button-search-wrap {
      display: block !important;
    }
  }

  @include breakpoint(x-small) {}

  .date-picker {
    width: 13rem;
  }
}

.dashboard {
  @include margin-padding(all, 2rem 2rem 0, null, null);

  @include breakpoint(x2large) {
    @include margin-padding(all, 2rem 2rem 0, null, null);
  }

  @include breakpoint(medium) {
    @include margin-padding(all, 1.5rem 1.5rem 0, null, null);
  }

  @include breakpoint(small) {
    @include margin-padding(top, 1.5rem, null, null);
  }

  .account-heading {
    @include margin-padding(bottom, 3rem, null, null);
    display: inline-block;

    @include breakpoint(x2large) {
      @include margin-padding(all, 0 0 2.5rem, null, null);
    }
  }

  .recording-quality {
    position: relative;

    .info {
      @include abs-position(0, auto, auto, auto);
      height: 2.4rem;
      width: 2.4rem;
      font-size: 2.2rem;
      @include margin-padding(left, 0.8rem, null, null);
    }
  }

  .profile-details {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);

    .profile-heading {
      @include flexbox;
      @include justify-content(flex-end);
      @include align-items(center);

      h2 {
        margin-bottom: 1rem !important;
      }

      .change-pass-btn {
        @include margin-padding(left, 2rem, null, null);
      }

      @include breakpoint(small) {
        @include justify-content(flex-start);
        @include margin-padding(bottom, 1rem, null, null);
      }
    }

    .youtube-btn {
      @include margin-padding(left, 2rem, null, null);
    }

    @include breakpoint(small) {
      display: block;
    }

    @include breakpoint(x-small) {
      .button {
        @include margin-padding(all, 0, null, null);

        &.youtube-btn {
          @include margin-padding(left, 1rem, null, null);
        }

        &.youtube-btn,
        &.facebook-btn {
          @include margin-padding(null, null, all, 0.5rem 0.7rem !important);
        }
      }
    }
  }

  .save-btn {
    margin-top: 30px;

    @include breakpoint(medium) {
      @include margin-padding(top, 8px, null, null);
    }
  }

  .search-create-session {
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    @include margin-padding(bottom, 0, null, null);

    @include breakpoint(small) {
      @include justify-content(space-between);
      @include margin-padding(bottom, 1rem, null, null);

      .search {
        width: 70%;
        max-width: 100%;
      }
    }

    @include breakpoint(x-small) {
      flex-direction: column;

      .search {
        width: 100%;
        max-width: 100%;
      }
    }

    .button {
      @include margin-padding(left, 3.6rem, null, null);

      @include breakpoint(medium) {
        @include margin-padding(left, 1rem, null, null);
      }

      @include breakpoint(x-small) {
        width: 18rem;
        height: 4.6rem;
        @include abs-position(-0.6rem, 1.5rem, auto, auto);
        @include margin-padding(all, 0, null, null);
      }

      &:hover {
        .material-icons {
          color: color("accent", "base");
        }
      }
    }

    .mat-form-field-appearance-legacy .mat-form-field-infix {
      border: 0;
    }
  }

  .row.no-margin {
    @include flexbox;
    @include align-items(baseline);
    @include margin-padding(bottom, 3rem, null, null);
  }

  .data {
    @include margin-padding(top, 0.5rem, left, 1.8rem);
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    font-size: 1.4rem;
    text-transform: capitalize;

    @include breakpoint(medium) {
      @include margin-padding(all, 0, left, 0);
    }

    @media (max-width:#{$small-screen}) {
      flex-direction: column;
      @include align-items(flex-start);
      @include margin-padding(top, 0.8rem, null, null);
    }
  }

  .audio-container {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);

    @media (max-width:#{$small-screen}) {
      @include margin-padding(top, 0.8rem, null, null);
    }
  }

  .audio-player {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    font-size: $h5-fontsize;
    border-radius: $default-radius;
    background-color: lighten(color("primary", "light"), 5%);
    width: 100%;

    &+.more {
      @include breakpoint(small) {
        button {
          @include margin-padding(left, 0, null, null);
        }
      }
    }

    .mat-progress-bar {
      width: 60%;
    }
  }

  .transcript {
    .mat-button {
      font-size: 1.6rem;

      .material-icons {
        font-size: 1.8rem;
      }

      &:hover {
        color: color("accent", "base");
      }
    }
  }

  .mat-tab-body-wrapper {
    @include margin-padding(null, null, all, 0);

    .info-heading {
      @include flexbox;
      @include align-items(center);

      .info-heading-inner {
        @include flexbox;
        @include align-items(center);

        .material-icons {
          font-size: 2rem;
        }
      }

      div {
        position: relative;
      }

      .material-icons {
        @include margin-padding(left, 0.8rem, null, null);
      }

      .info-text {
        visibility: hidden;
        border: 0.1rem solid color("grey", "lighten-7");
        @include margin-padding(null, null, all, 1.5rem);
        @include abs-position(0, 0, 0, 0);
        background-color: color("shades", "white");
        width: 47rem;
        height: 9.4rem;
        z-index: 2;
        line-height: 1.5;
        font-size: 1.5rem;
        border-radius: $default-radius;
        text-transform: initial;
      }

      div {
        &:hover .info-text {
          visibility: visible;
        }
      }
    }

    h4 {
      @include margin-padding(all, 1rem 0 0, all, 1rem 0 0 3.2rem);
      font-weight: 500;
      // border-top: .1rem solid color('grey', 'lighten-7');

      @include breakpoint(medium) {
        font-size: 1.4rem;
        @include margin-padding(top, 1.5rem, left, 1.5rem);
      }

      &.dd {
        border: none;
      }
    }

    h3 {
      @include margin-padding(all, 1.5rem 0 -1.1rem, all, 0.8rem 1.5rem);
      font-size: 1.6rem;
      position: relative;
      z-index: 1;
      border-top: 0.1rem solid color("grey", "lighten-7");

      @include breakpoint(medium) {
        font-size: 1.6rem;
      }

      &:first-child {
        border: none;
      }
    }
  }

  .show-all {
    text-align: center;

    button {
      color: color("primary", "base");
      font-size: 1.6rem;
      @include margin-padding(all, 0 0 1.6rem, null, null);
      position: relative;
      width: 100%;

      .material-icons {
        background-color: color("primary", "base");
        border-radius: $circular-radius;
        color: color("shades", "white");
        @include margin-padding(left, 0.8rem, null, null);
      }

      &:before,
      &:after {
        @include abs-position(0, auto, 0, 0);
        height: 0.1rem;
        width: 44%;
        content: "";
        background-color: color("primary", "light");
        @include margin-padding(all, auto, null, null);
      }

      &:after {
        left: auto;
        right: 0;
      }

      .mat-button-wrapper {
        background-color: color("light", "base");
        position: relative;
        z-index: 1;
      }
    }
  }

  @include breakpoint(medium) {

    .row div.page-heading-area,
    .billing-method .card-detail,
    .preview-customize .row {
      display: block !important;
    }

    .row div.page-heading-area .button,
    .billing-method .card-detail .button {
      @include margin-padding(all, 1.5rem 1.5rem 0 0, null, null);
    }

    .preview-customize {
      .filled {
        @include margin-padding(top, 1.5rem, null, null);
      }
    }
  }

  .today {
    background-color: color("accent", "base");
    color: color("shades", "white") !important;
    @include abs-position(-3rem, auto, auto, -2rem);
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    box-shadow: 5px 10px 10px rgba(241, 122, 126, 0.25);
    font-size: 13px;
    border-radius: 3rem;
    width: 6rem;
    height: 2.5rem;
  }
}

.session-expand-area {
  .mat-expansion-panel-header-title {
    @include margin-padding(all, 0, right, 6rem);

    @media (max-width:#{$small-screen}) {
      @include margin-padding(null, null, right, 1.7rem);
    }
  }

  .mat-expansion-panel {
    @include margin-padding(all, 0, null, null);

    .mat-expansion-panel-header {
      @include abs-position(3.7rem, 5.5rem, auto, auto);
      @include margin-padding(all, auto, null, null);
      min-height: 6.2rem !important;
      height: 6.2rem !important;

      @include breakpoint(medium) {
        top: 3rem;
      }

      @include breakpoint(medium) {
        top: 2.7rem;
      }

      @media (max-width:#{$medium-screen}) {
        top: 0rem !important;
      }

      @media (max-width:#{$small-screen}) {
        top: 0 !important;
        right: 6.3rem;
      }
    }
  }
}

.session-container {
  @include margin-padding(bottom, 1.5rem, all, 1.5rem);
  display: block;
  background-color: color("shades", "white");
  border-radius: $rounded-radius;
  position: relative;

  @include breakpoint(small) {
    @include margin-padding(null, null, all, 1.5rem);
  }

  .mat-content {
    overflow: visible;
  }

  .mat-expansion-panel-header-title {
    color: color("primary-light", "base2");
    display: block;
  }

  .mat-expansion-panel .mat-expansion-panel-header {
    background: transparent !important;
  }

  .mat-expansion-panel-header {
    height: 100% !important;
    padding: $no-padding;
  }

  .mat-expansion-panel {
    background-color: color("shades", "transparent");
    box-shadow: none !important;
    position: static;
    border: none;
  }

  .date {
    height: 7rem !important;
    width: 7rem !important;
    background-color: color("accent", "base");
    border-radius: $default-radius;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    color: color("shades", "white");
    flex-direction: column;
    flex: 0 0 8rem;

    @include breakpoint(xxxxxlarge) {
      height: 7rem;
      width: 7rem;
      flex: 0 0 7rem;
    }

    @include breakpoint(medium) {
      height: 7rem;
      width: 7rem;
      flex: 0 0 7rem;
    }

    span {
      font-size: 3.6rem;
      display: block;
      font-weight: 600;
      color: color("shades", "white");

      @include breakpoint(xxxxxlarge) {
        font-size: 2.5rem;
      }

      &.month {
        font-size: 1.4rem;
        font-weight: 500;
        text-transform: uppercase;
        color: color("shades", "white");

        @include breakpoint(xxxxxlarge) {
          font-size: 1.3rem;
        }

        @include breakpoint(small) {
          font-size: 1rem;
        }
      }
    }
  }

  .session-details {
    @include margin-padding(left, 2rem, null, null);
    width: 100%;

    @media (max-width:#{$small-screen}) {
      @include margin-padding(left, 1.5rem, null, null);
    }

    p.session-name {
      font-size: 1.9rem;
      font-weight: 600;
      display: block;
      @include margin-padding(all, 0 0 1rem, null, null);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      color: color("grey", "lighten-1");

      @include breakpoint(xxlarge) {
        // width: 85%;
      }

      @include breakpoint(small) {
        // width: 75%;
      }

      @include breakpoint(x-small) {
        font-size: 2rem;
        // width: 58%;
      }
    }

    p.time {
      font-size: 1.6rem;
      @include margin-padding(all, 0, null, null);

      color: color("primary-light", "base5");

      @include breakpoint(xxxxxlarge) {
        font-size: 1.3rem;
      }
    }
  }

  .left-content,
  .right-content {
    @include flexbox;
    @include align-items(center);
  }

  .right-content {
    @include justify-content(flex-end);

    div {
      word-break: break-word;

      &.recordings {
        // flex: 0 0 26%;
        width: 50px;

        @include breakpoint(medium) {
          @include margin-padding(right, 1.5rem, null, null);
        }

        @include breakpoint(small) {
          @include margin-padding(right, 4rem, null, null);
        }

        p {
          @include flexbox;
          @include align-items(center);
          @include justify-content(flex-start);
          color: color("grey", "lighten-1");

          b {
            @include margin-padding(all, 0 0.3rem 0 0.6rem, null, null);
          }

          .material-icons {
            font-size: 2.1rem;
            cursor: default;
            color: color("accent", "base");
          }
        }
      }

      // &.guest-details {
      //   // flex: 0 0 35%;
      // }
    }

    .button-small {
      min-width: 14.2rem;

      @include breakpoint(medium) {
        min-width: auto;
      }

      @include breakpoint(small) {
        @include margin-padding(left, 3.5rem, null, null);
      }
    }
  }

  span.more {
    @include margin-padding(all, -0.3rem 0rem 0 0rem, null, null);
    position: absolute;
    right: -10rem;

    @include breakpoint(medium) {
      top: 0.9rem;
    }

    @media (max-width:#{$medium-screen}) {
      top: -0.3rem !important;
    }

    @media (max-width:#{$small-screen}) {
      top: 0.2rem;
      right: -1.5rem;
    }

    .mat-list-base {
      @include abs-position(0, 0, auto, auto);
      width: 25rem;
      background-color: color("shades", "white");
      border: 0.1rem solid color("primary", "light");
      border-radius: $default-radius;
      @include box-shadow($box-shadow-white-container);
      visibility: hidden;
      transition: all 0.3s ease-out;
      z-index: -1;
      pointer-events: none;
      top: 4rem;
      opacity: 0;

      .material-icons {
        @include margin-padding(right, 1rem, null, null);
        font-size: 1.8rem;
      }

      .mat-list-item {
        border-bottom: 0.1rem solid color("primary", "light");
        @include margin-padding(null, null, all, 1.4rem 0);

        a {
          color: color("primary", "base");
          font-weight: 400;
          @include flexbox;
          @include align-items(center);

          &:hover {
            color: color("accent", "base");

            .material-icons {
              color: color("accent", "base");
            }
          }
        }
      }
    }

    &:hover .mat-list-base {
      visibility: visible;
      top: 2rem;
      pointer-events: auto;
      z-index: 99;
      opacity: 1;
    }
  }

  .mat-expansion-panel {
    overflow: visible;
  }

  .mat-expansion-panel-body {
    @include margin-padding(top, 2rem, all, 0.5rem 0 0);
    border-top: 0.1rem solid color("grey", "lighten-7");
  }

  .mat-expansion-panel-header-description {
    font-size: 1.6rem;
    color: color("primary-light", "base5");
    @include margin-padding(all, 0, null, null);

    .no-recording {
      @include margin-padding(top, 1rem, null, null);
      color: color("grey", "lighten-1");
      // width: 100%;
    }
  }

  .mat-expansion-indicator {
    display: inline-block;
    height: 3.5rem;
    width: 3.5rem;
    min-width: 3.5rem;
    border: 0.1rem solid color("grey", "lighten-4");
    text-align: center;
    border-radius: $circular-radius;
    line-height: 2.8rem;

    &::after {
      color: color("grey", "lighten-4");
    }
  }

  .guest-details {
    @include flexbox;
    @include margin-padding(all, 0 0 0, null, null);
    @include align-items(center);

    @include breakpoint(xxxxxlarge) {
      @include margin-padding(left, 0, null, null);
    }

    span {
      display: inline-block;

      &+.guest-name {
        @include margin-padding(left, 1rem, null, null);
      }
    }

    span.avatar {
      height: 3.5rem;
      width: 3.5rem;
      min-width: 3.5rem;
      // background-color: color("grey", "lighten-7");
      display: inline-block;
      border-radius: $circular-radius;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      // color: color("grey", "lighten-1");
      color: #000000 !important;
      background-color: #ffcfb8 !important;
      font-size: $h5-fontsize;
      font-weight: 500;
      border: 2.5px white solid;


    }

    p.name {
      font-weight: 600;
      @include margin-padding(all, 0, null, null);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 12rem;
      color: color("primary", "base");

      @include breakpoint(desktop) {
        width: 20rem;
      }
    }

    p.guest {
      @include margin-padding(all, 0.2rem 0 0, null, null);
      font-size: 1.3rem;
      color: color("primary", "base");
      display: inline-block;
    }
  }

  .invite {
    @include flexbox;
    @include align-items(center);
    @include margin-padding(left, 2rem, null, null);
    cursor: pointer;
    color: color("primary", "base");
    background-color: color("shades", "transparent" !important);

    span {
      background-color: color("shades", "transparent" !important);
    }

    @include breakpoint(xxxxxlarge) {
      @include margin-padding(left, 1.5rem, null, null);
    }

    @include breakpoint(medium) {
      @include margin-padding(left, 0, null, null);
    }

    .material-icons {
      @include margin-padding(right, 0.6rem, null, null);
      color: color("primary", "base");

      @include breakpoint(xxxxxlarge) {
        font-size: 1.8rem;
        @include margin-padding(right, 0.4rem, null, null);
      }
    }

    span:last-child {
      font-size: 1.4rem;

      @include breakpoint(xxxxxlarge) {
        font-size: 1.3rem;
      }
    }
  }

  button {
    @include margin-padding(left, 2rem, null, null);

    @include breakpoint(xxxxxlarge) {
      @include margin-padding(left, 1.5rem, null, null);
    }

    &:hover .material-icons {
      color: color("accent", "base");
    }
  }

  &.past-session {
    .date {
      background-color: color("grey", "lighten-7");

      span {
        color: color("grey", "lighten-1");
      }
    }

    .button {
      background-color: color("grey", "lighten-7");
      color: color("grey", "lighten-1");
      box-shadow: none;
      border: 0;

      .material-icons {
        color: color("grey", "lighten-1");
      }
    }

    .recordings {
      p {
        @include breakpoint(xxxxxlarge) {
          font-size: 1.4rem;
        }

        b {
          font-size: 1.8rem;

          @include breakpoint(xxxxxlarge) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}

/*==== Dashboard css ends ====*/

/* ==== Green room css starts ==== */

body .user_name_no_cam {
  font-size: 2.1rem !important;
  width: 5rem;
  height: 5rem;
  text-align: center;
  line-height: 5rem !important;
  border-radius: 100%;
  top: 0.7rem;
  position: relative;
  background-color: #a5a5a5;
  font-weight: 400 !important;
  color: #fff;
  text-transform: uppercase;
  @include margin-padding(all, 0 !important, null, null !important);

  @media (max-width:#{$small-screen}) {
    top: 0.3rem;
  }
}

body .second_user_name_no_cam {
  font-size: 4rem !important; //6
  width: 12rem; //14
  height: 12rem; //14
  line-height: 14rem !important;
  border-radius: 100%;
  background-color: #657f82;
  font-weight: 400 !important;
  color: #fff;
  text-transform: uppercase;
  @include abs-position(0, 0, 0, 0);
  @include margin-padding(all, auto !important, null, null);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-stream {
  width: max-content;
  @include abs-position(auto, 0.6rem, 1.2rem, auto);
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  border-radius: $default-radius;
  @include margin-padding(null, null, all, 0.9rem 1.2rem 0.9rem 1.5rem);
  background-color: color("shades", "black");

  p {
    text-transform: uppercase;
    font-size: 1.3rem;
    color: red;
    font-weight: 700;
    letter-spacing: 0.5px;
    @include margin-padding(all, 0 1.8rem 0 0, null, null);

    span {
      color: #fff;
    }
  }

  &>* {
    @include margin-padding(right, 0.8rem, null, null);
  }

  svg:last-child {
    margin: 0;
  }
}

/*==== Ready set go css starts ====*/

.number-state {
  font-size: 10rem;
  color: color("shades", "white");
  text-transform: uppercase;

  span {
    @include abs-position(0, 0, 0, 0);
    opacity: 0;
    color: color("shades", "white");
    height: 100vh;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
  }

  .num1 {
    animation: time 18s;
    animation-delay: 5s;
  }

  .num2 {
    animation: time 18s;
    animation-delay: 3s;
  }

  .num3 {
    animation: time 20s;
    animation-delay: 0s;
  }
}

@keyframes timer-open {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(1.5);
  }
}

@keyframes timer-close {
  0% {
    opacity: 1;
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes time {

  0%,
  10% {
    opacity: 0;
    transform: scale(1);
  }

  5% {
    opacity: 1;
    transform: scale(1.5);
  }
}

/*==== Ready set go css ends ====*/

.green-room {
  height: 100%;
  overflow: hidden;
  @include flexbox;
  flex-direction: column;
  position: relative;
  background-color: color("shades", "black");

  &+app-customize-room .logo-customization {
    .file-upload {
      @include margin-padding(bottom, 1rem, null, null);
    }

    .save-reset {
      @include margin-padding(top, 1rem, null, null);
    }

    &+button {
      @include margin-padding(top, 1.5rem, null, null);
    }

    label {
      font-weight: 400;
    }

    textarea {
      height: 7.4rem !important;
      line-height: 1.5;
    }

    .select-color-area {
      clear: both;
    }
  }

  #conference {
    padding: 59px 0;
    position: relative;
    height: calc(100% - 0px);
    width: calc(100% - 10px);
    left: 5px;
    top: 0px;
    border: 0 !important;
  }

  .conference-member {
    position: relative;

    .host {
      z-index: 999 !important;
    }

  }

  .conference[conference-member="1"] {
    .second_user_name_no_cam {
      font-size: 5vh !important;
      width: 16%;
      height: 22.18%;
      bottom: 0;
    }
  }



  &.user1-2 {
    .conference[conference-member="1"] {
      .conference-member {
        width: 45%;
        height: 60%;
      }

      .second_user_name_no_cam {
        font-size: 3vh !important;
      }
    }
  }

  &.user2-1 {
    .conference[conference-member="2"] {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      border: 0.5rem solid transparent;

      .conference-member {
        width: 70%;
        @include margin-padding(all, auto, null, null);
        float: none;
        height: 70%;

        &.host {
          height: 16%;
          width: 16%;
          @include abs-position(4.5%, 0, auto, 0);
          z-index: 99;
          @include margin-padding(null, null, all, 0);

          // transform: scale(1.5);
          .second_user_name_no_cam {
            width: 34%;
            height: 60.45%;
            font-size: 3vh !important;
          }

          .guest-name {
            display: none;
          }

          .name {
            display: none;
          }
        }

        .second_user_name_no_cam {
          width: 24%;
          height: 43%;
          font-size: 9vh !important;
        }
      }
    }
  }

  &.host-green-room.user2-1 {
    .conference[conference-member="2"] {
      .conference-member {
        &.host {
          top: 25px;
        }
      }
    }
  }

  // &.top-banner.user2-1 {
  //   .conference[conference-member="2"] {
  //     .conference-member {
  //       &.host {
  //         top: 3.8rem;
  //       }
  //     }
  //   }
  // }
  &.user2-2 {
    .conference[conference-member="2"] {
      @include flexbox;
      @include align-items(center);
      flex-direction: row-reverse;
      border: 0.5rem solid transparent;

      .conference-member {
        height: 70%;
        width: 70%;
        @include margin-padding(left, 2.5%, all, 0);
        margin-right: 0.4%;

        video {
          object-fit: cover;
        }

        &.host {
          width: 30%;
          height: 50%;
          margin-left: 0;
          margin-right: 2.5%;

          .second_user_name_no_cam {
            width: 20%;
            height: 20.236%;
            font-size: 3vh !important;
          }

          .name {
            .user-leaving {
              margin: 0.5rem;

              h2 {
                margin: 0.5rem;
                font-size: 1.4rem;
              }

              .mat-progress-bar {
                height: 0.6rem;
              }

              .content-percentage-wrap {
                h4 {
                  font-size: 1.2rem;
                  margin: 0.5rem 0;
                }
              }
            }

            p {
              font-size: 1.4rem;
            }
          }
        }

        .second_user_name_no_cam {
          width: 20%;
          height: 33.72%;
          font-size: 6vh !important;
        }
      }
    }
  }

  &.user2-3 {
    .conference[conference-member="2"] {
      @include flexbox;
      @include align-items(center);
      border: 0.5rem solid transparent;
      overflow: hidden;

      .conference-member {
        height: 70%;
        width: 50%;
        float: left;
        border: none;
        @include margin-padding(all, auto 10px auto 0.25rem, null, null);

        &.host {
          border-left: none;
          @include margin-padding(all, auto 0.25rem auto 10px, null, null);
        }

        .second_user_name_no_cam {
          width: 26.406% !important;
          height: 32.75%;
          font-size: 6vh !important;
        }

        video {
          object-fit: cover;
        }
      }
    }
  }

  &.user2-4 {
    .conference[conference-member="2"] {
      @include flexbox;
      @include align-items(center);
      justify-content: center;
      border: 0.5rem solid transparent;
      justify-content: center;

      .conference-member {
        width: 34%;
        height: 60%;
        @include margin-padding(all, 0 0.25rem 0 0.25rem, null, null);

        &.host {
          @include margin-padding(all, 0 00.25rem 0 0.25rem, null, null);
        }

        video {
          object-fit: cover;
        }

        .second_user_name_no_cam {
          width: 30%;
          height: 30.33%;
          font-size: 5vh !important;
        }

        .name {
          .user-leaving {
            margin: 0.5rem;

            h2 {
              margin: 0.5rem;
              font-size: 1.6rem;
            }

            .mat-progress-bar {
              height: 0.6rem;
            }

            .content-percentage-wrap {
              h4 {
                font-size: 1.4rem;
                margin: 0.5rem 0;
              }
            }
          }

          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  &.user2-5 {
    .conference[conference-member="2"] {
      @include flexbox;
      @include align-items(center);
      border: 0.5rem solid transparent;
      flex-direction: column;

      .conference-member {
        width: 47.5%;
        height: 50%;
        @include margin-padding(all, 0 0% 0 0%, null, null);

        &.host {
          @include margin-padding(all, 0 0% 0 0%, null, null);
        }

        video {
          object-fit: cover;
        }
      }
    }
  }

  &.host-green-room.user3-1 {
    .conference[conference-member="3"] {
      .conference-member {
        &.host {
          top: 25px;
        }
      }
    }
  }

  &.user3-1 {
    .conference[conference-member="3"] {
      border: 0.5rem solid transparent;
      overflow: hidden;

      .conference-member {
        height: 70%;
        width: calc(50% - 13px);
        border: none;
        @include margin-padding(all, 0, null, null);

        video {
          object-fit: cover;
        }

        .second_user_name_no_cam {
          width: 26.95%;
          height: 32.75%;
          font-size: 6vh !important;
        }

        &.host {
          height: 16%;
          width: 16%;
          @include abs-position(4.5%, 0, auto, 0);
          z-index: 9;
          @include margin-padding(null, null, all, 0);

          // transform: scale(1.5);
          .second_user_name_no_cam {
            height: 6rem;
            width: 6rem;
            font-size: 2.6rem !important;
            line-height: 5rem !important;
          }

          .guest-name {
            display: none;
          }

          .name {
            display: none;
          }
        }

        &:nth-child(2) {
          margin-right: 0.25rem;
          margin-left: 10px;

          .mic {
            margin-right: 16%;
          }
        }

        &:nth-child(3) {
          margin-left: 0.25rem;
          margin-right: 10px;

          .guest-name {
            .more-outer {
              position: relative;
              margin-left: 16%;
            }
          }
        }
      }
    }
  }

  &.user3-2 {
    .conference[conference-member="3"] {
      @include flexbox;
      @include justify-content(center);
      flex-flow: column wrap;
      position: relative;
      border: 0.5rem solid transparent;
      column-gap: 0.5rem;
      row-gap: 0.5rem;

      .conference-member {
        width: 25%;
        @include margin-padding(all, 0 2.5% 0 0rem, null, null);
        height: 40%;

        video {
          object-fit: cover;
        }

        &.host {
          width: 70%;
          @include margin-padding(all, 0% 0rem 0% 2.5%, all, 0);
          height: calc(80% + 0.5rem);

          video {
            object-fit: cover;
          }
        }

        &:nth-child(3) {
          @include margin-padding(top, 0rem, null, null);

          .second_user_name_no_cam {
            height: 3rem;
            width: 3rem;
            font-size: 1.2rem !important;
          }

          .name {
            .user-leaving {
              margin: 0.5rem 0.5rem 0;

              h2 {
                margin: 0.5rem;
                font-size: 1.4rem;
              }

              .mat-progress-bar {
                height: 0.6rem;
              }

              .content-percentage-wrap {
                h4 {
                  font-size: 1.2rem;
                  margin: 0.5rem 0;
                }
              }
            }

            p {
              font-size: 1.4rem;
            }
          }
        }

        &:nth-child(2) {
          .second_user_name_no_cam {
            height: 3rem;
            width: 3rem;
            font-size: 1.2rem !important;
          }

          .name {
            .user-leaving {
              margin: 0.5rem 0.5rem 0;

              h2 {
                margin: 0.5rem;
                font-size: 1.4rem;
              }

              .mat-progress-bar {
                height: 0.6rem;
              }

              .content-percentage-wrap {
                h4 {
                  font-size: 1.2rem;
                  margin: 0.5rem 0;
                }
              }
            }

            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  &.user3-3 {
    .conference[conference-member="3"] {
      display: block;
      border: 0.5rem solid transparent;
      overflow: hidden;

      .conference-member {
        border: none;
        margin: 0;
        margin-left: 0.25rem;
        height: calc(50% - 13px);
        width: calc(50% - 13px);
        float: left;
        margin-right: 10px;

        video {
          object-fit: cover;
        }

        .second_user_name_no_cam {
          width: 39%;
          height: 35.27%;
          font-size: 9vh !important;
        }

        &.host {
          border: none;
          margin-left: 10px;
          margin-right: 0.25rem;
          height: calc(100% - 20px);
          margin-top: 10px;
        }

        &:nth-child(2) {
          margin-bottom: 0.25rem;
          margin-top: 10px;

          .second_user_name_no_cam {
            font-size: 5vh !important;
            width: 23.17%;
            height: 42.74%;
          }

          .name {
            .user-leaving {
              margin: 0.5rem;

              h2 {
                margin: 0.5rem;
                font-size: 1.6rem;
              }

              .mat-progress-bar {
                height: 0.6rem;
              }

              .content-percentage-wrap {
                h4 {
                  font-size: 1.4rem;
                  margin: 0.5rem 0;
                }
              }
            }

            p {
              font-size: 20px;
            }
          }
        }

        &:nth-child(3) {
          margin-top: 0.25rem;
          margin-bottom: 10px;

          .second_user_name_no_cam {
            font-size: 5vh !important;
            width: 23.17%;
            height: 42.74%;
          }

          .name {
            .user-leaving {
              margin: 0.5rem;

              h2 {
                margin: 0.5rem;
                font-size: 1.6rem;
              }

              .mat-progress-bar {
                height: 0.6rem;
              }

              .content-percentage-wrap {
                h4 {
                  font-size: 1.4rem;
                  margin: 0.5rem 0;
                }
              }
            }

            p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  &.user3-4 {
    .conference[conference-member="3"] {
      @include flexbox;
      @include justify-content(center);
      align-content: center;
      flex-wrap: wrap;
      border: 0.5rem solid transparent;
      column-gap: 0.5rem;
      row-gap: 0.5rem;

      .conference-member {
        width: 38.5%;
        height: calc(50% - 13px);
        @include margin-padding(all, 0, null, null);

        &.host {
          margin-top: 10px;
        }

        video {
          object-fit: cover;
        }

        &:nth-child(2) {
          margin-top: 10px;
          margin-bottom: 0;
        }

        &:nth-child(3) {
          margin-top: 0;
          margin-bottom: 10px;
        }

        .second_user_name_no_cam {
          height: 42.97%;
          width: 28.25%;
          font-size: 6vh !important;
        }

        .name {
          .user-leaving {
            margin: 0.5rem 0.5rem 0;

            h2 {
              margin: 0.5rem;
              font-size: 1.4rem;
            }

            .mat-progress-bar {
              height: 0.6rem;
            }

            .content-percentage-wrap {
              h4 {
                font-size: 1.2rem;
                margin: 0.5rem 0;
              }
            }
          }

          p {
            font-size: 20px;
          }
        }
      }
    }
  }

  &.user3-5 {
    .conference[conference-member="3"] {
      border: 0.5rem solid transparent;
      overflow: hidden;
      border: 0.5rem solid transparent;
      flex-wrap: initial;

      .conference-member {
        width: calc(100% / 3 - 0.35rem);
        height: 70%;
        @include margin-padding(all, 0, null, null);

        .second_user_name_no_cam {
          width: 41.9%;
          height: 33.17%;
          font-size: 5vh !important;
        }

        &.host {
          border: none;
          margin-right: 0.25rem;
          margin-left: 10px;
        }

        &:nth-child(2) {
          border: none;
          margin-left: 0.25rem;
          margin-right: 0.25rem;
        }

        &:nth-child(3) {
          border: none;
          margin-left: 0.25rem;
          margin-right: 10px;
        }

        video {
          object-fit: cover;
        }
      }
    }
  }

  &.user4-1 {
    .conference[conference-member="4"] {
      border: 0.5rem solid transparent;
      overflow: hidden;
      width: 100%;
      flex-wrap: initial;

      .conference-member {
        width: calc(100% / 3 - 0.35rem);
        height: 70%;
        @include margin-padding(all, 0, null, null);

        video {
          object-fit: cover;
        }

        .video-container {
          video {
            object-fit: cover;
          }
        }

        .second_user_name_no_cam {
          width: 44%;
          height: 34.83%;
          font-size: 6vh !important;
        }

        &.host {
          height: 16%;
          width: 16%;
          @include abs-position(4.5%, 0, auto, 0);
          z-index: 99;
          @include margin-padding(null, null, all, 0);

          // transform: scale(1.5);
          .guest-name {
            display: none;
          }

          .name {
            display: none;
          }

          .second_user_name_no_cam {
            width: 34%;
            height: 60.45%;
            font-size: 3vh !important;
          }
        }

        &:nth-child(2) {
          margin-right: 0.25rem;
          margin-left: 10px;
        }

        &:nth-child(3) {
          margin-left: 0.25rem;
          margin-right: 0.25rem;
        }

        &:nth-child(4) {
          margin-left: 0.25rem;
          margin-right: 10px;
        }
      }
    }
  }

  &.user4-2 {
    .conference[conference-member="4"] {
      border: 0.5rem solid transparent;
      column-gap: 0.5rem;
      row-gap: 0.5rem;

      .conference-member {
        video {
          object-fit: cover;
        }

        .second_user_name_no_cam {
          height: 36.84%;
          width: 22%;
          font-size: 5vh !important;
          line-height: 1rem !important;
        }

        .name {
          .user-leaving {
            margin: 0.5rem;

            h2 {
              margin: 0.5rem;
              font-size: 1.4rem;
            }

            .mat-progress-bar {
              height: 0.6rem;
            }

            .content-percentage-wrap {
              h4 {
                font-size: 1.2rem;
                margin: 0.5rem 0;
              }
            }
          }

          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  &.user4-3 {
    .conference[conference-member="4"] {
      @include flexbox;
      @include justify-content(center);
      flex-flow: column wrap;
      border: 0.5rem solid transparent;
      column-gap: 0.5rem;
      row-gap: 0.5rem;

      .name {
        p {
          margin-bottom: 8px !important;
        }
      }

      .conference-member {
        width: 25%;
        @include margin-padding(all, 0 2.5% 0 0, null, null);
        height: calc(33% - 9px);

        .second_user_name_no_cam {
          height: 40%;
          width: 27.23%;
          font-size: 3vh !important;
          top: 0;
        }

        &.host {
          height: 100%;
          width: 70%;
          @include margin-padding(all, 0 0 0 2.5%, null, null);
          margin-top: 10px;
          margin-bottom: 10px;

          .second_user_name_no_cam {
            width: 26.65%;
            height: 34%;
            font-size: 8vh !important;
          }
        }

        &:nth-child(2) {
          margin-top: 10px;

          .name {
            .user-leaving {
              margin: 0;

              h2 {
                margin: 0.2rem;
                font-size: 1.2rem;
              }

              .mat-progress-bar {
                height: 0.5rem;
              }

              .content-percentage-wrap {
                h4 {
                  font-size: 1rem;
                  margin: 0.2rem;
                }
              }
            }

            p {
              font-size: 1.4rem;
            }
          }
        }

        &:nth-child(3) {
          .name {
            .user-leaving {
              margin: 0;

              h2 {
                margin: 0.2rem;
                font-size: 1.2rem;
              }

              .mat-progress-bar {
                height: 0.5rem;
              }

              .content-percentage-wrap {
                h4 {
                  font-size: 1rem;
                  margin: 0.2rem;
                }
              }
            }

            p {
              font-size: 1.4rem;
            }
          }
        }

        &:nth-child(4) {
          margin-bottom: 10px;

          .name {
            .user-leaving {
              margin: 0;

              h2 {
                margin: 0.2rem;
                font-size: 1.2rem;
              }

              .mat-progress-bar {
                height: 0.5rem;
              }

              .content-percentage-wrap {
                h4 {
                  font-size: 1rem;
                  margin: 0.2rem;
                }
              }
            }

            p {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  &.user5-1 {
    .conference[conference-member="5"] {
      border: 0.5rem solid transparent;
      column-gap: 0.5rem;
      row-gap: 0.5rem;
      padding-top: 10px !important;
      padding-bottom: 10px !important;

      .conference-member {
        margin: 0;
        height: calc(50% - 0.25rem);

        .second_user_name_no_cam {
          height: 30%;
          width: 27.21%;
          font-size: 3.8vh !important;
          line-height: 1rem !important;
        }

        .name {
          .user-leaving {
            margin: 1rem 1rem 0.5rem;

            h2 {
              margin: 0.5rem;
              font-size: 1.4rem;
            }

            .mat-progress-bar {
              height: 0.6rem;
            }

            .content-percentage-wrap {
              h4 {
                font-size: 1.2rem;
                margin: 0.5rem 0;
              }
            }
          }

          p {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  &.user5-2 {
    .conference[conference-member="5"] {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      flex-wrap: wrap;
      border: 0.5rem solid transparent;
      column-gap: 0.5rem;
      row-gap: 0.5rem;
      align-content: center;

      .conference-member {
        width: 38%;
        height: 36%;
        @include margin-padding(all, 0, null, null);

        video {
          object-fit: cover;
        }

        &.host {
          height: 16%;
          width: 16%;
          @include abs-position(2.5%, 0, auto, 0);
          z-index: 99;
          @include margin-padding(null, null, all, 0);
          background-color: #262323;

          .guest-name {
            display: none;
          }

          .name {
            display: none;
          }

          .second_user_name_no_cam {
            height: 6rem;
            width: 6rem;
            font-size: 2.6rem !important;
            line-height: 5rem !important;
          }
        }

        &:nth-child(2) {
          background-color: color("grey", "lighten-2");
        }

        &:nth-child(3) {
          background-color: color("grey", "lighten-3");
        }

        &:nth-child(4) {
          background-color: color("grey", "lighten-4");
          @include margin-padding(bottom, 2.5%, null, null);
        }

        &:nth-child(5) {
          background-color: color("grey", "lighten-1");
          @include margin-padding(bottom, 2.5%, null, null);
        }

        .second_user_name_no_cam {
          height: 40%;
          width: 21.26%;
          font-size: 3.8vh !important;
          line-height: 1rem !important;
        }

        .name {
          .user-leaving {
            margin: 1rem;

            h2 {
              margin: 0.5rem;
              font-size: 1.4rem;
            }

            .mat-progress-bar {
              height: 0.6rem;
            }

            .content-percentage-wrap {
              h4 {
                font-size: 1.2rem;
                margin: 0.5rem 0;
              }
            }
          }

          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  &.user5-2 {
    .conference[conference-member="5"] {
      .conference-member {
        &:nth-child(2) {
          .mic {
            margin-right: 20.5%;
          }
        }

        &:nth-child(3) {
          .more-outer {
            margin-left: 20.5%;
          }
        }
      }
    }
  }

  &.user5-3 {
    .conference[conference-member="5"] {
      border: 0.5rem solid transparent;
      column-gap: 0.5rem;
      row-gap: 0.5rem;

      .conference-member {
        margin: 0;
        height: calc(50% - 0.25rem);

        .second_user_name_no_cam {
          height: 5rem;
          width: 5rem;
          font-size: 2rem !important;
          line-height: 1rem !important;
        }

        .name {
          .user-leaving {
            margin: 1rem 1rem 0.5rem;

            h2 {
              margin: 0.5rem;
              font-size: 1.4rem;
            }

            .mat-progress-bar {
              height: 0.6rem;
            }

            .content-percentage-wrap {
              h4 {
                font-size: 1.2rem;
                margin: 0.5rem 0;
              }
            }
          }

          p {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  // &.user5-3 {
  //     .conference[conference-member="5"] {
  //         border: 0.5rem solid transparent;
  //         overflow: hidden;
  //         .conference-member {
  //             width: calc(100% / 3 - 0.35rem);
  //             height: 49.5%;
  //             @include margin-padding(all, 0, null, null);
  //             &.host {
  //                 border: none;
  //                 margin-right: 0.25rem;
  //             }
  //             &:nth-child(1) {
  //                 border: none;
  //                 margin-right: 0.25rem;
  //                 margin-bottom: 0.25rem;
  //             }
  //             &:nth-child(2) {
  //                 border: none;
  //                 margin-left: 0.25rem;
  //                 margin-right: 0.25rem;
  //                 margin-bottom: 0.25rem;
  //             }
  //             &:nth-child(3) {
  //                 border: none;
  //                 margin-left: 0.25rem;
  //                 margin-bottom: 0.25rem;
  //             }
  //             &:nth-child(4) {
  //                 border: none;
  //                 margin-top: 0.25rem;
  //                 margin-right: 0.25rem;
  //                 width: calc(50% - 0.35rem);
  //             }
  //             &:nth-child(5) {
  //                 border: none;
  //                 margin-top: 0.25rem;
  //                 margin-left: 0.25rem;
  //                 width: calc(50% - 0.35rem);
  //             }
  //             video {
  //                 object-fit: cover;
  //             }
  //             .second_user_name_no_cam {
  //                 height: 5rem;
  //                 width: 5rem;
  //                 font-size: 2rem !important;
  //                 line-height: 1rem !important;
  //             }
  //             .name {
  //                 .user-leaving {
  //                     margin: 1rem;
  //                     h2 {
  //                         margin: 0.5rem;
  //                         font-size: 1.4rem;
  //                     }
  //                     .mat-progress-bar {
  //                         height: 0.6rem;
  //                     }
  //                     .content-percentage-wrap {
  //                         h4 {
  //                             font-size: 1.2rem;
  //                             margin: 0.5rem 0;
  //                         }
  //                     }
  //                 }
  //                 p {
  //                     font-size: 1.6rem;
  //                 }
  //             }
  //         }
  //     }
  // }

  &.user6-1 {
    .conference[conference-member="6"] {
      border: 0.5rem solid transparent;

      .conference-member {
        .second_user_name_no_cam {
          height: 31.45%;
          width: 26%;
          font-size: 3.8vh !important;
          line-height: 1rem !important;
        }

        .name {
          .user-leaving {
            margin: 1rem 1rem 0.5rem;

            h2 {
              margin: 0.5rem;
              font-size: 1.4rem;
            }

            .mat-progress-bar {
              height: 0.6rem;
            }

            .content-percentage-wrap {
              h4 {
                font-size: 1.2rem;
                margin: 0.5rem 0;
              }
            }
          }

          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  &.user6-2 {
    .conference[conference-member="6"] {
      // height: calc(100% - 5.6rem);
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      flex-wrap: wrap;
      border: 0.5rem solid transparent;
      column-gap: 0.5rem;
      row-gap: 0.5rem;
      align-content: center;

      .conference-member {
        width: 30.5%;
        height: 36%;
        @include margin-padding(all, 0, null, null);

        video {
          object-fit: cover;
        }

        &.host {
          height: 16%;
          width: 16%;
          @include abs-position(2.5%, 0, auto, 0);
          z-index: 99;
          @include margin-padding(null, null, all, 0);
          background-color: color("grey", "lighten-2");

          // transform: scale(1.5);
          .guest-name {
            display: none;
          }

          .name {
            display: none;
          }

          .second_user_name_no_cam {
            height: 6rem;
            width: 6rem;
            font-size: 2.6rem !important
          }
        }

        &:nth-child(1) {
          background-color: color("grey", "lighten-1");
        }

        &:nth-child(2) {
          background-color: color("grey", "lighten-2");
          margin-top: 60px;
        }

        &:nth-child(3) {
          background-color: color("grey", "lighten-3");
          margin-top: 60px;
        }

        &:nth-child(4) {
          background-color: color("grey", "lighten-4");
          margin-top: 60px;
        }

        &:nth-child(5) {
          background-color: color("grey", "lighten-1");
          @include margin-padding(bottom, 2.5%, null, null);
          margin-bottom: 60px;
        }

        &:nth-child(6) {
          background-color: color("grey", "lighten-2");
          @include margin-padding(bottom, 2.5%, null, null);
          margin-bottom: 60px;
        }

        .second_user_name_no_cam {
          height: 39.25%;
          width: 26%;
          font-size: 4vh !important;
          line-height: 1rem !important;
        }

        .name {
          .user-leaving {
            margin: 1rem;

            h2 {
              margin: 0.5rem;
              font-size: 1.4rem;
            }

            .mat-progress-bar {
              height: 0.6rem;
            }

            .content-percentage-wrap {
              h4 {
                font-size: 1.2rem;
                margin: 0.5rem 0;
              }
            }
          }

          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  &.user6-3 {
    .conference[conference-member="6"] {
      border: 0.5rem solid transparent;
      overflow: hidden;
      padding: 10px !important;

      .conference-member {
        width: calc(100% / 3 - 0.35rem);
        height: 49.5%;
        @include margin-padding(all, 0, null, null);

        &.host {
          border: none;
          margin-right: 0.25rem;
        }

        .second_user_name_no_cam {
          height: 34.22%;
          width: 29%;
          font-size: 4vh !important;
          line-height: 1rem !important;
        }

        .name {
          .user-leaving {
            margin: 1rem;

            h2 {
              margin: 0.5rem;
              font-size: 1.4rem;
            }

            .mat-progress-bar {
              height: 0.6rem;
            }

            .content-percentage-wrap {
              h4 {
                font-size: 1.2rem;
                margin: 0.5rem 0;
              }
            }
          }

          p {
            font-size: 1.6rem;
          }
        }

        &:nth-child(1) {
          border: none;
          margin-right: 0.25rem;
          margin-bottom: 0.25rem;
        }

        &:nth-child(2) {
          border: none;
          margin-left: 0.25rem;
          margin-right: 0.25rem;
          margin-bottom: 0.25rem;
        }

        &:nth-child(3) {
          border: none;
          margin-left: 0.25rem;
          margin-bottom: 0.25rem;
        }

        &:nth-child(4) {
          border: none;
          margin-top: 0.25rem;
          margin-right: 0.25rem;
        }

        &:nth-child(5) {
          border: none;
          margin-top: 0.25rem;
          margin-left: 0.25rem;
          margin-right: 0.25rem;
        }

        &:nth-child(6) {
          border: none;
          margin-top: 0.25rem;
          margin-left: 0.25rem;
        }

        video {
          object-fit: cover;
        }
      }
    }
  }

  &.user7-1 {
    .conference[conference-member="7"] {
        border: 0.5rem solid transparent;
        column-gap: 0.75%;
        row-gap: 0.75%;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        flex-wrap: wrap;
        padding: 0.75% !important;
        .conference-member {
            @include margin-padding(all, 0, null, null);
            height: calc(45% - 0.5rem);
            width: 28.5%;
            height: 32.3%;
            video {
                object-fit: cover;
            }
            &:nth-child(1) {
                background-color: color("grey", "lighten-1");
            }
    
            &:nth-child(2) {
                background-color: color("grey", "lighten-2");
            }
    
            &:nth-child(3) {
                background-color: color("grey", "lighten-3");
            }
    
            &:nth-child(4) {
                background-color: color("grey", "lighten-4");
            }
    
            &:nth-child(5) {
                background-color: color("grey", "lighten-1");
                
            }
    
            &:nth-child(6) {
                background-color: color("grey", "lighten-2");
            }
  
            &:nth-child(7) {
                background-color: color("grey", "lighten-3");
            }
  
            &:nth-child(2) {
                z-index: 101 !important;
            }
            &:nth-child(3) {
                z-index: 100 !important;
            }
            .second_user_name_no_cam {
                font-size: 3vh !important;
                line-height: 1rem !important;
                width: 10vh;
                height: 10vh;
                @media (max-width:#{$small-screen}) {
                    font-size: 3.6vw !important;
                    width: 4vh;
                    height: 4vh;
                }
            }
            .name {
                .user-leaving {
                    margin: 1rem 1rem 0.5rem;
  
                    h2 {
                        margin: 0.5rem;
                        font-size: 1.4rem;
                    }
  
                    .mat-progress-bar {
                        height: 0.6rem;
                    }
  
                    .content-percentage-wrap {
                        h4 {
                            font-size: 1.2rem;
                            margin: 0.5rem 0;
                        }
                    }
                }
  
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }
  }
  
  &.user7-2 {
    .conference[conference-member="7"] {
        border: 0.5rem solid transparent;
        column-gap: 0.5%;
        row-gap: 1%;
        @include flexbox;
        @include align-items(center);
        align-content: center;
        @include justify-content(center);
        flex-wrap: wrap;
        padding: 0% !important;
        .conference-member {
            width: 30.5%;
            height: 36%;
            @include margin-padding(all, 0, null, null);
  
            video {
                object-fit: cover;
            }
  
            &.host {
                height: 16%;
                width: 16%;
                @include abs-position(2.5%, 0, auto, 0);
                z-index: 103 !important;
                @include margin-padding(null, null, all, 0);
                background-color: color("grey", "lighten-2");
                .guest-name {
                    left: -3px;
                    top: 2px;
                    transform: scale(0.9);
  
                    .mic {
                        display: none;
                    }
                }
  
                .name {
                    display: none;
  
                    .inactive-speaker {
                        display: none;
                    }
  
                    .with-rise-hand.style-2 {
                        padding: 0 !important;
                        background: transparent;
                        left: -5px;
                        bottom: -5px;
  
                        .material-icons {
                            font-size: 16px;
                        }
  
                        .material-icons+span {
                            display: none;
                        }
                    }
                }
  
                .second_user_name_no_cam {
                    height: 40%;
                    width: 23%;
                    font-size: 3vh !important;
                    @media (max-width:#{$small-screen-main}) {
                        font-size: 2.5vw !important;
                    }
                }
            }
  
            &:nth-child(1) {
                background-color: color("grey", "lighten-1");
            }
  
            &:nth-child(2) {
                background-color: color("grey", "lighten-2");
                margin-top: 4%;
                z-index: 102 !important;
            }
  
            &:nth-child(3) {
                background-color: color("grey", "lighten-3");
                margin-top: 4%;
                z-index: 101 !important;
            }
  
            &:nth-child(4) {
                background-color: color("grey", "lighten-4");
                margin-top: 4%;
                z-index: 100 !important;
            }
  
            &:nth-child(5) {
                background-color: color("grey", "lighten-1");
                margin-bottom: 4%;
            }
  
            &:nth-child(6) {
                background-color: color("grey", "lighten-2");    
                margin-bottom: 4%;               
            }
            &:nth-child(7) {
                background-color: color("grey", "lighten-3");  
                margin-bottom: 4%;                 
            }
  
            .second_user_name_no_cam {
                height: 39.25%;
                width: 26%;
                font-size: 4vh !important;
                line-height: 1rem !important;
                @media (max-width:#{$small-screen}) {
                    font-size: 4vw !important;
                }
            }
  
            .name {
                .user-leaving {
                    margin: 1rem;
  
                    h2 {
                        margin: 0.5rem;
                        font-size: 1.4rem;
                    }
  
                    .mat-progress-bar {
                        height: 0.6rem;
                    }
  
                    .content-percentage-wrap {
                        h4 {
                            font-size: 1.2rem;
                            margin: 0.5rem 0;
                        }
                    }
                }
  
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }
  }
  
  &.user8-1 {
    .conference[conference-member="8"] {
        border: 0.5rem solid transparent;
        column-gap: 0.75%;
        row-gap: 0.75%;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        flex-wrap: wrap;
        padding: 0.75% !important;
        .conference-member {
            @include margin-padding(all, 0, null, null);
            height: calc(45% - 0.5rem);
            width: 28.5%;
            height: 32.3%;
            video {
                object-fit: cover;
            }
            &:nth-child(1) {
                background-color: color("grey", "lighten-1");
            }
    
            &:nth-child(2) {
                background-color: color("grey", "lighten-2");
            }
    
            &:nth-child(3) {
                background-color: color("grey", "lighten-3");
            }
    
            &:nth-child(4) {
                background-color: color("grey", "lighten-4");
            }
    
            &:nth-child(5) {
                background-color: color("grey", "lighten-1");
                
            }
    
            &:nth-child(6) {
                background-color: color("grey", "lighten-2");
            }
  
            &:nth-child(7) {
                background-color: color("grey", "lighten-3");
            }
            &:nth-child(8) {
                background-color: color("grey", "lighten-4");
            }
  
            &:nth-child(2) {
                z-index: 101 !important;
            }
            &:nth-child(3) {
                z-index: 100 !important;
            }
            .second_user_name_no_cam {
                font-size: 3vh !important;
                line-height: 1rem !important;
                width: 10vh;
                height: 10vh;
                @media (max-width:#{$small-screen}) {
                    font-size: 3.6vw !important;
                    width: 4vh;
                    height: 4vh;
                }
            }
            .name {
                .user-leaving {
                    margin: 1rem 1rem 0.5rem;
  
                    h2 {
                        margin: 0.5rem;
                        font-size: 1.4rem;
                    }
  
                    .mat-progress-bar {
                        height: 0.6rem;
                    }
  
                    .content-percentage-wrap {
                        h4 {
                            font-size: 1.2rem;
                            margin: 0.5rem 0;
                        }
                    }
                }
  
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }
  }
  
  &.user8-2 {
    .conference[conference-member="8"] {
        border: 0.5rem solid transparent;
        column-gap: 0.5%;
        row-gap: 1%;
        @include flexbox;
        @include align-items(center);
        align-content: center;
        @include justify-content(center);
        flex-wrap: wrap;
        padding: 0% !important;
        .conference-member {
            width: 30.5%;
            height: 28%;
            @include margin-padding(all, 0, null, null);
  
            video {
                object-fit: cover;
            }
  
            &.host {
                height: 16%;
                width: 16%;
                @include abs-position(2.5%, 0, auto, 0);
                z-index: 103 !important;
                @include margin-padding(null, null, all, 0);
                background-color: color("grey", "lighten-2");
                
                .guest-name {
                    left: -3px;
                    top: 2px;
                    transform: scale(0.9);
  
                    .mic {
                        display: none;
                    }
                }
  
                .name {
                    display: none;
  
                    .inactive-speaker {
                        display: none;
                    }
  
                    .with-rise-hand.style-2 {
                        padding: 0 !important;
                        background: transparent;
                        left: -5px;
                        bottom: -5px;
  
                        .material-icons {
                            font-size: 16px;
                        }
  
                        .material-icons+span {
                            display: none;
                        }
                    }
                }
  
                .second_user_name_no_cam {
                    font-size: 2.6vh !important;
                    line-height: 1rem !important;
                    width: 7vh;
                    height: 7vh;
                    @media (max-width:#{$small-screen}) {
                        font-size: 2.2vw !important;
                        width: 3vh;
                        height: 3vh;
                    }
                }
            }
  
            &:nth-child(1) {
                background-color: color("grey", "lighten-1");
            }
  
            &:nth-child(2) {
                background-color: color("grey", "lighten-2");
                margin-top: 4%;
                z-index: 102 !important;
            }
  
            &:nth-child(3) {
                background-color: color("grey", "lighten-3");
                margin-top: 4%;
                z-index: 101 !important;
            }
  
            &:nth-child(4) {
                background-color: color("grey", "lighten-4");
                margin-top: 4%;
                z-index: 100 !important;
            }
  
            &:nth-child(5) {
                background-color: color("grey", "lighten-1");
                margin-bottom: 0%;
            }
  
            &:nth-child(6) {
                background-color: color("grey", "lighten-2");    
                margin-bottom: 0%;               
            }
            &:nth-child(7) {
                background-color: color("grey", "lighten-3");  
                margin-bottom: 0%;                 
            }
  
            &:nth-child(8) {
                background-color: color("grey", "lighten-4");  
                margin-bottom: 0%;                 
            }
  
            .second_user_name_no_cam {
                font-size: 3vh !important;
                line-height: 1rem !important;
                width: 10vh;
                height: 10vh;
                @media (max-width:#{$small-screen}) {
                    font-size: 2.6vw !important;
                    width: 4vh;
                    height: 4vh;
                }
            }
  
            .name {
                .user-leaving {
                    margin: 1rem;
  
                    h2 {
                        margin: 0.5rem;
                        font-size: 1.4rem;
                    }
  
                    .mat-progress-bar {
                        height: 0.6rem;
                    }
  
                    .content-percentage-wrap {
                        h4 {
                            font-size: 1.2rem;
                            margin: 0.5rem 0;
                        }
                    }
                }
  
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }
  }
  
  &.user9-1 {
    .conference[conference-member="9"] {
        border: 0.5rem solid transparent;
        column-gap: 0.75%;
        row-gap: 0.75%;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        flex-wrap: wrap;
        padding: 0.75% !important;
        .conference-member {
            @include margin-padding(all, 0, null, null);
            height: calc(45% - 0.5rem);
            width: 28.5%;
            height: 32.3%;
            video {
                object-fit: cover;
            }
            &:nth-child(1) {
                background-color: color("grey", "lighten-1");
            }
    
            &:nth-child(2) {
                background-color: color("grey", "lighten-2");
            }
    
            &:nth-child(3) {
                background-color: color("grey", "lighten-3");
            }
    
            &:nth-child(4) {
                background-color: color("grey", "lighten-4");
            }
    
            &:nth-child(5) {
                background-color: color("grey", "lighten-1");
                
            }
    
            &:nth-child(6) {
                background-color: color("grey", "lighten-2");
            }
  
            &:nth-child(7) {
                background-color: color("grey", "lighten-3");
            }
            &:nth-child(8) {
                background-color: color("grey", "lighten-4");
            }
  
            &:nth-child(9) {
                background-color: color("grey", "lighten-5");
            }
  
            &:nth-child(2) {
                z-index: 101 !important;
            }
            &:nth-child(3) {
                z-index: 100 !important;
            }
            .second_user_name_no_cam {
                font-size: 3vh !important;
                line-height: 1rem !important;
                width: 10vh;
                height: 10vh;
                @media (max-width:#{$small-screen}) {
                    font-size: 3.6vw !important;
                    width: 4vh;
                    height: 4vh;
                }
            }
            .name {
                .user-leaving {
                    margin: 1rem 1rem 0.5rem;
  
                    h2 {
                        margin: 0.5rem;
                        font-size: 1.4rem;
                    }
  
                    .mat-progress-bar {
                        height: 0.6rem;
                    }
  
                    .content-percentage-wrap {
                        h4 {
                            font-size: 1.2rem;
                            margin: 0.5rem 0;
                        }
                    }
                }
  
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }
  }
  
  &.user9-2 {
    .conference[conference-member="9"] {
        border: 0.5rem solid transparent;
        column-gap: 0.5%;
        row-gap: 1%;
        @include flexbox;
        @include align-items(center);
        align-content: center;
        @include justify-content(center);
        flex-wrap: wrap;
        padding: 0% !important;
        .conference-member {
            width: 30.5%;
            height: 28%;
            @include margin-padding(all, 0, null, null);
  
            video {
                object-fit: cover;
            }
  
            &.host {
                height: 16%;
                width: 16%;
                @include abs-position(2.5%, 0, auto, 0);
                z-index: 103 !important;
                @include margin-padding(null, null, all, 0);
                background-color: color("grey", "lighten-2");
                
                .guest-name {
                    left: -3px;
                    top: 2px;
                    transform: scale(0.9);
  
                    .mic {
                        display: none;
                    }
                }
  
                .name {
                    display: none;
  
                    .inactive-speaker {
                        display: none;
                    }
  
                    .with-rise-hand.style-2 {
                        padding: 0 !important;
                        background: transparent;
                        left: -5px;
                        bottom: -5px;
  
                        .material-icons {
                            font-size: 16px;
                        }
  
                        .material-icons+span {
                            display: none;
                        }
                    }
                }
  
                .second_user_name_no_cam {
                    font-size: 2.6vh !important;
                    line-height: 1rem !important;
                    width: 7vh;
                    height: 7vh;
                    @media (max-width:#{$small-screen}) {
                        font-size: 2.2vw !important;
                        width: 3vh;
                        height: 3vh;
                    }
                }
            }
  
            &:nth-child(1) {
                background-color: color("grey", "lighten-1");
            }
  
            &:nth-child(2) {
                background-color: color("grey", "lighten-2");
                margin-top: 4%;
                z-index: 102 !important;
            }
  
            &:nth-child(3) {
                background-color: color("grey", "lighten-3");
                margin-top: 4%;
                z-index: 101 !important;
            }
  
            &:nth-child(4) {
                background-color: color("grey", "lighten-4");
                margin-top: 4%;
                z-index: 100 !important;
            }
  
            &:nth-child(5) {
                background-color: color("grey", "lighten-1");
                margin-bottom: 0%;
            }
  
            &:nth-child(6) {
                background-color: color("grey", "lighten-2");    
                margin-bottom: 0%;               
            }
            &:nth-child(7) {
                background-color: color("grey", "lighten-3");  
                margin-bottom: 0%;                 
            }
  
            &:nth-child(8) {
                background-color: color("grey", "lighten-4");  
                margin-bottom: 0%;                 
            }
  
            &:nth-child(9) {
                background-color: color("grey", "lighten-5");  
                margin-bottom: 0%;                 
            }
  
            .second_user_name_no_cam {
                font-size: 3vh !important;
                line-height: 1rem !important;
                width: 10vh;
                height: 10vh;
                @media (max-width:#{$small-screen}) {
                    font-size: 2.6vw !important;
                    width: 4vh;
                    height: 4vh;
                }
            }
  
            .name {
                .user-leaving {
                    margin: 1rem;
  
                    h2 {
                        margin: 0.5rem;
                        font-size: 1.4rem;
                    }
  
                    .mat-progress-bar {
                        height: 0.6rem;
                    }
  
                    .content-percentage-wrap {
                        h4 {
                            font-size: 1.2rem;
                            margin: 0.5rem 0;
                        }
                    }
                }
  
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }
  }
  
  &.customization,
  &.chat-enabled {
    @include margin-padding(right, 36rem, null, null);
    transition: all 0.3 ease;
  }

  .logo-image {
    @include abs-position(0, 1.5rem, auto, auto);
    // width: 20rem;
    // height: 8.3rem;
    width: auto;
    height: auto;
    text-align: right;
    z-index: 99;

    img {
      height: auto;
      max-height: 100%;
      position: static;
      margin: 0;
    }
  }

  .container {
    max-width: 100%;

    &.layout-preview {
      text-align: center;
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      height: 5rem;

      .mat-radio-button {
        margin: 1rem 2rem;
      }

      .close {
        color: color("accent", "base");

        .material-icons {
          color: color("accent", "base");
        }
      }
    }
  }

  .recording-info {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    border-radius: 0;
    @include margin-padding(all, 0, all, 0 1.5rem);
    min-width: auto;
    min-height: auto;
    background-color: color("accent", "base");

    p {
      color: color("shades", "white");
      font-weight: 500;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
    }

    .mat-button.mat-button-base {
      color: color("shades", "white");
      @include abs-position(0, -18rem, 0, auto);
      height: 3rem;
      line-height: 1.5;
      border: 1px solid color("shades", "white");
    }

    .material-icons {
      color: color("shades", "white");

      &.warning {
        @include margin-padding(right, 0.8rem, null, null);
      }
    }
  }

  header.logo {
    display: block;
    height: auto;

    p {
      text-align: center;
      @include margin-padding(all, 0, all, 0.7rem 0);
      position: relative;

      .button {
        @include margin-padding(left, 5.6rem, all, 0.5rem 1rem !important);
      }
    }
  }

  .guest-cam {
    overflow: hidden;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    height: 100%;
    background: #000;
    transition: all 0.3s;
    min-height: 100%;
    width: 100%;
    position: relative;

    video {
      transition: all 0.3s;
      top: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      @include margin-padding(all, auto, null, null);
    }

    img {
      width: 100%;

      @include breakpoint(small) {
        height: 100%;
        width: auto;
        max-width: initial;
      }
    }
  }

  .mat-list-base .mat-list-item .mat-list-item-content {
    @include margin-padding(null, null, all, 0 1rem);
    font-size: 1.4rem;

    @include breakpoint(small) {
      @include margin-padding(null, null, all, 0 0.5rem);
    }
  }

  .actions {
    @include abs-position(auto, 0, 0, 0);
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    @include margin-padding(null, null, all, 1rem);
    background-color: color("grey", "lighten-1");

    &.recording {
      @include abs-position(auto, auto, 1.2rem, 1.5rem);
      justify-content: flex-start;
      @include margin-padding(null, null, all, 0.5rem);
      width: auto;
      z-index: 1;
      border-radius: $default-radius;
      background-color: color("shades", "black");

      @include breakpoint(small) {
        @include margin-padding(null, null, all, 0.8rem);
        top: 7.2rem;
      }

      .mat-button {
        .blink-animation {
          animation: blinker 2.5s linear infinite;
          @include flexbox;
          @include align-items(center);
          line-height: 1.5;
        }
      }
    }

    &.mat-list-base .mat-list-item {
      width: auto;
      background-color: transparent !important;

      &.stop-rec {
        .mat-button {
          background-color: color("shades", "white");

          .material-icons {
            color: color("red", "base");
          }
        }
      }
    }

    .record {
      .mat-button-wrapper {
        font-size: 1.4rem;
      }

      span.dot {
        width: 1rem;
        height: 1rem;
        background: color("red", "base");
        display: inline-block;
        border-radius: 0.2rem;
        @include margin-padding(bottom, 0 0.5rem 0 0, null, null);
      }
    }

    .mat-button {
      padding: $no-padding;
      min-width: auto;
      line-height: 0;
      height: 3.6rem;
      width: 3.6rem;
      border-radius: $circular-radius;
      background-color: rgba(0, 0, 0, 0.75);

      &.cdk-program-focused .mat-button-focus-overlay {
        opacity: 0;
      }

      .material-icons {
        font-size: 2rem;
        color: color("shades", "white");
      }
    }

    .record {
      .mat-button {
        height: auto;
        width: auto;
        background-color: color("shades", "transparent");
        cursor: default;

        .mat-button-wrapper {
          @include flexbox;
          @include align-items(center);
          font-weight: 500;
          line-height: 1.5;

          .rec {
            @include margin-padding(right, 1.5rem, null, null);
          }

          .material-icons {
            @include margin-padding(right, 0.4rem, null, null);
            font-size: 2.4rem;
            cursor: default;
          }
        }
      }
    }

    .red-text {
      .mat-button {
        color: color("red", "base");
        border: 0;

        .material-icons {
          color: color("red", "base");
        }
      }
    }
  }

  .guest-name {
    @include abs-position(1rem, auto, auto, 1rem);
    @include flexbox;
    @include align-items(center);
    cursor: pointer;
    z-index: 99;

    .mat-list-base .mat-list-item .mat-list-item-content {
      padding: $no-padding;
    }

    .mat-button {
      min-width: auto;
      padding: $no-padding;
      border-radius: $circular-radius;

      &.red .material-icons {
        background-color: color("red", "base");
      }
    }

    p {
      background-color: rgba(0, 0, 0, 0.75);
      color: color("shades", "white");
      @include margin-padding(all, 0 1rem 0 0, all, 0.5rem 1.2rem);
      border-radius: $rounded-radius;
      // max-width: 10rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .call_end {
      .material-icons {
        background-color: color("red", "base");
        color: color("shades", "white") !important;
      }
    }

    .material-icons {
      height: 3.1rem;
      width: 3.1rem;
      font-size: 2rem;
      background-color: rgba(0, 0, 0, 0.75);
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      border-radius: $circular-radius;
      color: color("shades", "white");
    }

    .mat-list-item-content {
      @include margin-padding(bottom, 1rem, all, 0);
    }
  }

  @include breakpoint(small) {
    .guest-name .options {
      right: -0.5rem;
      text-align: right;
    }

    .mat-list-base.options .mat-list-item {
      display: inline-block;
      width: auto;
      @include margin-padding(all, 0 0.5rem, null, null);
    }
  }
}

.conference-member {
  position: relative;

  .vu-meter {
    @include abs-position(auto, 1rem, 1rem, auto);
    width: 0.8rem;
    height: 25%;

    .meter-color {
      position: relative;
      background-image: linear-gradient(to bottom, red 1%, rgb(255, 255, 0) 16%, lime 45%, rgb(0, 136, 0) 100%);
      width: 100%;
      height: 100%;

      .meter-level {
        background-color: color("grey", "lighten-1");
        width: 100%;
        @include abs-position(0, 0, auto, 0);
        transition: height 0.1s ease 0s;
      }
    }
  }

  &.host {
    .more-options {
      display: none;
    }
  }

  video {
    height: 100% !important;
    object-fit: cover;
  }
}

.conference {
  background: color("shades", "black");

  .conference-member {
    .canvas {
      @include abs-position(auto, 0, 0, auto);
      height: 25%;
      @include flexbox;
    }
  }
}

.host-green-room {
  .conference {
    height: calc(100% - 10.6rem) !important;
  }

  //   &.live-room-page {
  //     .conference {
  //       height: calc(100vh - 5.6rem) !important;
  //     }
  //   }
}

.top-banner {
  .conference {
    // height: calc(100% - 9.4rem) !important;
  }
}

.conference[conference-member="1"] {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);

  // height: calc(100% - 5.6rem);
  .conference-member {
    height: auto;
    width: 70%;
    height: 90%;
    background-color: color("grey", "lighten-1");

    video {
      border: 1px solid transparent;
      transition: all 0.5s;

      &.voice-video {
        // border: 1px solid color("shades", "white");
        box-shadow: 0px 0px 0px 4px color("accent", "base");
        transition: all 0.5s;
      }
    }
  }

  &+.main-inner-cam-area {
    display: none;
  }
}

.conference[conference-member="2"] {
  @include flexbox;
  @include align-items(center);

  // height: calc(100% - 5.6rem);
  .conference-member {
    width: 47.5%;
    height: 50%;
    @include margin-padding(all, 0 2.5% 0 1.25%, null, null);
    background-color: color("grey", "lighten-2");

    &.host {
      @include margin-padding(all, 0 1.25% 0 2.5%, null, null);
      background-color: color("grey", "lighten-1");
    }

    video {
      object-fit: cover;
    }
  }

  &+.main-inner-cam-area {
    display: none;
  }
}

.conference[conference-member="3"] {
  @include flexbox;
  @include justify-content(center);
  flex-wrap: wrap;

  // height: calc(100% - 5.6rem);
  .conference-member {
    width: 35%;
    height: 41.75%;
    @include margin-padding(all, 2.5% 1.25% 2.5% 2.5%, null, null);
    background-color: color("grey", "lighten-1");

    &:nth-child(2) {
      @include margin-padding(all, 2.5% 2.5% 2.5% 1.25%, null, null);
      background-color: color("grey", "lighten-2");
    }

    &:nth-child(3) {
      @include margin-padding(top, 0, null, null);
      background-color: color("grey", "lighten-3");
    }
  }
}


.conference[conference-member="4"] {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  flex-wrap: wrap;
  align-content: center;

  .conference-member {
    width: 45%;
    height: calc(50% - 13px);
    @include margin-padding(all, 0 0 0 0, null, null);

    video {
      object-fit: cover;
    }

    &:nth-child(2n) {
      @include margin-padding(left, 0, null, null);
    }

    &:nth-child(1) {
      background-color: color("grey", "lighten-1");
      margin-top: 10px;
    }

    &:nth-child(2) {
      background-color: color("grey", "lighten-2");
      margin-top: 10px;
    }

    &:nth-child(3) {
      background-color: color("grey", "lighten-3");
      margin-bottom: 10px;
    }

    &:nth-child(4) {
      background-color: color("grey", "lighten-4");
      margin-bottom: 10px;
    }
  }
}


.green-room.user6-1 .conference[conference-member="6"] {
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  align-content: center;

  .conference-member {
    @include margin-padding(all, 0, null, null);
    height: calc(45px - 0.5rem);
    width: 28.5%;
    height: 44%;
  }
}

.conference[conference-member="5"],
.conference[conference-member="6"] {
  // height: calc(100% - 5.6rem);
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  flex-wrap: wrap;

  .conference-member {
    width: 30.5%;
    height: 41.75%;
    @include margin-padding(all, 0, null, null);

    video {
      object-fit: cover;
    }

    &:nth-child(1) {
      background-color: color("grey", "lighten-1");
    }

    &:nth-child(2) {
      background-color: color("grey", "lighten-2");
    }

    &:nth-child(3) {
      background-color: color("grey", "lighten-3");
    }

    &:nth-child(4) {
      background-color: color("grey", "lighten-4");
      @include margin-padding(bottom, 2.5%, null, null);
    }

    &:nth-child(5) {
      background-color: color("grey", "lighten-1");
      @include margin-padding(bottom, 2.5%, null, null);
    }

    &:nth-child(6) {
      background-color: color("grey", "lighten-2");
      @include margin-padding(bottom, 2.5%, null, null);
    }
  }
}

.host-green-room {
  .conference {
    height: calc(100% - 10.6rem);
  }
}

.top-banner {
  .conference {
    height: calc(100% - 9.4rem);
  }
}

.bg-color {
  background-color: color("grey", "lighten-6");
  height: 100%;
}

.horizontal {
  background-color: #bbb;
  padding: 10px;
  height: 250px;
  overflow: hidden;
  margin: 15px;
  @include flexbox;

  img {
    height: 100px;
    width: 100px;
  }
}

.vertical {
  background-color: #bbb;
  padding: 10px;
  overflow: hidden;
  display: flex;
  height: 250px;
  flex-direction: column;
  margin: 15px;

  img {
    height: 100px;
    width: 100px;
  }
}

.conference.cam2-layout2[conference-member="2"] .conference-member:nth-child(1) {
  width: 80%;
}

.conference.cam2-layout2[conference-member="2"] .conference-member:nth-child(2) {
  width: 20%;
  height: auto;
}

/*==== Customization css starts====*/

.layout {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  background-color: #fff;
  height: 100vh;
  width: 36rem;
  transition: all 0.3s ease;
  overflow-y: auto;

  app-banner,
  app-upload-logo,
  app-background-conference,
  app-layout-conference {
    // @include margin-padding(top, 0.5rem, null, null);
    margin: 1rem 0;
    display: block;
  }

  .mat-expansion-panel {
    overflow: visible;
  }

  &.configuration-layout {
    position: static;
    height: auto;
    width: 100%;
    overflow: visible;

    .mat-expansion-panel {
      overflow: visible;
    }

    .layout-container {
      @include margin-padding(null, null, all, 0rem 0);

      .mat-radio-group {
        overflow: hidden;
      }
    }
  }

  .header {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    background-color: color("accent", "base");
    @include margin-padding(null, null, all, 0 1.5rem);
    border-left: 1px solid color("shades", "white");
    height: 5rem;

    h2 {
      font-weight: 500;
    }

    h2,
    .mat-button,
    .material-icons {
      color: #fff;
    }
  }

  .mat-tab-body[ng-reflect-position="0"] {
    .logo-container-outer {
      @include margin-padding(bottom, 0, null, null);
    }
  }

  .layout-container {
    @include margin-padding(null, null, all, 1.5rem);

    @media (max-width:#{$small-screen-up}) {
      .configuration-dropdown {
        width: 100%;
      }
    }

    .user {
      p {
        margin-bottom: 4px;
      }

      span {
        margin-left: 5px;
        border: 1px solid transparent;
        display: inline-block;

        &:hover {
          cursor: pointer;
          border: 1px solid color("accent", "base");
        }

        &:nth-child(1) {
          margin: 0;
        }
      }
    }

    .mat-radio-group {
      clear: both;
      display: block;

      .mat-radio-button {
        display: block;
        width: 50%;
        float: left;

        &:nth-child(1),
        &:nth-child(2) {
          @include margin-padding(bottom, 0.3rem, null, null);
        }
      }
    }
  }

  .logo-customization {
    .logo-container-outer {
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      @include margin-padding(all, 0.5rem 0 1rem, null, null);
    }
  }

  .mat-tab-labels {
    @include margin-padding(bottom, 1rem, null, null);
    border-bottom: 1px solid color("grey", "lighten-7");
  }

  .border-top {
    @include margin-padding(null, null, top, 1.5rem);
    border-top: 1px solid color("grey", "lighten-7");
    width: 100%;
  }

  p {
    color: color("primary", "base");
  }
}

.logo-with-info {
  @include flexbox;
  @include align-items(center);
  @include margin-padding(all, 0 0 1rem 0, null, null);
  flex-wrap: wrap;

  p {
    font-size: 1.4rem;
    @include margin-padding(left, 2rem, null, null);
    width: 17rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    span {
      width: 0.1rem;
      height: 1.6rem;
      background: #c7c7c7;
      display: inline-block;
      @include margin-padding(all, 0 1rem, null, null);
      position: relative;
      top: 0.2rem;
    }
  }
}

.customization .tagline,
.chat-enabled .tagline {
  // @include margin-padding(null, null, all, 1.5rem);

  p {
    font-size: 1.2vw;
  }
}

.tagline {
  @include margin-padding(null, null, all, 1.5rem 6rem);
  background-color: color("accent", "base");
  width: calc(100% / 3);
  text-align: center;
  z-index: 99;
  height: 6rem; //6.4
  transform: skew(-20deg);
  border-radius: $default-radius;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);

  @include breakpoint(xxlarge) {
    @include margin-padding(null, null, all, 1.5rem 3rem);
  }

  p {
    color: color("shades", "white");
    font-size: 1.4vw; //1.52
    font-weight: 500;
    @include margin-padding(all, 0, null, null);
    transform: skew(20deg);
    white-space: nowrap;

    // @include breakpoint(xxxxxlarge) {
    //   font-size: 1.7rem;
    // }

    // @include breakpoint(xxxlarge) {
    //   font-size: 1.6rem;
    // }

    // @include breakpoint(xxlarge) {
    //   font-size: 1.5rem;
    // }
  }

  &.left {
    @include abs-position(auto, auto, 7.1rem, -2rem);

    &.top-left {
      top: 1.5rem;
      bottom: auto;
      transform: skew(20deg);

      p {
        transform: skew(-20deg);
      }
    }

    &.top-right {
      top: 1.5rem;
      bottom: auto;
      left: auto;
      right: -2rem;
    }

    &.bottom-right {
      top: auto;
      bottom: 7.1rem;
      left: auto;
      right: -2rem;
      transform: skew(20deg);

      p {
        transform: skew(-20deg);
      }
    }
  }
}

.host-green-room {
  .left {
    &.top-left {
      top: 6.5rem;
    }

    &.top-right {
      top: 6.5rem;
    }
  }
}

.live-room-page {
  .left {

    &.top-right,
    &.top-left {
      top: 1.5rem;
    }
  }
}

.top-banner {
  .left {

    &.top-right,
    &.top-left {
      top: 5.3rem;
    }
  }
}

/*==== Customization css ends====*/

.main-inner-cam-area {
  @include flexbox;
  position: relative;
  height: 100%;
}

/*==== Chat css starts ====*/

.chat {
  textarea.mat-input-element {
    background-color: color("grey", "lighten-7");
    width: calc(100% - 9rem);
    line-height: 2.6;
    box-sizing: content-box;
    min-height: 1.5rem;
    @include margin-padding(null, null, all, 0.4rem 1.5rem);

    &+.material-icons {
      right: 6rem;
      top: 2.8rem;
    }
  }

  .mat-mini-fab {
    top: auto;
    height: 5rem;
    width: 5rem;
    bottom: 1rem;
  }

  &.mat-form-field-appearance-legacy .mat-form-field-label {
    top: 3.2rem;
  }
}

#chat {
  width: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: color("shades", "white");
  overflow-x: hidden;
  transition: all 0.3s ease-out;

  &.open {
    width: 36rem;
    @include flexbox;
    flex-direction: column;
    @include justify-content(space-between);
    height: 100vh;
    overflow: hidden;
    @include margin-padding(null, null, bottom, 9rem);
  }

  .heading {
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: capitalize;
    color: color("shades", "white");
  }

  .chat-heading {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include margin-padding(all, 0 0rem, all, 0 1.5rem);
    background-color: color("accent", "base");
    @include box-shadow($box-shadow-white-container);

    @include breakpoint(small) {
      @include margin-padding(all, 0 0rem, all, 0 2rem);
    }

    .close {
      color: color("shades", "white");
      padding: $no-padding;

      .material-icons {
        color: color("shades", "white");
      }
    }
  }

  .chat-box-inner {
    @include margin-padding(null, null, all, 0 1.5rem);
    max-height: calc(100% - 6rem);
    overflow: auto;

    @include breakpoint(small) {
      @include margin-padding(null, null, all, 0 2rem);
    }
  }

  .chat-user-details {
    @include flexbox;
    @include align-items(center);
    @include margin-padding(top, 4rem, all, 0 0rem);

    &.own-chat {
      @include justify-content(flex-start);
      flex-direction: row-reverse;
      @include align-items(center);

      .name {
        @include margin-padding(right, 1rem, null, null);
      }
    }

    span.avatar {
      height: 3.5rem;
      width: 3.5rem;
      background-color: color("primary", "base");
      display: inline-block;
      border-radius: $circular-radius;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      color: color("shades", "white");
      font-size: $h5-fontsize;
      font-weight: 400;
    }

    .name {
      font-size: $h5-fontsize;
      font-weight: 500;
      @include margin-padding(left, 1rem, null, null);
    }
  }

  .message {
    @include margin-padding(top, 0.5rem, all, 1.2rem);
    background-color: color("grey", "lighten-7");
    @include box-shadow($box-shadow-white-container);
    border-radius: $default-radius;
    display: table;

    &.own-message {
      text-align: left;
      background-color: color("accent", "base");
      @include margin-padding(left, auto, null, null);
      color: color("shades", "white");
    }

    span {
      display: block;
    }

    .message-text {
      font-size: $h5-fontsize;
      word-break: break-all;
    }

    .date {
      font-size: 1.2rem;
      opacity: 0.75;
      @include margin-padding(top, 0.4rem, null, null);
    }
  }

  a {
    transition: 0.3s;
  }

  &.mystyle {
    @include margin-padding(null, null, all, 0 4rem 3rem);
  }

  .mat-form-field.chat {
    @include abs-position(auto, auto, 0rem, auto);
    width: 100%;
    @include margin-padding(null, null, all, 0 1.5rem);
    position: fixed;
    max-width: 36rem;
  }
}

#main {
  transition: margin-left 0.5s;
}

/*==== Chat css ends ====*/

/* ==== Green room css ends ==== */

/* ==== Live room css starts ==== */

.green-room.live-room .guest-cam {
  height: calc(100vh - 7.2rem);
}

/* ==== Live room css ends ==== */

/*==== Account settings css starts ====*/
.charts {
  .progress-bar {
    @include margin-padding(top, 1rem, null, null);

    @include breakpoint(x-small) {
      .outer-div {
        display: block;
      }
    }

    &.storage {
      @include margin-padding(top, 1.5rem, null, null);
    }

    p {
      font-size: 1.4rem;
      @include margin-padding(all, 0.6rem 0 0, null, null);
    }

    .mat-chip-list {
      display: inline-block;
      @include margin-padding(all, 0.3rem 0 0, null, null);

      .mat-standard-chip {
        @include margin-padding(all, 0, null, null);
      }
    }
  }
}

.latest-transaction {
  .last-sub {
    @include margin-padding(top, 1.5rem, null, null);
  }

  .renew {
    @include margin-padding(top, 1rem, null, null);
  }
}

.renews {
  font-size: 1.4rem;
}

.add-charge {
  border-top: 0.1rem solid color("primary", "light");
  @include margin-padding(top, 1.5rem, top, 1.5rem);
}

.payment-info {
  @include margin-padding(null, null, all, 2rem 3rem);

  .payment-history .outer-div {
    @include breakpoint(x-small) {
      display: block;

      .top {
        display: block;
      }
    }
  }

  &.latest-transaction {
    height: 33.7rem;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
  }

  .plan-details {
    @include margin-padding(all, 1rem 0, null, null);
  }

  .outer-div {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    flex-wrap: wrap;

    &.pricing {
      @include align-items(baseline);

      @include breakpoint(x-small) {
        display: block;
      }
    }
  }

  .billing-details {
    border-top: 0.1rem solid color("grey", "lighten-7");
    @include margin-padding(top, 1.5rem, top, 1.5rem);
  }

  .mat-table {
    @include margin-padding(top, 1.5rem, null, null);
  }

  .plan-info {
    @include flexbox;
    @include align-items(center);

    .material-icons {
      color: color("accent", "base");
      @include margin-padding(right, 0.4rem, null, null);
      font-size: 2.2rem;
    }
  }

  .price {
    @include margin-padding(all, 1.5rem 0 0, null, null);

    span {
      font-size: 2.4rem;
      font-weight: 700;
    }
  }

  .renew,
  .last-sub {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    font-size: 1.4rem;

    .bold {
      font-weight: 700;
    }
  }

  p {
    @include margin-padding(all, 0, null, null);
    font-size: 1.6rem;
    color: color("grey", "lighten-1");
  }

  .card-detail {
    @include flexbox;
    @include align-items(center);
    @include margin-padding(all, 1.5rem 0, null, null);

    span {
      @include margin-padding(all, 0 1rem, null, null);
    }

    .card-number {
      color: color("primary", "light-base");
      @include margin-padding(all, 0, null, null);

      @include breakpoint(medium) {
        display: block;
        @include margin-padding(left, 1rem, null, null);
      }
    }

    &.no-record {
      @include margin-padding(bottom, 0, all, 0.8rem 0);

      svg path {
        fill: color("primary", "base");
      }

      .img-div {
        width: 7.1rem;
      }

      .no-card-data {
        text-align: left;
        @include margin-padding(left, 1.5rem, null, null);

        h6 {
          @include margin-padding(bottom, 0.5rem, null, null);
        }

        p {
          font-size: 1.4rem;
          @include margin-padding(all, 0 !important, null, null);
          color: color("grey", "lighten-3");
        }
      }
    }
  }

  .expiry-date {
    font-size: 1.3rem;
    color: color("primary", "base");

    .expiry {
      @include margin-padding(left, 3rem, null, null);
    }
  }

  a {
    cursor: pointer;
  }
}

.payment-history {
  color: color("primary", "base");
  font-size: 1.4rem;

  p {
    font-size: 1.4rem;
  }

  .top {
    @include margin-padding(top, 1rem, null, null);
    display: inline-block;
  }

  .bottom {
    @include margin-padding(top, 1rem, null, null);
    display: inline-block;
  }
}

.billing-method {
  @include margin-padding(all, 2rem 0 4rem 0, null, null);

  h4 {
    @include margin-padding(all, 0 0 1rem 0, null, null);
  }

  .card-detail {
    @include flexbox;
    flex-wrap: wrap;
    @include align-items(center);

    p {
      @include margin-padding(all, 0, null, null);
      @include flexbox;
      flex-wrap: wrap;
      @include align-items(center);

      img {
        @include margin-padding(all, 0 0.8rem 0 0, null, null);
      }
    }

    .button {
      @include margin-padding(all, 0 0 0 1.5rem, null, null);
    }
  }
}

.btn-area {
  .button {
    @include margin-padding(all, 0 1rem 0 0, null, null);
  }
}

.select-color-area {
  @include flexbox;
  flex-wrap: wrap;
  @include align-items(flex-start);
  position: relative;
  flex-direction: column;

  .color-picker {
    top: 5rem !important;
    left: 0 !important;

    .arrow {
      display: none;
    }
  }

  span {
    width: 3.2rem;
    height: 3.2rem;
    display: inline-block;
    border-radius: 100%;
    @include margin-padding(all, 0 0.6rem 0 0, null, null);
    font-size: 3.6rem;
    cursor: pointer;

    &.color-box {
      border: 0.1rem solid color("grey", "lighten-7");
    }

    &.blue {
      background: #5249e7;
    }

    &.yellow {
      background: #f8ca15;
    }

    &.red {
      background: #df3056;
    }

    &.green {
      background: #2ab8a4;
    }

    &.add-more {
      color: color("accent", "base");
      cursor: pointer;
      top: -0.2rem;
    }
  }
}

/*==== Account settings css ends ====*/

.slide-lefr-bar {
  display: none;

  @include breakpoint(x2large) {
    display: flex;
    align-items: center;
    // margin-top: 9px;
    background-color: #e5e5e5;
    height: 3.5rem;
    // border-radius: 1rem;
    // margin-left: 1.5rem;

    span {
      font-size: 2.8rem;
      margin: 1rem;
    }
  }

  @include breakpoint(medium) {
    @include margin-padding(null, null, all, 0);
  }

  @include breakpoint(small) {
    @include margin-padding(null, null, all, 0);
  }

  @include breakpoint(x-small) {
    @include margin-padding(all, 0, null, null);
  }
}

.main-full-area {
  .left-area-main {
    @include breakpoint(x2large) {
      &.open {
        left: 0;
        position: relative;
        z-index: 12;

        .mat-drawer.sidebar {
          left: 0;
          z-index: 12;
        }
      }

      .mat-drawer.sidebar {
        left: -40rem;
      }
    }
  }

  .bg-back {
    display: none;

    @include breakpoint(x2large) {
      display: block;

      &.open {
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        cursor: pointer;
        background: #000;
        z-index: 11;
        opacity: 0.4;
      }
    }
  }

  .mat-drawer-container.full-width-container.drawer-right {
    @include margin-padding(all, 0, left, 22rem);
    width: 100%;

    @include breakpoint(x2large) {
      @include margin-padding(null, null, left, 0);
    }

    .mat-drawer-content {
      height: auto;
      overflow: initial;
    }
  }

  .mat-drawer.sidebar {
    height: 100vh;
    position: fixed;
    top: 0;

    @include breakpoint(medium) {
      left: -40rem;
      left: 0;
      width: 100%;
      max-width: 30rem;

      .mat-drawer-inner-container {
        width: 100% !important;
      }
    }

    @include breakpoint(small) {
      max-width: 30rem;
      width: 86%;
    }

    .mat-drawer-inner-container {
      @include breakpoint(xxxxxlarge) {
        max-width: 30rem;
        width: 100%;
        @include margin-padding(null, null, all, 0);

        .ng-scrollbar {
          .ng-scroll-content {
            @include margin-padding(null, null, all, 2.2rem 2.2rem 0);
          }
        }
      }
    }
  }
}

.session-main-area {
  position: relative;

  // @include breakpoint(medium) {
  //   flex-direction: column;
  //   @include align-items(flex-start);
  //   @include justify-content(flex-start);
  // }

  .left-main {
    @include breakpoint(medium) {
      max-width: 100%;
      width: 100% !important;
    }
  }

  .right-main {
    @include breakpoint(medium) {
      max-width: 100%;
      width: 100% !important;

      .right-content {
        @include justify-content(flex-start);
        @include margin-padding(all, 1rem 0 0, null, null);
        flex-wrap: wrap;

        .guest-details {
          @include margin-padding(left, 0, null, null);
        }
      }
    }

    .right-content {
      @include breakpoint(small) {
        flex-direction: column;
        @include align-items(flex-start);

        .guest-details {
          @include margin-padding(all, 0.5rem 0 1rem, null, null);
        }
      }
    }
  }
}

.audio-player {
  audio {
    width: 100%;
    height: 4rem;

    &:focus {
      outline: 0;
    }
  }
}

.scroll-event {
  height: 10rem;
}

.nowrap {
  white-space: nowrap !important;
}

.timepicker__header {
  background-color: color("accent", "base") !important;
}

.clock-face__number>span.active {
  background-color: color("accent", "base") !important;
}

.clock-face__clock-hand {
  background-color: color("accent", "base") !important;
}

.timepicker-button {
  color: color("accent", "base") !important;
}

.dashboard {
  &.dashboard-main {
    @include margin-padding(bottom, 8rem, null, null);
    // margin-top: 35px !important;

    .row.dashboard-heading {
      @include breakpoint(small) {
        flex-direction: column-reverse;
      }
    }
  }

  .no-plan {
    .no-record.session-container {
      @include margin-padding(all, 0, all, 1rem 0 0);
    }
  }

  .dashboard-heading {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include margin-padding(bottom, 2.5rem, null, null);

    @include breakpoint(x-small) {
      @include margin-padding(bottom, 1rem, null, null);
    }

    .session-sorting {
      border: 1px solid color("grey", "lighten-6");
      width: max-content;
      border-radius: $default-radius;
      overflow: hidden;
      height: 4.5rem;

      .mat-button-base {
        border-radius: 0;
        line-height: 4.5rem;
        @include margin-padding(null, null, all, 0 2.4rem);
        background-color: color("shades", "white");

        @include breakpoint(x-small) {
          @include margin-padding(null, null, all, 0 1.9rem);
          font-size: 1.4rem;
        }

        &.filled {
          color: color("shades", "white");
          font-weight: 500;
          background-color: color("accent", "base");
        }
      }
    }

    .mat-form-field-appearance-fill .mat-form-field-infix {
      border: 0;
    }

    h1 {
      @include margin-padding(all, 0, null, null);
    }

    @include breakpoint(small) {
      flex-direction: column;
      @include flexbox;

      .col {
        width: 100% !important;
      }
    }
  }
}

.mat-error {
  font-size: 1.2rem;
  color: color("accent", "base");
  text-align: left;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  @include margin-padding(null, null, all, 0 !important);
}

.input-with-icon {
  .icon-right {
    height: 2.4rem;
    width: 2.4rem;
    position: absolute;
    right: 1rem !important;
    top: 1.5rem !important;
    pointer-events: none;
    z-index: 1;
    color: #bbbbbb !important;
    opacity: 1 !important;
  }
}

.remember-area {
  @include margin-padding(top, 1rem, null, null !important);
}

.btn-area {
  @include breakpoint(medium) {
    @include margin-padding(all, 0 0 1.5rem 0, null, null);
  }
}

html.cdk-global-scrollblock {
  overflow-y: hidden;

  body {
    overflow: hidden;
  }
}

/* Live room customization css starts*/

#chat.live-room-customization {
  width: 36.2rem;
  overflow: hidden;
  @include abs-position(6.2rem, 0, null, auto);
  height: calc(100% - 6.2rem);
  z-index: 9;

  .chat-heading {
    height: 7.2rem;
  }

  .lrc-inner {
    @include margin-padding(null, null, all, 3.6rem);

    h4 span {
      font-weight: 400;
      font-size: 1.4rem;
    }
  }

  .choose-color {
    @include margin-padding(all, 2.6rem 0 0, top, 2.6rem);
    border-top: 0.1rem solid color("primary", "light");
  }
}

/* Live room customization css ends*/

/*==== Recover upload css starts ====*/
app-recover-upload {
  header.logo.mat-dialog-container {
    @include justify-content(flex-start);
    @include margin-padding(null, null, all, 0 3rem !important);

    .logo-image {
      @include margin-padding(all, 0, null, null);
    }
  }
}

.recover-upload {
  * {
    font-size: 1.6rem;
  }

  display: block;
  max-width: 80rem;
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  border-radius: $default-radius;
  @include margin-padding(all, auto, all, 3rem);

  h2.please-wait {
    @include margin-padding(all, 3rem 0 2rem, null, null);
  }

  .no-record {
    @include margin-padding(top, 3rem, null, null);
  }

  ul {
    list-style-type: none;
    @include margin-padding(null, null, all, 0);

    li {
      background-color: color("shades", "white");
      height: 6.7rem;
      border-radius: $default-radius;
      @include margin-padding(all, 1rem 0, all, 2rem);
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);

      .button-small {
        @include margin-padding(left, 1.5rem, null, null);
      }
    }
  }

  .back-to-homepage {
    @include margin-padding(top, 3rem, null, null);

    &.record-none {
      text-align: center;
    }

    .material-icons {
      font-size: 2.4rem;
      color: color("shades", "white");
    }
  }
}

/*==== Recover upload css ends ====*/

/* ==== Referrals page css starts ==== */

.referrals {
  .or-option {
    h3 {
      position: relative;

      @include breakpoint(medium) {
        @include margin-padding(top, 5rem !important, null, null);
      }

      &::before {
        @include abs-position(4rem, auto, auto, -6.2rem);
        content: "Or";
        color: color("grey", "lighten-1");
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        font-size: 1.6rem;

        @include breakpoint(medium) {
          left: 0;
          top: -3.7rem;
        }
      }
    }
  }

  .session-container {
    @include margin-padding(null, null, all, 3rem);

    @include breakpoint(medium) {
      @include margin-padding(null, null, all, 3rem 1.5rem);
    }
  }

  .benefits-outer {
    @include flexbox;
    @include align-items(center);

    @include breakpoint(medium) {
      @include margin-padding(top, 3rem, null, null);
    }

    @include breakpoint(x-small) {
      display: block;
    }

    .benefit-icon {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      border-radius: $circular-radius;
      height: 8.9rem;
      width: 8.9rem;
      flex: 0 0 8.9rem;
      background-color: color("grey", "lighten-8");
      @include margin-padding(right, 1rem, null, null);
    }

    .heading {
      @include flexbox;
      @include align-items(center);
      @include margin-padding(bottom, 0.5rem, null, null);

      @include breakpoint(x-small) {
        @include margin-padding(top, 1rem, null, null);
      }

      .material-icons {
        @include margin-padding(right, 0.8rem, null, null);
      }
    }

    .features {
      @include margin-padding(all, 0, all, 0);

      li {
        @include flexbox;
        @include align-items(center);

        .material-icons {
          color: #31b14d;
          @include margin-padding(right, 0.8rem, null, null);
        }

        p {
          @include margin-padding(all, 0, null, null);

          span {
            font-weight: 500;
            color: color("grey", "lighten-1");
          }
        }
      }
    }
  }

  .invite-form {
    border-top: 1px solid color("grey", "lighten-7");
    @include margin-padding(top, 2.4rem, top, 2.4rem);
    // overflow-x: scroll;

    &.stats {
      @include breakpoint(medium) {
        h3 {
          @include margin-padding(top, 2rem, null, null);
        }
      }
    }
  }

  .invite-inner {
    @include flexbox;
    @include align-items(center);

    .mat-form-field {
      flex: 0 0 calc(100% - 20rem);

      input {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .mat-form-field-infix {
        border: none;
      }

      .mat-form-field-empty {
        display: none;
      }

      .mat-form-field-hide-placeholder .mat-select-placeholder {
        color: #787878;
        -webkit-text-fill-color: #787878;
      }
    }

    .button {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      @include margin-padding(all, 0.2rem 0 0 -0.2rem, null, null);
    }
  }

  .stats {
    .stats-inner {
      @include flexbox;
      @include align-items(center);
      @include margin-padding(bottom, 1.5rem, null, null);

      .material-icons {
        color: color("grey", "lighten-4");
        background-color: color("grey", "lighten-8");
        height: 5rem;
        width: 5rem;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        border-radius: $circular-radius;
        @include margin-padding(right, 1rem, null, null);
      }

      p {
        @include margin-padding(all, 0, null, null);

        &:last-child {
          font-size: 1.8rem;
          font-weight: 500;
          color: color("grey", "lighten-1");
          @include margin-padding(top, 0.2rem, null, null);
        }
      }
    }
  }

  .referrals-table {
    h3 {
      @include margin-padding(bottom, 1rem, null, null);
    }
  }

  .mat-paginator .button {
    @include margin-padding(all, 0, null, null);
  }
}

/* ==== Referrals page css ends ==== */

/* ==== Plan expiry reminder css starts ==== */
.plan-expiry-reminder {
  background-color: color("accent", "base");
  @include flexbox;
  @include align-items(center);
  @include justify-content(flex-start);
  min-height: 3.8rem;
  height: auto;
  width: calc(100% - 22rem);
  @include margin-padding(null, null, all, 0 4rem);
  float: right;

  @include breakpoint(xxxxxlarge) {
    width: calc(100% - 22rem);
  }

  @include breakpoint(x2large) {
    width: 100%;
  }

  p {
    .material-icons {
      @include margin-padding(right, 0.8rem, null, null);
      color: color("shades", "white");
    }

    @include flexbox;
    @include align-items(center);
    padding: 0;
    color: color("shades", "white");

    a {
      color: color("shades", "white");
      text-decoration: underline;
    }
  }

  .mat-button.mat-button-base {
    height: 3rem;
    color: color("shades", "white");
    border: 0.1rem solid color("shades", "white");
    line-height: 1.5;
    background-color: color("shades", "transparent") !important;
    @include abs-position(0.4rem, 5.5rem, null, null);
  }
}

/* ==== Plan expiry reminder css ends ==== */

/* ==== Into js css starts ==== */

body {
  .introjs-tooltiptext {
    font-size: 1.4rem;
  }

  .introjs-skipbutton {
    @include margin-padding(null, null, right, 0);
  }

  .introjs-nextbutton {
    box-shadow: none !important;
  }
}

/* ==== Into js css ends ==== */

.v-height-same {
  .mat-horizontal-content-container {
    min-height: 354px;

    // .modal-footer-btn{
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    //   left: 0;
    //   padding: 3.5rem 4rem 4rem;
    // }
    app-live-stream-configuration {
      min-height: 191px;
      display: block;
    }
  }
}

.row-disable {
  .social-details-outer {
    pointer-events: none;
    opacity: 0.3;
  }

  .add-more {
    pointer-events: none;
    opacity: 0.3;
  }
}

#resizedDiv {
  &:hover {
    .resize-show {
      opacity: 1;
    }
  }
}

.resize-show {
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 22px;
  height: 22px;
  background: color("shades", "black");
  z-index: 1;
  color: color("shades", "white");
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  opacity: 0.5;

  // pointer-events: none;
  &:hover {
    opacity: 1;
  }

  span {
    color: color("shades", "white");
    font-size: 16px;
    transform: rotate(84deg);
  }
}

.with-yes-no {
  margin-right: 0 !important;
  transform: scale(1.1);

  &.mat-checked {
    .mat-slide-toggle-bar {
      &:after {
        color: color("accent", "base");
        opacity: 1;
      }

      &:before {
        color: color("shades", "white");
      }
    }
  }

  .mat-slide-toggle-bar {
    &:after {
      position: absolute;
      right: 4.5px !important;
      font-size: 0.8rem;
      content: "yes" !important;
      pointer-events: none;
      top: -1px;
      z-index: 2;
      color: color("shades", "black");
      opacity: 0.6;
    }

    &:before {
      position: absolute;
      left: 7px;
      font-size: 0.8rem;
      content: "No";
      z-index: 2;
      pointer-events: none;
      top: -1px;
      color: color("shades", "black");
    }
  }
}

.content-list {
  display: block;

  &.style-2 {
    margin: 2.4rem auto;
    max-width: 100%;
    background: #fff6f6;
    padding: 2rem;
  }

  .heading {
    @include flexbox;
    @include align-items(center);
    @include margin-padding(bottom, 0.5rem, null, null);

    @include breakpoint(x-small) {
      @include margin-padding(top, 1rem, null, null);
    }

    .material-icons {
      @include margin-padding(right, 0.8rem, null, null);
    }
  }

  .features {
    @include margin-padding(all, 0, all, 0);

    li {
      @include flexbox;
      @include align-items(center);

      .material-icons {
        color: #31b14d;
        @include margin-padding(right, 0.8rem, null, null);
      }

      p {
        @include margin-padding(all, 0, null, null);

        span {
          font-weight: 500;
          color: color("grey", "lighten-1");
        }
      }
    }
  }
}

.get-started-img-area {
  text-align: center;
  margin: 1rem 0;
}

.get-started-info {
  margin: 1rem auto;
  text-align: center;
  max-width: 80%;

  .btn-area {
    margin: 2rem 0 0;
  }
}

.get-dialog-container {
  min-height: 600px;
  width: 950px;

  .mat-dialog-container {
    // @include flexbox;
    // @include align-items(center);
    overflow: auto;
    width: 100%;
    max-width: 100%;
    position: relative;
  }
}

.get-img-with-info {
  @include flexbox;
  @include align-items(center);

  @include breakpoint(small) {
    flex-direction: column;
  }

  .get-started-img-area {
    max-width: 319px;
    min-width: 319px;

    @include breakpoint(medium) {
      max-width: 219px;
      min-width: 219px;
    }

    @include breakpoint(small) {
      max-width: 300px;
      min-width: 300px;
    }
  }

  .get-started-info {
    text-align: left;
    max-width: 100%;
    padding: 0 0rem 0 5rem;

    @include breakpoint(small) {
      text-align: center;
      padding: 0 1rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: color("primary", "base");

      @include breakpoint(medium) {
        font-size: 1.6rem;
        line-height: 2.6rem;
      }
    }
  }
}

.three-dots {
  position: relative;
  @include flexbox;
  @include align-items(center);

  span {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin: 0 1rem 0 0;
    background: color("grey", "lighten-7");
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: color("accent", "base");
    }

    &.active {
      background: color("accent", "base");
    }
  }
}

.dots-with-btn {
  position: relative;
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  width: 100%;
  margin: 3rem 0 1rem 0;

  .btn-area {
    margin: 0;

    .button {
      margin: 0;
    }
  }
}

.double-btn {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  width: 100%;
  margin: 2rem 0 1rem 0;

  @media (max-width:#{$small-screen}) {
    flex-direction: column;
  }

  &.btn-center {
    @include justify-content(center);
  }

  .left-area {
    @media (max-width:#{$small-screen}) {
      width: 100%;
    }
  }

  .button {
    min-width: 15rem;

    @media (max-width:#{$small-screen}) {
      width: 100%;
      margin: 1rem 0;
    }
  }
}

.alert-cancel-msg {
  @include flexbox;
  background: rgba(241, 122, 126, 0.15);
  border-radius: 10px;
  padding: 3rem;
  margin: 1.5rem 0;

  @media (max-width:#{$small-screen}) {
    padding: 2rem 1rem;
  }

  .icon-area {
    width: 30px;
    height: 30px;
    margin: 0 1rem 0 0;

    span {
      color: color("accent", "base");
      font-size: 2.8rem;
    }
  }

  p {
    color: color("accent", "base");
    margin: 0;
    font-weight: 500;
    font-size: 1.8rem !important;
    line-height: 2.8rem !important;

    @media (max-width:#{$small-screen}) {
      font-size: 1.6rem !important;
      line-height: 2.6rem !important;
    }
  }
}

.get-start-inner {
  width: 100%;
  padding: 1rem 2rem;

  .popup-heading {
    margin: 0 0 1rem 0;
  }
}

.cancel-form {
  position: relative;
  margin: 3rem 0;

  label {
    font-weight: 500;
    font-size: 2rem;
    // line-height: 2.4rem;
  }

  .row .col {
    width: 100%;
  }
}

body .full-dialog-container {
  position: fixed !important;
  width: 100%;
  top: 0;
  min-height: 100vh;
  border-radius: 0;
  background: color("shades", "white");

  header.logo.mat-dialog-container {
    background: color("shades", "white");
    box-shadow: 0px 4px 8px 5px rgba(197, 197, 197, 0.15);
    position: sticky;
    border-radius: 0;

    .logo-image {
      margin: 0;
    }

    .mat-button.close {
      font-size: 2rem;

      @media (max-width:#{$small-screen}) {
        font-size: 1.8rem;
      }
    }

    .container {
      max-width: 100%;
      padding: 0 3rem;

      @media (max-width:#{$small-screen}) {
        padding: 0 15px;
      }
    }
  }

  .mat-dialog-container {
    padding: 0 !important;
    overflow: auto;
    margin: 0 !important;
  }
}

body .join-session-section {
  @media (max-width:#{$small-screen}) {
    padding: 0 15px;
    padding-bottom: 30px;
  }

  .section-title {
    text-align: center;
    max-width: 70rem;
    margin: 5rem auto;

    @media (max-width:#{$large-desktop-up}) {
      margin: 3rem auto;
    }
  }

  .row {
    .col {
      padding: 0 2rem;
    }
  }

  .btn-area {
    margin: 4rem 0 2rem 0;

    @media (max-width:#{$large-desktop-up}) {
      margin: 0 0 2rem 0;
    }

    @media (max-width:#{$small-screen}) {
      margin: 2rem 0;
    }

    .button {
      width: 100%;
      max-width: 36rem;
    }
  }
}

.img-list-info {
  text-align: center;
  margin: 2rem 0;

  .img-area {
    width: 100%;
    margin: 0 0 2rem 0;

    img {
      @media (max-width:#{$large-desktop-up}) {
        max-width: 200px;
      }
    }
  }
}

body .mat-dialog-container {
  .img-list-info {
    h3 {
      @media (max-width:#{$small-screen}) {
        font-size: 1.7rem;
        line-height: 2.2rem;
      }
    }

    p {
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: color("primary", "base");

      @media (max-width:#{$small-screen}) {
        font-size: 1.5rem;
        line-height: 2.2rem;
      }

      @media (max-width:#{$large-desktop-up}) {
        font-size: 1.5rem;
        line-height: 2.2rem;
      }
    }
  }
}

.thankyou-msg-inner {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  height: 50rem;

  .mat-dialog-content {
    height: auto;

    .thankyou-info {
      max-width: 76%;
      margin: auto;
    }
  }
}

.thankyou-msg {
  text-align: center;

  .icon-area {
    width: 8rem;
    height: 8rem;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    border-radius: 100%;
    margin: 2rem auto 3rem;
    background: #79e07d;

    span {
      color: color("shades", "white");
      font-size: 5rem;
    }
  }

  .thankyou-info {
    h1 {
      margin: 2rem 0;
    }
  }
}

.mat-step-header .mat-step-label.mat-step-label-active {
  @media (max-width:#{$small-screen}) {
    white-space: initial;
  }
}

body .create-session-popup {
  .mat-dialog-container .mat-dialog-title {
    @media (max-width:#{$small-screen}) {
      margin: 2rem 0 0;
      font-size: 2rem;
    }
  }

  .mat-dialog-content {
    @media (max-width:#{$small-screen}) {
      padding: 0 14px;
    }
  }
}

div.share-recording {
  width: 100% !important;
  max-width: 80rem !important;

  .audio-listing {
    min-height: 25rem;
  }

  .row {
    margin: 0;
  }

  .info-heading {
    font-size: 1.4rem;
    white-space: nowrap;
    min-width: 10rem;
    margin: 0 1rem 0 0;
    width: 25%;

    .audio-player-content {
      display: block;
      max-width: 216px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    margin: 0 1rem 0 0;
  }

  .data {
    @include flexbox;
    @include align-items(center);

    .col {
      padding: 0;
    }

    .checkbox {
      margin: 0 1rem 0 0;
    }

    .audio-inner {
      margin-left: auto;
      width: 100%;
    }
  }

  .mat-dialog-container p {
    font-size: 1.4rem;
  }
}

// .manage-session-equal{
//   min-height: 390px;
//   @include flexbox;
//   flex-direction: column;
//   @include justify-content(space-between);
// }

body .payment-history-popup {
  width: auto !important;
  max-width: 1200px !important;
  min-width: 800px;

  @media (max-width:#{$medium-screen}) {
    width: 100% !important;
    min-width: initial;
  }

  .mat-dialog-container {
    padding: 2rem 3rem 3em;
  }
}

.dont-warn {
  // @include abs-position(auto, auto, 2.5rem, 0rem);
  margin: 2rem 0 0 0;
  width: 100%;
}

.progress-bg-bar {
  @include abs-position(0, 0, 0rem, 0rem);
  width: 0;
  height: 100%;
  background: rgba(color("accent", "base"), 0.5);
}

body .unable-to-access {
  .mat-dialog-container {
    overflow: auto;
  }
}

.setting-area {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin: 20px auto;
  overflow: hidden;

  img {
    max-width: 100%;
  }
}

.share-recording-align {
  @include flexbox;

  .audio-player {
    width: 75%;
    margin-left: auto;
  }
}

.delete-button-icon-align {
  width: 100% !important;
  @include flexbox;
  @include justify-content(flex-end);
  // margin-top: -7.2%;
  position: absolute;


}

.ng-touched .delete-button-icon-align {
  transform: translate(-26px, -167%);

}

.ng-untouched .delete-button-icon-align {
  transform: translate(-26px, -143%);

}

.send-recording-input-align {
  width: 88% !important;

  @media (max-width:#{$small-screen}) {
    width: 80% !important;
  }
}

// updated dashboard

.session-expand-area .mat-expansion-panel .mat-expansion-panel-header {
  top: 2.2rem;
  right: 4rem;
}

.session-container span.more {
  margin: -0.4rem 0 0 0;
  position: absolute;
  right: -7.5rem;
}

.session-main-area {
  width: 100%;
  align-items: center;

  .left-date-month {
    width: 7%;
    min-width: 7rem;
  }

  .middle-session-detail {
    width: 70%;
    padding-top: 1rem;
    margin-left: 1.5rem;

    .session-name {
      font-size: 2rem;
      font-weight: 500;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      color: color("grey", "lighten-1");
      margin: 0 0 1rem 0;
    }

    .time p {
      font-size: 1.3rem;
    }

    .session-title {
      margin-left: 0 !important;
    }

    .session-full-details {
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      margin-left: 0 !important;
      margin-top: -0.5rem;
      width: 100%;

      .time-record-wrap {
        @include flexbox;
        width: 50%;

        .time {
          width: 50%;
          display: flex;
          align-items: center;
        }

        .recordings {
          width: 50%;
          display: flex;
          align-items: center;
        }
      }

      .guest-invite-wrap {
        @include flexbox;
        width: 50%;

        .guest-details {
          width: 50%;


        }

        .invite {
          width: 50%;
        }
      }

      .recordings {
        margin: 0.5rem 0;

        .radio-text {
          @include flexbox;
          @include align-items(center);
          margin-bottom: 0;
          margin-top: 0.5rem;

          b {
            margin-left: 0.7rem;
          }
        }
      }
    }
  }
}

.right-enter-room {
  text-align: right;
  margin-right: 1.5rem;
  width: 20%;
}

.session-container .grey-circle {
  height: 0.7rem;
  width: 0.7rem;
  min-width: 0.7rem;
  background-color: #e5e5e5;
  display: inline-block;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e8e8e8;
  margin-right: 1.5rem;
}


//media query of updated dashboard lisiting

@media (max-width:#{$medium-screen}) {
  .session-main-area {
    @include align-items(flex-start);

    .left-date-month {
      width: 9% !important;
    }

    .session-title .session-name {
      margin-top: 0;
    }

    .right-enter-room {
      margin-right: 1.5rem;
      margin-top: 3.6rem;
    }
  }
}

@media only screen and (max-width: 890px) {
  .session-container span.more {
    right: -6.5rem !important;
    top: -0.4rem !important;
  }

  .dropdown-arrow .mat-expansion-panel-header {
    right: 6rem !important;
  }

  .left-date-month {
    width: 10%;
  }

  .session-main-area .middle-session-detail .session-full-details {
    display: block;
  }

  .date {
    width: 100%;
  }

  .time-record-wrap {
    width: 100% !important;

    .time {
      width: 50% !important;
    }

    .recordings {
      width: 50% !important;
    }
  }

  .guest-invite-wrap {
    // flex-direction: row-reverse;
    width: 100% !important;

    .guest-details {
      width: 50% !important;
    }

    .invite {
      width: 50% !important;
    }
  }

  .right-enter-room {
    margin-right: 1.5rem;
    margin-top: 7rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .session-container span.more {
    right: -1.5rem !important;
    top: -0.3rem !important;
  }

  .middle-session-detail {
    margin-top: 0 !important;
  }

  .right-enter-room {
    width: 100%;
    text-align: left !important;
    margin-top: 13.5rem !important;
    @include flexbox;
    @include justify-content(flex-end);
  }
}

@media only screen and (max-width: 555px) {
  .session-main-area {
    flex-direction: column;

    .left-date-month {
      // margin-left: 2rem;
      min-width: 6rem !important;
    }

    .middle-session-detail {
      margin-top: 1rem;
      width: 100% !important;

      .session-full-details .recordings .radio-text {
        margin-top: 0;
      }

      .recordings {
        @include flexbox;
        @include justify-content(flex-start);
        // padding-left: 3rem;
      }

      .guest-invite-wrap {
        flex-direction: row;

        .invite {
          @include flexbox;
          @include justify-content(flex-start);
          padding-left: 3rem;
        }
      }

      .recordings {
        margin: 1.5rem 0 !important;
      }
    }
  }

  .right-enter-room {
    margin-top: 1.5rem !important;
    @include flexbox;
    @include justify-content(flex-start);
  }
}

@media only screen and (max-width: 601px) {
  .session-main-area {
    flex-direction: column;

    .left-date-month {
      margin-left: 2rem;
      min-width: 6rem !important;
    }

    .middle-session-detail {
      margin-top: 1rem;
      width: 100% !important;

      .session-full-details .recordings .radio-text {
        margin-top: 0;
      }

      .recordings {
        @include flexbox;
        @include justify-content(flex-start);
        padding-left: 0;
      }

      .guest-invite-wrap {
        flex-direction: row;

        .invite {
          @include flexbox;
          @include justify-content(flex-start);
          padding-left: 0;
        }
      }

      .recordings {
        margin: 1.5rem 0 !important;
      }
    }
  }

  .right-enter-room {
    margin-top: 1.5rem !important;
    @include flexbox;
    @include justify-content(flex-start);
  }
}

@media only screen and (max-width: 324px) {
  .session-main-area {
    .middle-session-detail {
      .time-record-wrap {
        flex-direction: column;

        .time {
          width: 100% !important;
        }

        .recordings {
          padding-left: 0;
          margin: 1rem 0 !important;
          width: 100% !important;
        }
      }

      .guest-invite-wrap {
        flex-direction: column;

        .guest-details {
          width: 100% !important;
          margin: 1rem 0 !important;
        }

        .invite {
          padding-left: 0 !important;
          margin: 1rem 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (max-width:601px) {
  .menu-collapse-area.open .menu-lines {
    display: none !important;
  }

  .sidebar .ng-scroll-content .side-nav-block .user-menu-wrap {
    margin-top: 0 !important;
  }
}

//loader

.loader .mat-dialog-container .open {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  padding-left: 13% !important;
}

.loader {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner>div {
  background-color: color("accent", "base");
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 0 0.2rem;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

// .green-room.user2-1 .conference[conference-member="2"] .conference-member.host {
//   top: 3.5rem !important;
// }

.create-button {
  font-size: 1.4rem;
  padding: 1rem 2rem !important;

  .add-icon {
    font-size: 2rem;
  }
}

.create-button {
  font-size: 1.4rem !important;
  height: 4.5rem;
  padding: 0 2rem !important;
}

//tooltip

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: transparent;
  color: color("grey", "lighten-8");
  text-align: center;
  border-radius: 0;
  padding: 3px 0;
  top: 43px;
  left: -18px;
  z-index: 9;
  border-radius: 5px;
  font-size: 12px;
  padding: 6px;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -4px;
    top: 13px;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: inherit;
  }

  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.sidebar .mat-drawer-opened .tooltip .tooltiptext {
  display: none;
}

.sidebar .close.tooltip .tooltiptext {
  display: block;
}

mat-progress-bar.mat-progress-bar.progress-bar-height.mat-primary {
  height: 8px;
  margin-top: 1rem;
}

// p.top.last-payment-date {
//   margin: 2.7rem -1.5rem 0 -1.5rem;
// }

b.top.last-payment-date.ng-star-inserted {
  margin: 1rem -0.5rem 0 0.5rem;
}

.payment-info .card-detail.no-record {
  margin-bottom: 0;
  padding: 0.8rem 0 5rem 0;
}

.plan-details.container-box.container-box-padding.no-shadow.payment-info.bottom-padding {
  padding-bottom: 6rem;
}

h2.no-margin.local-recording {
  margin: 2rem 0 !important;
}

// scroll custom

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 3px grey;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: color("grey", "lighten-5");
  border-radius: 12px;
}

//no record banner

.no-record-banner {
  background-color: color("accent", "base");
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #ffffff;
    margin-right: 1rem;
  }

  p {
    margin: 0;
    color: #ffffff;
  }
}

// calender svg alignment

.svg-align span.mat-button-wrapper svg {
  margin: 1rem auto !important;
}

// dashboard listing dropdown responsive

.dashboard .data {
  margin: 2px !important;
}

.conference-member {
  border-radius: 10px;
}

button.mat-focus-indicator.add-more.orange-text.pl-0.add-email.mat-button.mat-button-base {
  padding: 0;
}

//send recording
.email-label-top-align .mat-form-field-label {
  top: 3rem !important;
}

@media only screen and (max-width: 527px) {
  .nowrap.no-recording {
    font-size: 14px;
  }
}

@media only screen and (max-width: 406px) {
  .nowrap.no-recording {
    font-size: 12px;
  }
}

@media only screen and (max-width: 347px) {
  .nowrap.no-recording {
    font-size: 11.5px;
  }
}

span.material-icons.side-menu-icon {
  margin-right: 0.5rem !important;
}

@media only screen and (max-width: 402px) {
  a.plan-info.ng-star-inserted {
    margin-top: 1rem;
  }
}

.sidebar.close {
  span.material-icons.side-menu-icon {
    margin-left: 0.5rem !important;
  }
}

.mat-select-panel {
  max-width: 100px !important;
}

//greenroom

// .green-room.user2-2 .conference[conference-member="2"] {
//   p.inactive-speaker.with-rise-hand {
//     max-width: 10rem;
//   }
// }

// greenroom blocks

.green-room .logo-image.top-left {
  top: 8px !important;
}

.green-room .logo-image.top-right {
  top: 8px !important;
}


.top-banner .logo-image.top-left {
  top: 0px !important;
}

.top-banner .logo-image.top-right {
  top: 0px !important;
}


// greenroom blocks

.green-room .logo-image.top-left {
  top: 8px !important;
}

.green-room .guest-name {
  position: absolute !important;
  display: flex;
  cursor: pointer;
  z-index: 99;
  width: 100%;
  top: 0;
  left: 0;
}

.green-room .guest-name p {
  color: #ffffff;
  margin: 0 1rem 0 0;
  padding: 3px 6px;
  border-radius: 8px;
  font-size: 13px;
  height: 3.1rem;
}

.inactive-speaker[_ngcontent-etf-c328] {
  border: none !important;
}

.name {
  display: flex;
  justify-content: flex-start;
  min-width: 60%;
  margin: 10px;
}

.mic-option-wrap {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  margin: 10px;

  .more-options {
    visibility: hidden;
    transition: all 0.3s ease-out;
  }
}

.conference-member {
  &:hover .more-options {
    visibility: visible;
    opacity: 1;
  }
}

.guest-name {
  .options {
    @include abs-position(auto, auto, -13.3rem, auto);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  .more-options {
    &:hover .options {
      visibility: visible;
      opacity: 1;
    }
  }
}

// minimize screen top margin

.green-room.host-green-room.user3-1 .conference[conference-member="3"] .conference-member.host {
  top: 10px;
}



.green-room.host-green-room.user4-1 .conference[conference-member="4"] .conference-member.host {
  top: 10px;
}



// minimize screen top margin with banner

.green-room.top-banner.user3-1 .conference[conference-member="3"] .conference-member.host {
  top: 10px;
}







// .green-room.user4-1 .conference[conference-member="4"] .conference-member.host .second_user_name_no_cam {
//   height: 7rem !important;
//   width: 7rem !important;
//   font-size: 2.5rem !important;
//   line-height: 5rem !important;
// }



.green-room.user3-1 .conference[conference-member="3"] .conference-member {
  .mic-option-wrap {
    margin-right: 70px;
  }
}

.green-room.user5-3 .conference[conference-member="5"] .conference-member {
  height: 49.5%;
}

.green-room.user6-3 .conference[conference-member="6"] .conference-member {
  height: 49.5%;
}

.conference-member .host {
  z-index: 999 !important;
}


//more-icon toggle

.green-room .guest-name .mat-list-item-content {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.guest-name .options {
  bottom: -10.5rem !important;
}


.green-room.user4-3 .conference[conference-member="4"] .conference-member {

  .green-room .guest-name .mat-list-item-content {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }

  .guest-name .options {
    bottom: -10rem !important;
  }
}

//preview button hidden on mobile
@media only screen and (max-width: 601px) {
  button.mat-focus-indicator.button.button-medium.filled.preview-button.mat-flat-button.mat-button-base {
    display: none;
  }
}

//enter room button
.enter-room-icon-text {
  display: flex;
  align-items: center;

  .button-text {
    margin-left: 1px;
  }
}

//share recording popup

div.share-recording .audio-listing {
  overflow-y: scroll !important;
  height: 50vh !important;
  padding: 0 1rem;
}

.send-recording-email-block {
  overflow-y: scroll !important;
  height: 50vh !important;
  padding: 0 1rem;
}

.sidebar.close {
  .main-logo-image {
    display: none !important;
  }

  .menu-collapse-area {
    margin: 2rem 0 7rem !important;
  }
}

//side nav

.sidebar .ng-scroll-content {
  padding: 3rem 2rem !important;

  .side-nav-block {
    display: flex;
    flex-direction: column;

    .logo-arrow-wrap {
      margin: 0 !important;
    }

    .user-menu-wrap {
      margin-top: 5rem;

      // .icon-menu-text-wrap{
      //   display: flex;
      //   align-items: center;
      // }
    }

    .logout-icon-bottom {
      position: absolute;
      bottom: 0 !important;
    }
  }
}


//avatar

.avatar-first {
  z-index: 10;
}

.avatar-second {
  margin-left: -9px !important;
  z-index: 9;
}

.avatar-third {
  margin-left: -9px !important;
  z-index: 8;

}

.avatar-last {
  margin-left: -9px !important;
  color: #F2B192 !important;
  z-index: 7;
}


.avatar-0 {
  z-index: 10;
}

.avatar-1 {
  margin-left: -9px !important;
  z-index: 9;
}

.avatar-2 {
  margin-left: -9px !important;
  z-index: 8;

}




// arrow animation


.mouse_scroll {
  // display: block;
  // margin: 0 auto;
  // width: 24px;
  height: 7px;
  margin-left: 0.5rem;
  // margin-top: 125px;
}


.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(225deg);
  /* IE 9 */
  -webkit-transform: rotate(225deg);
  /* Chrome, Safari, Opera */
  transform: rotate(225deg);
  border-right: 2px solid color("accent", "base");
  border-bottom: 2px solid color("accent", "base");
  margin: 0 0 3px 4px;
  width: 8px;
  height: 8px;
}


// .unu
// {
//   margin-top: 1px;
// }

.unu,
.doi,
.trei {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;

}

.unu {
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;

  animation-direction: alternate;
  animation-delay: alternate;
}

.doi {
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;

  animation-delay: .2s;
  animation-direction: alternate;

  margin-top: -6px;
}

.trei {
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;

  animation-delay: .3s;
  animation-direction: alternate;


  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid rgb(245, 167, 167);
  top: 170px;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: rgb(245, 167, 167);
  position: relative;

  height: 4px;
  width: 4px;
  border: 2px solidrgb(245, 167, 167);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}

@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }

  25% {
    top: 2px;
  }

  50% {
    top: 3px;
  }

  75% {
    top: 2px;
  }

  100% {
    top: 1px;
  }
}

@-o-keyframes mouse-wheel {

  0% {
    top: 1px;
  }

  25% {
    top: 2px;
  }

  50% {
    top: 3px;
  }

  75% {
    top: 2px;
  }

  100% {
    top: 1px;
  }
}

@keyframes mouse-wheel {

  0% {
    top: 1px;
  }

  25% {
    top: 2px;
  }

  50% {
    top: 3px;
  }

  75% {
    top: 2px;
  }

  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {

  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes mouse-scroll {

  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes mouse-scroll {

  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes mouse-scroll {

  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.recording-icon {
  font-size: 23px !important;
}


.conference-member video {
  border-radius: 10px !important;
  border: 4px solid transparent;
}

// 

h2.wait-guest.text-center.mt-2.mb-0 {
  margin-top: 0 !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
}

//  .green-room .logo-image.top-right.ng-star-inserted {
//   margin-right: 91px !important;
// }

.plan-expiry-reminder p {
  display: block !important;
}


.banner-content {
  display: flex;
  align-items: center;

  .banner-icon {
    color: color('shades', 'white');
    margin-right: 1rem;
  }
}

@media only screen and (max-width:1586px) {
  .banner-content {
    align-items: flex-start;

  }
}

@media only screen and (max-width:992px) {
  .plan-expiry-reminder p {
    margin: 0 !important;
  }
}

//customize section - banner
.banner-style-main-block {
  @include flexbox;
  flex-direction: column;

  .banner-style-block {
    width: 100%;
    @include flexbox;
    flex-direction: row;
    margin-bottom: 1.5rem;

    .banner-style-inner-block {
      width: 45%;
      min-width: 40%;
      padding: 1.5rem 1.2rem;
      border: 0.1rem solid color("grey", "lighten-7");
      box-sizing: border-box;
      border-radius: 1rem;
      margin: auto;
    }

    .banner-style-active {
      border: 0.1rem solid color("accent", "base") !important;
    }
  }
}

.default-block {
  background-color: color("accent", "base");
  text-align: center;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  cursor: pointer;
  border-radius: 8px;
  padding: 0.7rem 1rem;
  position: relative;
  border-bottom-right-radius: 12px;

  p {
    padding-left: 2rem;
  }

  .banner-after {
    content: '';
    position: absolute;
    top: 0;
    right: -40px;
    height: 100%;
    transform: skew(-35deg);
    border-radius: 10px;
    width: 124px;
  }

}



.minimul-block {
  background-color: color("accent", "base");
  border-left: 0.5rem solid #e26266;
  // padding: 0.7rem 1rem;
  text-align: center;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  border-radius: 0.5rem;
  cursor: pointer;

  .banner-after {

    display: none;
  }
}

.bubble-block {
  background-color: color("accent", "base");
  border-radius: 5rem;
  padding: 0.7rem 1rem;
  text-align: center;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  cursor: pointer;

  .banner-after {

    display: none;
  }
}

.block {
  background-color: color("accent", "base");
  padding: 0.7rem 1rem;
  text-align: center;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  border-radius: 0.5rem;
  cursor: pointer;

  .banner-after {
    display: none;
  }
}

p.banner-quote {
  font-size: 1.4rem !important;
  font-weight: 500;
  margin: auto;
  color: color("shades", "white");

  @media only screen and (max-width: 1440px) {
    font-size: 1.3rem !important;
  }
}

//conference-room-view
.conference {
  .banner-secondary-logo-wrap {
    position: absolute;
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(flex-end);
    flex-direction: row;
    width: 100%;
    bottom: 0;
    padding: 1rem 3rem 1rem 0;

    .side-content-banner {
      width: 70%;

      //banner-layout-classes
      .default-block {
        min-width: 50%;
        max-width: fit-content;
        // padding: 1.5rem 4rem 1.5rem 1rem;
        padding-right: 4rem;
        justify-content: flex-start;
      }

      .minimul-block {
        min-width: 50%;
        max-width: fit-content;
        // padding: 1.5rem;
        border-left: 1rem solid #e26266;
        justify-content: flex-start;
      }

      .bubble-block {
        min-width: 50%;
        max-width: fit-content;
        // padding: 1.5rem;
        justify-content: flex-start;
      }

      .block {
        min-width: 50%;
        max-width: fit-content;
        // padding: 1.5rem;
        justify-content: flex-start;
      }

      p.banner-quote {
        font-size: 2.4rem;
      }
    }
  }
}

//primary logo postion classes
.primary-logo-sec {
  position: absolute;
  right: 1rem;
}

.top-left {
  top: 0;
  left: 1rem;
}

.top-right {
  top: 0;
  right: 1rem;
}

.bottom-left {
  left: 1rem;
  bottom: 0 !important;
}

.bottom-right {
  right: 1rem;
  bottom: 0 !important;
}

.tagline.left {

  display: none !important;
}

//banner and secondary logo hide
.hide-banner {
  display: none;
}

.hide-secondary-banner {
  display: none;
}

.media-custom {
  margin-top: 1rem !important;
}


//  name-block

.name {
  position: absolute;
  bottom: 0;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  flex-direction: column;
  width: 100%;

  p {
    color: color("shades", "white");
    ///margin: 1rem !important;
    border-radius: 8px;
    font-size: 18px;
    background-color: rgb(17 17 17 / 75%);
    margin: 0 1rem 0 0;
    padding: 0.8rem 1rem;
    max-width: 90%;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
  }
}


.inactive-speaker[_ngcontent-etf-c328] {
  border: none !important;
}


.video-container {
  width: 70% !important;
  margin: auto !important;
  float: none;
  height: 90% !important;
}

@media screen and (min-width: 1900px) {

  // .name p{
  //   font-size: 24px;
  // }
  // body .second_user_name_no_cam{
  //   font-size: 8rem !important;
  //   width: 20rem;
  //   height: 20rem;
  // }
}

.live-room-chat {
  width: 335px;

  //bottom: 10%;
  .message {
    width: 100% !important;
    background: transparent !important;
    position: relative;
    //padding-left: 50px !important;
  }

  .live-room-profile {
    position: absolute !important;
    left: 0px;
  }

  .message-text {

    background: #fff;
    color: #000000;
    margin: 0px !important;
    padding: 12px 10px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .profile-name-wrap {
    width: 100% !important;
    justify-content: space-between;
    padding-left: 0px !important;
    position: initial;

    .live-room-name {
      background: #F17A7E;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      span {
        margin: 0px;
        padding-right: 10px;
      }
    }
  }
}

#chat.open {
  overflow-y: auto;
  overflow-x: hidden;
}

.live-room-close-pop {
  display: flex;
  align-items: center;

  em {
    cursor: pointer;
  }

  span {
    color: #fff;
  }
}

.profile-name-wrap {

  width: 100% !important;
  background: transparent !important;
  position: relative;
  padding-left: 50px !important;
}




#main.aspect-ratio-area {
  #conference.conference {
    // aspect-ratio: 16/9;
    margin: auto;
    // border: 2px solid #333 !important;
    width: 100%;
    height: 100%;
    max-height: 100% !important;
    left: 0;
  }
}



#main.aspect-ratio-area {
  padding-top: 0px;
  background: #5c5c5c;

  &.waiting-left-conference {
    left: 26rem;

    .screen-fit-area {
      width: 100%;
      left: 0;
    }
  }

  &.chat-enabled {
    header.logo {
      max-width: calc(100% - 370px);
    }

    .actions {
      max-width: calc(100% - 370px);
    }
  }

  &.host-green-room {
    padding-top: 55px;
  }

  &.customization {
    header.logo {
      max-width: calc(100% - 370px);
    }

    .actions {
      max-width: calc(100% - 370px);
    }
  }

  &.live-room-page {
    padding-top: 0;

    &.top-banner {
      padding-top: 55px;
    }
  }

  .conference-outer {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    margin: 5px;
  }

  #conference.conference {
    margin: auto;
    height: 100%;
    left: 0;
    width: 100%;
    // border-radius: 10px;
  }

  header.logo {
    position: fixed;
    top: 0;
  }

  .bottom-action {
    position: fixed;
    bottom: 5px;
  }
}

.mat-typography .green-room .conference {
  align-items: center;
}

#primaryLogo {
  z-index: 999;
}

#secondaryLogo {
  z-index: 998;
}

.share-user-area {
  background: color("shades", "black");
  border-radius: 5px;
  padding: 15px 25px;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include abs-position(20px, auto, auto, 20px);
  z-index: 1111;

  .drag-area {
    margin-left: -20px;
    margin-right: 5px;
    cursor: move;
  }

  .share-user-area-list {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    column-gap: 15px;

    .user-info {
      background: #515151;
      border: 1px solid #303030;
      box-shadow: 0px 0px 18px rgba(43, 127, 255, 0.25);
      font-weight: 600;
      font-size: 19px;
      line-height: 22px;
      text-align: center;
      color: color("shades", "white");
      text-transform: uppercase;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      width: 54px;
      height: 54px;
      border-radius: 100%;
      overflow: hidden;

      .user-tile {
        width: initial;
        height: 100%;
        max-width: inherit;
      }
    }
  }
}

.conference-outer.share-outer-area {
  .conference {
    background: #272727 !important;
    padding: 0 !important;

    .conference-member {
      background: #272727 !important;
    }
  }
}

.conference.share-outer-area {
  background: #272727 !important;
  padding: 0 !important;

  .conference-member {
    background: #272727 !important;
  }
}

.share-outer-area .conference-member.video-container {
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
  overflow: hidden;

  .screen-share {
    max-height: 100%;
    height: auto !important;
    margin: auto;
    bottom: 0;
    border-radius: 0 !important;
  }


  .screen-share-studio {

    video {
      max-height: 100%;
      height: auto !important;
      margin: auto;
      bottom: 0;
      border-radius: 0 !important;
    }
  }

  &.share-video-container {
    display: flex;
    align-items: center;
  }
}

#main.aspect-ratio-area #conference.conference {
  background-size: cover;
}


.green-room {

  &.user1-3 {
    #conference.conference[conference-member="1"] {
      @include flexbox;
      @include align-items(center);
      border: 0.5rem solid transparent;
      overflow: hidden;
      justify-content: flex-start;

      .conference-member {
        height: 84%;
        width: 54%;
        float: left;
        border: none;
        @include margin-padding(all, auto 10px auto 0.25rem, null, null);
        display: none;

        &.host {
          border-left: none;
          @include margin-padding(all, auto 0.25rem auto 10px, null, null);
          display: flex;
        }

        .second_user_name_no_cam {
          width: 26.406% !important;
          height: 32.75% !important;
          font-size: 6vh !important;
        }

        video {
          object-fit: cover;
        }
      }
    }
  }
}

















.right-to-portrait {
  transform: rotate(180deg) scale(1.4);
}

.left-to-portrait {
  transform: rotate(90deg) scale(1.4);
}












.conference-member .tile-container {
  border-radius: 10px !important;
  height: 100% !important;
  overflow: hidden;
  video {
    width: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
  }

}





.media-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 100% !important;
  background-color: #000;
}

.media-container-studio {
  z-index: 999;
  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 100% !important;
    background-color: #000;
  }
}


.user-tile-studio {
  video {
      width: 100%;
      height: 100%;
      max-width: inherit;
      transform: scale(1.8);
  }
}




