html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.loader {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0px;
  left: 0px;

  .mat-spinner {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    circle {
      stroke-width: 6% !important;
    }
  }
}



.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #F17A7E;
}

mat-spinner.mat-spinner.mat-progress-spinner.mat-primary.mat-progress-spinner-indeterminate-animation {
  left: 44% !important;
  top: 50% !important;
}

mat-drawer-container.mat-drawer-container.full-width-container.drawer-right {
  float: left;
  width: 75%;
  margin-left: 24%;
}

app-side-bar.drawer-left {
  float: left;
  width: 14%;
}
