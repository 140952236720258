.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  // font-family: $font-medium;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  font-size: $h1-fontsize;
  font-weight: 600;
  margin: 0 0 1.5rem;

  @include breakpoint(xxxxxlarge) {
    font-size: $h2-fontsize;
  }
}

.mat-h2,
.mat-title,
.mat-typography h2 {
  font-size: $h2-fontsize;
  font-weight: 600;
  margin: 0 0 16px;

  @include breakpoint(xxxxxlarge) {
    font-size: $h3-fontsize;
  }
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  font-size: $h3-fontsize;
  font-weight: 600;
  margin:0px 0 16px 0;
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
  font-size: $h4-fontsize;
  font-weight: 600;
  margin: 16px 0;
}

.mat-h5,
.mat-typography h5 {
  font-size: $h5-fontsize;
  font-weight: 600;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography h6 {
  font-size: $h6-fontsize;
  font-weight: 600;
  margin: 0 0 12px;
}

// .mat-body-strong,
// .mat-body-2 {
//   // font: 500 14px/24px $font-regular;
// }
// .mat-body,
// .mat-body-1,
// .mat-typography {
//   // font: 400 14px/20px $font-regular;
// }
// .mat-body p,
// .mat-body-1 p,
// .mat-typography p {
//   // margin: 0 0 12px;
// }
// .mat-small,
// .mat-caption {
//   // font: 400 12px/20px $font-regular;
// }
.mat-display-4,
.mat-typography .mat-display-4 {
  // font: 300 112px/112px $font-regular;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  // font: 400 56px/56px $font-regular;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  // font: 400 45px/48px $font-regular;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  // font: 400 34px/40px $font-regular;
  margin: 0 0 64px;
}

// .mat-bottom-sheet-container {
//   // font: 400 14px/20px $font-regular;
// }
// .mat-button,
// .mat-raised-button,
// .mat-icon-button,
// .mat-stroked-button,
// .mat-flat-button,
// .mat-fab,
// .mat-mini-fab {
//   // font-family: $font-regular;
//   // font-size: 16px;
//   // font-weight: 500;
// }
// .mat-button-toggle,
// .mat-card {
//   // font-family: $font-regular;
// }
.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

// .mat-checkbox {
//   // font-family: $font-regular;
// }
.mat-checkbox-layout .mat-checkbox-label,
.mat-radio-label {
  line-height: 24px;
  color: color('primary', 'base');
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: none;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;

  .mat-chip-trailing-icon.mat-icon,
  .mat-chip-remove.mat-icon {
    font-size: 18px;
  }
}

// .mat-table {
//   // font-family: $font-regular;
// }
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  text-align: left;
}

.mat-header-cell {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.mat-cell,
.mat-footer-cell {
  font-size: 14px;
}

// .mat-calendar {
//   // font-family: $font-regular;
// }
.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

// .mat-dialog-title {
//   // font: 500 20px/32px $font-regular;
// }

.mat-expansion-panel-header {
  // font-family: $font-regular;
  font-size: 15px;
  font-weight: 400;
}

// .mat-expansion-panel-content {
//   // font: 400 14px/20px $font-regular;
// }
.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  display: block !important;
  // font-family: $font-regular;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}

.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: .5em 0;
  border-top: .84375em solid transparent;
}

.mat-form-field-can-float {
  &.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.34375em) scale(0.75);
    width: 133.3333333333%;
  }

  .mat-input-server {
    &:focus+.mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.34375em) scale(0.75);
      width: 133.3333333333%;
    }

    &[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.34374em) scale(0.75);
      width: 133.3333433333%;
    }
  }
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: .84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  line-height: 1.6rem;
  position: static !important;
}

.mat-form-field-appearance-legacy {
  .mat-form-field-wrapper {
    padding-bottom: $no-padding;
  }

  .mat-form-field-infix {
    padding: .4375em 0;
  }

  &.mat-form-field-can-float {

    &.mat-form-field-should-float .mat-form-field-label,
    .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
      -ms-transform: translateY(-1.28125em) scale(0.75);
      width: 133.3333333333%;
    }

    .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
      -ms-transform: translateY(-1.28124em) scale(0.75);
      width: 133.3333433333%;
    }

    .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
      -ms-transform: translateY(-1.28123em) scale(0.75);
      width: 133.3333533333%;
    }
  }

  .mat-form-field-label {
    top: 4.7rem;
    padding: 0 1.5rem;
  }

  .mat-form-field-underline {
    bottom: 1.25em;
  }
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float {

    &.mat-form-field-should-float .mat-form-field-label,
    .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28122em) scale(0.75);
    }

    .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28121em) scale(0.75);
    }

    .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.2812em) scale(0.75);
    }
  }
}

.mat-form-field-appearance-fill {
  .mat-form-field-infix {
    padding: .25em 0 0 0;
  }

  .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
  }

  .mat-form-field-label {
    top: 1.09375em;
    margin-top: -0.5em;
  }

  &.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-0.59375em) scale(0.75);
      width: 133.3333333333%;
    }

    .mat-input-server {
      &:focus+.mat-form-field-label-wrapper .mat-form-field-label {
        transform: translateY(-0.59375em) scale(0.75);
        width: 133.3333333333%;
      }

      &[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
        transform: translateY(-0.59374em) scale(0.75);
        width: 133.3333433333%;
      }
    }
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: 1em 0 1em 0;
  }

  .mat-form-field-label {
    top: 1.84375em;
    margin-top: -0.25em;
  }

  &.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.59375em) scale(0.75);
      width: 133.3333333333%;
    }

    .mat-input-server {
      &:focus+.mat-form-field-label-wrapper .mat-form-field-label {
        transform: translateY(-1.59375em) scale(0.75);
        width: 133.3333333333%;
      }

      &[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
        transform: translateY(-1.59374em) scale(0.75);
        width: 133.3333433333%;
      }
    }
  }
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}

.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: color('primary', 'base');
}

input.mat-input-element,
.mat-select,
body textarea.mat-input-element {
  @include margin-padding(all, .3rem 0 .5rem, all, 1.5rem);
  color: color('grey', 'lighten-4') !important;
  border: .1rem solid color('grey', 'lighten-7');
  height: 4.5rem;
  width: 100%;
  border-radius: $default-radius;
  position: static !important;
  font-size: 1.4rem;
  transition: all .3s ease-out;

  &+.material-icons {
    @include abs-position (3.5rem, 1.5rem, 0, auto);
    color: color('primary', 'base');
    opacity: .6;
  }

  &:hover,
  &.input-hover {
    border: .1rem solid color('primary', 'light-base') !important;
  }

  &:focus,
  &.input-focus {
    border: .1rem solid color('accent', 'base') !important;
    color: color('primary', 'light-base') !important;
    caret-color: color('primary', 'base') !important;
  }
}

.mat-form-field-underline {
  display: none;
}

.material-icons {
  cursor: pointer;
}

.mat-menu-item {
  // font-family: $font-medium;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  // font-family: $font-medium;
  font-size: 12px;
}

.mat-paginator-page-size {
  align-items: center !important;

  .mat-form-field-infix {
    border: 0;
  }
}

// .mat-radio-button,
// .mat-select,
// input {
//   // font-family: $font-medium !important;
// }
.mat-select-trigger {
  height: 1.125em;
}

// .mat-slide-toggle-content {
//   // font-family: $font-medium;
// }
.mat-slider-thumb-label-text {
  // font-family: $font-medium;
  font-size: 12px;
  font-weight: 500;
}

// .mat-stepper-vertical,
// .mat-stepper-horizontal {
//   // font-family: $font-medium;
// }
.mat-step-label {
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 1.3rem;
  font-weight: 500;
  color: color('accent', 'base') !important;
}

// .mat-tab-group {
//   // font-family: $font-medium;
// }
.mat-tab-label,
.mat-tab-link {
  // font-family: $font-medium;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar {
  // font: 500 20px/32px $font-medium;
  margin: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // font: 500 20px/32px $font-medium;
    margin: 0;
    
  }
}

.mat-tooltip {
  // font-family: $font-medium;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

// .mat-list-item,
// .mat-list-option {
//   // font-family: $font-medium;
// }
.mat-list-base {

  .mat-list-item,
  .mat-list-option {
    font-size: 16px;

    .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box;

      &:nth-child(n+2) {
        font-size: 14px;
      }
    }
  }

  .mat-subheader {
    // font-family: $font-medium;
    font-size: 14px;
    font-weight: 500;
  }

  &[dense] {

    .mat-list-item,
    .mat-list-option {
      font-size: 12px;

      .mat-line {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        box-sizing: border-box;

        &:nth-child(n+2) {
          font-size: 12px;
        }
      }
    }

    .mat-subheader {
      // font-family: $font-medium;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.mat-option {
  padding: $no-padding;
}

.mat-option-text {
  // font-family: $font-medium;
  font-size: 1.4rem;
  color: color('primary', 'base');
  @include margin-padding(null, null, all, 1.5rem);

  &:hover {
    background-color: color('grey', 'lighten-7');
  }
}

// .mat-optgroup-label {
//   // font: 500 14px/24px $font-medium;
// }
.mat-simple-snackbar {
  // font-family: $font-medium;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

// .mat-tree {
//   // font-family: $font-medium;
// }
.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;

  &:not(:empty) {
    transform: translateZ(0);
  }

  &.mat-ripple-unbounded {
    overflow: visible;
  }
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: .1rem;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: .1rem;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 99;

  &:empty {
    display: none;
  }
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  max-width: 100% !important;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;

  &.cdk-overlay-backdrop-showing {
    opacity: 1;
  }
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: .6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  opacity: 0;

  &.cdk-overlay-backdrop-showing {
    opacity: 0;
  }
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: .1rem;
  min-height: .1rem;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}

@keyframes cdk-text-field-autofill-end {
  /*!*/
}

.cdk-text-field-autofill-monitored {
  &:-webkit-autofill {
    animation: cdk-text-field-autofill-start 0s 1ms;
  }

  &:not(:-webkit-autofill) {
    animation: cdk-text-field-autofill-end 0s 1ms;
  }
}

textarea {
  &.cdk-textarea-autosize {
    resize: none;
  }

  &.cdk-textarea-autosize-measuring {
    height: auto !important;
    overflow: hidden !important;
    padding: 2px 0 !important;
    box-sizing: content-box !important;
  }
}

.mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-option {
  color: color("grey", "lighten-2");

  &:hover:not(.mat-option-disabled),
  &:focus:not(.mat-option-disabled),
  &.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(255, 255, 255, 0.04);
  }

  &.mat-active {
    background: color('grey', 'lighten-7');
    color: color('primary', 'base');
  }

  &.mat-option-disabled {
    color: rgba(255, 255, 255, 0.5);
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(255, 255, 255, 0.04);
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: color("green", "base");
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: color('accent', 'base');
}

.mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}

.mat-pseudo-checkbox {
  color: color('grey', 'lighten-6');
  width: 2rem !important;
  height: 2rem !important;

  &::after {
    color: color('shades', 'white');
    width: 1.2rem !important;
    height: .4rem !important;
    @include abs-position(0 !important, 0, 0, 0 !important);
  }
}

.mat-pseudo-checkbox-disabled {
  color: #686868;
}

.mat-primary {

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background: color("accent", "base");
  }
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: color("accent", "base");
}

.mat-accent {

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background: color("accent", "base");
  }
}

.mat-warn {

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background: color('accent', 'base');
  }
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-app-background {
  background-color: #303030;
  color: color("shades", "white");
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: #424242;
  color: color("shades", "white");

  &:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: #424242;

    &:not(.mat-option-disabled) {
      color: color("shades", "white");
    }
  }
}

.mat-badge-content {
  color: color("shades", "white");
  background: color("blue", "base");
}

.cdk-high-contrast-active .mat-badge-content {
  outline: solid .1rem;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: color("green", "base");
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn .mat-badge-content {
  color: color("shades", "white");
  background: color('accent', 'base');
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content {
  transition: none;
}

.mat-badge-content {
  &._mat-animation-noopable {
    transition: none;
  }

  &.mat-badge-active {
    transform: none;
  }
}

.mat-badge-small {
  .mat-badge-content {
    width: 16px;
    height: 16px;
    line-height: 16px;
  }

  &.mat-badge-above .mat-badge-content {
    top: -8px;
  }

  &.mat-badge-below .mat-badge-content {
    bottom: -8px;
  }

  &.mat-badge-before .mat-badge-content {
    left: -16px;
  }
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium {
  .mat-badge-content {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }

  &.mat-badge-above .mat-badge-content {
    top: -11px;
  }

  &.mat-badge-below .mat-badge-content {
    bottom: -11px;
  }

  &.mat-badge-before .mat-badge-content {
    left: -22px;
  }
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large {
  .mat-badge-content {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }

  &.mat-badge-above .mat-badge-content {
    top: -14px;
  }

  &.mat-badge-below .mat-badge-content {
    bottom: -14px;
  }

  &.mat-badge-before .mat-badge-content {
    left: -28px;
  }
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: color("shades", "white");
}

.mat-button,
.mat-icon-button,
.mat-stroked-button {
  color: inherit;
  background: transparent;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: color("blue", "base");
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: color("green", "base");
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
  color: color('accent', 'base');
}

.mat-button {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(0, 0, 0, 0.3);
  }
}

.mat-icon-button {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(0, 0, 0, 0.3);
  }
}

.mat-stroked-button {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: rgba(0, 0, 0, 0.3);
  }
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: color("blue", "base");
}

.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: color("green", "base");
}

.mat-button.mat-warn .mat-button-focus-overlay,
.mat-icon-button.mat-warn .mat-button-focus-overlay,
.mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: color('accent', 'base');
}

.mat-button[disabled] .mat-button-focus-overlay,
.mat-icon-button[disabled] .mat-button-focus-overlay,
.mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-button .mat-ripple-element,
.mat-icon-button .mat-ripple-element,
.mat-stroked-button .mat-ripple-element {
  opacity: .1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: color("shades", "white");
}

// .mat-stroked-button:not([disabled]) {
//   border-color: rgba(0, 0, 0, 0.12);
// }
.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  color: color("shades", "white");
  background-color: color("grey", "lighten-4");
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: color("shades", "white");
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  color: color("shades", "white");
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  color: color("shades", "white");
}

.mat-flat-button {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    // color: #B5CDCF;
  }
}

.mat-raised-button {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: color("shades", "white");
    background-color: color("grey", "lighten-6");
  }
}

.mat-fab {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: color("shades", "white");
    background-color: color("grey", "lighten-6");
  }
}

.mat-mini-fab {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    color: color("shades", "white");
    background-color: color("grey", "lighten-6");
  }
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: color("blue", "base");
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: color("green", "base");
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  background-color: color('accent', 'base');
}

.mat-flat-button {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    // background-color: color("grey", "lighten-6");
  }
}

.mat-raised-button {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    background-color: rgba(255, 255, 255, 0.12);
  }
}

.mat-fab {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    background-color: rgba(255, 255, 255, 0.12);
  }
}

.mat-mini-fab {

  &.mat-primary[disabled],
  &.mat-accent[disabled],
  &.mat-warn[disabled],
  &[disabled][disabled] {
    background-color: rgba(255, 255, 255, 0.12);
  }
}

.mat-flat-button.mat-primary .mat-ripple-element,
.mat-raised-button.mat-primary .mat-ripple-element,
.mat-fab.mat-primary .mat-ripple-element,
.mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-flat-button.mat-accent .mat-ripple-element,
.mat-raised-button.mat-accent .mat-ripple-element,
.mat-fab.mat-accent .mat-ripple-element,
.mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-flat-button.mat-warn .mat-ripple-element,
.mat-raised-button.mat-warn .mat-ripple-element,
.mat-fab.mat-warn .mat-ripple-element,
.mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

// .mat-stroked-button:not([class*=mat-elevation-z]),
// .mat-flat-button:not([class*=mat-elevation-z]) {
//   // box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
//   // padding: $medium-button-padding;
// }
.mat-raised-button {
  &:not([class*=mat-elevation-z]) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: $medium-button-padding;
  }

  &:not([disabled]):active:not([class*=mat-elevation-z]) {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &[disabled]:not([class*=mat-elevation-z]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }
}

.mat-fab:not([class*=mat-elevation-z]),
.mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]),
.mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-fab[disabled]:not([class*=mat-elevation-z]),
.mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);

  .mat-button-toggle-focus-overlay {
    background-color: rgba(255, 255, 255, 0.12);
  }
}

.mat-button-toggle-appearance-standard {
  color: color("shades", "white");
  background: #424242;

  .mat-button-toggle-focus-overlay {
    background-color: color("shades", "white");
  }
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: solid .1rem rgba(255, 255, 255, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: none;
  border-right: solid .1rem rgba(255, 255, 255, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle+.mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid .1rem rgba(255, 255, 255, 0.12);
}

.mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);

  &.mat-button-toggle-appearance-standard {
    color: color("shades", "white");
  }
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.3);
  background-color: #000;

  &.mat-button-toggle-appearance-standard {
    background: #424242;
  }

  &.mat-button-toggle-checked {
    background-color: #424242;
  }
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid .1rem rgba(255, 255, 255, 0.12);
}

.mat-card {
  background: #424242;
  color: color("shades", "white");

  &:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  &.mat-card-flat:not([class*=mat-elevation-z]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }
}

.mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}


.mat-checkbox-frame {
  border-color: color('grey', 'lighten-5');
}

.mat-checkbox-checkmark {
  fill: #303030;
}

.mat-checkbox-checkmark-path {
  stroke: color("shades", "white") !important;
}

.mat-checkbox-mixedmark {
  background-color: #303030;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: color("blue", "base");
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: color("blue", "base");
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: color('accent', 'base');
}

.mat-checkbox-disabled {

  &.mat-checkbox-checked .mat-checkbox-background,
  &.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: #686868;
  }

  &:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: #686868;
  }

  .mat-checkbox-label {
    color: rgba(255, 255, 255, 0.7);
  }
}

.mat-checkbox .mat-ripple-element {
  background-color: color("shades", "white");
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: color('accent', 'base') !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: color('accent', 'base') !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: color('accent', 'base') !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: color("blue", "base");
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: color("blue", "base");
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: color('accent', 'base');
}

.mat-chip.mat-standard-chip {
  background-color: color('primary-light', 'base3');
  color: color("primary", "base");

  .mat-chip-remove {
    color: color("shades", "white");
    opacity: .4;
  }

  &:not(.mat-chip-disabled) {
    &:active {
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    }

    .mat-chip-remove:hover {
      opacity: .54;
    }
  }

  &.mat-chip-disabled {
    opacity: .4;
  }

  &::after {
    background: color("shades", "white");
  }

  &.mat-chip-selected {
    &.mat-primary {
      background-color: color("blue", "base");
      color: color("shades", "white");

      .mat-chip-remove {
        color: color("shades", "white");
        opacity: .4;
      }

      .mat-ripple-element {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    &.mat-warn {
      background-color: color('accent', 'base');
      color: color("shades", "white");

      .mat-chip-remove {
        color: color("shades", "white");
        opacity: .4;
      }

      .mat-ripple-element {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    &.mat-accent {
      background-color: color("green", "base");
      color: rgba(0, 0, 0, 0.87);

      .mat-chip-remove {
        color: rgba(0, 0, 0, 0.87);
        opacity: .4;
      }

      .mat-ripple-element {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.mat-table {
  background: color('shades', 'white');
  border: .1rem solid color('grey', 'lighten-7');
  border-radius: $default-radius;

  thead,
  tbody,
  tfoot {
    background: inherit;
  }
}

mat-header-row,
mat-row,
mat-footer-row,
[mat-header-row],
[mat-row],
[mat-footer-row],
.mat-table-sticky {
  background: inherit;
  height: 4.5rem !important;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell {
  border-bottom-color: color('grey', 'lighten-7');
}

td {

  &.mat-cell,
  &.mat-footer-cell {
    border-bottom-color: color('grey', 'lighten-7');
  }
}

.mat-header-cell {
  color: color('primary', 'base');
  background-color: color('grey', 'lighten-8');
}

.mat-cell,
.mat-footer-cell {
  color: color("grey", "lighten-4");
}

.mat-calendar-arrow {
  border-top-color: color("primary", "base");
}

.mat-datepicker-toggle {
  color: color("shades", "white");
}

.mat-datepicker-content {

  .mat-calendar-next-button,
  .mat-calendar-previous-button {
    color: color("primary", "base");
  }
}

.mat-calendar-table-header {
  color: color("primary", "base");
}

.mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}

.mat-button.mat-button-base {
  font-size: 1.5rem;
  font-weight: 400;
  color: color("primary", "base");
}

.mat-calendar-body-label {
  color: color("primary", "base");
}

.mat-calendar-body-cell-content {
  color: color("primary", "base");
  border-color: transparent;
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: color("grey", "lighten-5");
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: color("grey", "lighten-7");
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: color("grey", "lighten-6");
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.3);
}

.mat-calendar-body-selected {
  background-color: color("blue", "base");
  color: color("shades", "white");
}

.mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: rgba(194, 24, 91, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px color("shades", "white");
}

.mat-calendar-body-selected {
  background-color: color('accent', 'base') !important;
  color: color("shades", "white");
}

.mat-calendar-body-today {
  background-color: color("shades", "transparent");
  border: .1rem solid color("grey", "lighten-6");
}

.mat-calendar-body-selected.mat-calendar-body-today {
  border: none;
}

.mat-datepicker-content {
  @include box-shadow($box-shadow-primary);
  background-color: color("shades", "white");
  color: color("primary", "base");

  &.mat-accent {
    .mat-calendar-body-selected {
      background-color: color("green", "base");
      color: rgba(0, 0, 0, 0.87);
    }

    .mat-calendar-body-disabled>.mat-calendar-body-selected {
      background-color: rgba(176, 190, 197, 0.4);
    }

    .mat-calendar-body-today.mat-calendar-body-selected {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
    }
  }

  &.mat-warn {
    .mat-calendar-body-selected {
      background-color: color('accent', 'base');
      color: color("shades", "white");
    }

    .mat-calendar-body-disabled>.mat-calendar-body-selected {
      background-color: rgba(244, 67, 54, 0.4);
    }

    .mat-calendar-body-today.mat-calendar-body-selected {
      box-shadow: inset 0 0 0 1px color("shades", "white");
    }
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: color("blue", "base");

  &.mat-accent {
    color: color("green", "base");
  }

  &.mat-warn {
    color: color('accent', 'base');
  }
}

.mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}

.mat-expansion-panel {
  background: color("shades", "white");
  color: color("grey", "lighten-1");
}

.mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}

.mat-expansion-panel {
  border: 1px solid color('grey', 'lighten-7');
  margin: 0 0 .5rem !important;
  border-radius: $default-radius !important;

  &:last-child {
    margin-bottom: 0 !important;
  }

  .mat-expansion-panel-header {
    background-color: color('grey', 'lighten-8') !important;
    height: 5rem !important;
  }
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}

.mat-expansion-panel-header-title {
  color: color("grey", "lighten-1");
  @include align-items(center);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: color('grey', 'lighten-1');
  @include align-items(center);
  @include justify-content(flex-end);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.3);

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    color: inherit;
  }
}

.mat-form-field-label,
.mat-hint,
label,
.label {
  color: color('grey', 'lighten-1') !important;
  font-size: $label-font-size !important;
  text-transform: capitalize;
}

label,
.label {
  font-weight: 500;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: color("blue", "base");

  &.mat-accent {
    color: color("green", "base");
  }

  &.mat-warn {
    color: color('accent', 'base');
  }
}

.mat-focused .mat-form-field-required-marker {
  color: color("green", "base");
}

.mat-form-field-ripple {
  background-color: color("shades", "white");
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: color("blue", "base");

  &.mat-accent {
    background-color: color("green", "base");
  }

  &.mat-warn {
    background-color: color('accent', 'base');
  }
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) {
  .mat-form-field-infix::after {
    color: color("blue", "base");
  }

  &.mat-accent .mat-form-field-infix::after {
    color: color("green", "base");
  }

  &.mat-warn .mat-form-field-infix::after {
    color: color('accent', 'base');
  }
}

.mat-form-field.mat-form-field-invalid {
  .mat-form-field-label {
    color: color('accent', 'base');

    &.mat-accent,
    .mat-form-field-required-marker {
      color: color('accent', 'base');
    }
  }

  .mat-form-field-ripple {
    background-color: color('accent', 'base');

    &.mat-accent {
      background-color: color('accent', 'base');
    }
  }
}

.mat-error {
  color: color('accent', 'base');
  margin-top: -3px;
}

.mat-form-field-appearance-legacy {

  .mat-form-field-label,
  .mat-hint {
    color: color("grey", "lighten-5")
  }

  .mat-form-field-underline {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
    background-size: 4px 100%;
    background-repeat: repeat-x;
  }
}

.mat-form-field-appearance-standard {
  .mat-form-field-underline {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
    background-size: 4px 100%;
    background-repeat: repeat-x;
  }
}

.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.mat-form-field-disabled .mat-form-field-flex {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .mat-form-field-underline::before {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.mat-form-field-disabled {
    .mat-form-field-label {
      color: rgba(255, 255, 255, 0.5);
    }

    .mat-form-field-underline::before {
      background-color: transparent;
    }
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.3);
  }

  .mat-form-field-outline-thick {
    color: color("shades", "white");
  }

  &.mat-focused {
    .mat-form-field-outline-thick {
      color: color("blue", "base");
    }

    &.mat-accent .mat-form-field-outline-thick {
      color: color("green", "base");
    }

    &.mat-warn .mat-form-field-outline-thick {
      color: color('accent', 'base');
    }
  }

  &.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: color('accent', 'base');
  }

  &.mat-form-field-disabled {
    .mat-form-field-label {
      color: rgba(255, 255, 255, 0.5);
    }

    .mat-form-field-outline {
      color: rgba(255, 255, 255, 0.15);
    }
  }
}

.mat-icon {
  &.mat-primary {
    color: color("blue", "base");
  }

  &.mat-accent {
    color: color("green", "base");
  }

  &.mat-warn {
    color: color('accent', 'base');
  }
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}

.mat-input-element {

  // caret-color: color("blue", "base");
  &::placeholder,
  &::-moz-placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder {
    color: color("primary", "light-base") !important;
  }

  option {
    color: color("primary", "light-base");

    &:disabled {
      color: rgba(0, 0, 0, 0.38);
    }
  }
}

.mat-accent .mat-input-element {
  caret-color: color("green", "base");
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: color('accent', 'base');
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: color('accent', 'base');
}

.mat-list-base {

  // .mat-list-item,
  // .mat-list-option {
  //   // color: color("shades", "white");
  // }
  .mat-subheader {
    color: rgba(255, 255, 255, 0.7);
  }
}

.mat-list-item-disabled {
  background-color: #000;
}

.mat-list-option {

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.04);
  }
}

.mat-nav-list .mat-list-item {

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.04);
  }
}

.mat-action-list .mat-list-item {

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.04);
  }
}

.mat-list-single-selected-option {
  background: rgba(255, 255, 255, 0.12);

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.12);
  }
}

.mat-menu-panel {
  background-color: color('shades', 'white');
  @include box-shadow($box-shadow-primary);
  max-width: 100% !important;
  min-width: 102px !important;

  &:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}

button.mat-menu-item {
  @include flexbox;
  @include align-items(center);
}

.mat-menu-item {
  background: transparent;
  color: color('grey', 'lighten-1');

  a {
    color: color('grey', 'lighten-1');
    font-weight: 400;
  }

  &[disabled] {
    color: rgba(255, 255, 255, 0.5);

    &::after {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .mat-icon {
    margin-right: .8rem !important;
  }

  .mat-icon-no-color {
    color: color('grey', 'lighten-1');
  }
}

.mat-menu-item-submenu-trigger::after {
  color: color("shades", "white");
}

.mat-menu-item {

  &:hover:not([disabled]),
  &.cdk-program-focused:not([disabled]),
  &.cdk-keyboard-focused:not([disabled]) {
    background: rgba(255, 255, 255, 0.04);
  }
}

.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}

.mat-paginator-page-size-label {
  color: color("grey", "lighten-4");
  ;
}

.mat-paginator-range-label {
  color: color("grey", "lighten-4");
  ;

  @include breakpoint(x-small) {
    margin: 0 1rem 0 1rem !important;
  }
}

.mat-paginator-container {
  @include breakpoint(x-small) {
    justify-content: flex-start !important;
  }

  min-height: 4.5rem !important;
}

.mat-paginator {
  border: .1rem solid color('grey', 'lighten-7');
  border-top: 0;

  .mat-select {
    padding: .5rem;
    margin: 0;
    height: auto;
    border-radius: 0;
  }

  .mat-select-value {
    color: color('primary', 'light-base');
  }
}

.mat-paginator {
  background: color('shades', 'white');
  color: color('primary', 'light-base');
}

.mat-paginator-page-size .mat-select-trigger {
  color: color('primary', 'light-base');
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: .2rem solid color("shades", "white");
  border-right: .2rem solid color("shades", "white");
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: .2rem solid color("shades", "white");
}

.mat-icon-button[disabled] {

  .mat-paginator-decrement,
  .mat-paginator-increment,
  .mat-paginator-first,
  .mat-paginator-last {
    border-color: rgba(255, 255, 255, 0.5);
  }
}

.mat-progress-bar-background {
  fill: color('accent', 'base');
}

.mat-progress-bar-buffer {
  background-color: color('grey', 'lighten-7');
}

.mat-progress-bar-fill::after {
  background-color: color("primary", "base");
}

.mat-progress-bar {
  border-radius: $default-radius;

  &.mat-accent {
    .mat-progress-bar-background {
      fill: #cfd8dc;
    }

    .mat-progress-bar-buffer {
      background-color: #cfd8dc;
    }

    .mat-progress-bar-fill::after {
      background-color: color("green", "base");
    }
  }

  &.mat-warn {
    .mat-progress-bar-background {
      fill: #ffcdd2;
    }

    .mat-progress-bar-buffer {
      background-color: #ffcdd2;
    }

    .mat-progress-bar-fill::after {
      background-color: color('accent', 'base');
    }
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: color("blue", "base");
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: color("green", "base");
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
  stroke: color('accent', 'base');
}

.mat-radio-outer-circle {
  border-color: color('grey', 'lighten-7');
}

.mat-radio-button {
  &.mat-primary {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: color("blue", "base");
    }

    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: color("blue", "base");
    }
  }

  &.mat-accent {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: color("green", "base");
    }

    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: color("green", "base");
    }
  }

  &.mat-warn {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: color('accent', 'base');
    }

    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    &.mat-radio-checked .mat-radio-persistent-ripple,
    &:active .mat-radio-persistent-ripple {
      background-color: color('accent', 'base');
    }
  }

  &.mat-radio-disabled {

    &.mat-radio-checked .mat-radio-outer-circle,
    .mat-radio-outer-circle {
      border-color: rgba(255, 255, 255, 0.5);
    }

    .mat-radio-ripple .mat-ripple-element,
    .mat-radio-inner-circle {
      background-color: rgba(255, 255, 255, 0.5);
    }

    .mat-radio-label-content {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .mat-ripple-element {
    background-color: color("shades", "white");
  }
}



// .mat-select-value {
//   color: color("shades", "white");
// }
// .mat-select-placeholder,
// .mat-select-disabled .mat-select-value {
//   color: rgba(255, 255, 255, 0.5);
// }
.mat-option-text {
  color: color("grey", "lighten-2");
}

.mat-checkbox-inner-container {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: $button-radius-default;
}

.mat-select-value-text {
  color: color("grey", "lighten-4");
}

.mat-select-arrow {
  color: color("grey", "lighten-2");
}

.mat-select-panel {
  background: #ffffff;

  &:not([class*=mat-elevation-z]) {
    // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border: .1rem solid color('grey', 'lighten-7');
  }

  .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(255, 255, 255, 0.12);
  }
}

.mat-form-field {
  &.mat-focused {
    &.mat-primary .mat-select-arrow {
      color: color("blue", "base");
    }

    &.mat-accent .mat-select-arrow {
      color: color("green", "base");
    }

    &.mat-warn .mat-select-arrow {
      color: color('accent', 'base');
    }
  }

  .mat-select {
    &.mat-select-invalid .mat-select-arrow {
      color: color('accent', 'base');
    }

    &.mat-select-disabled .mat-select-arrow {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.mat-drawer-container {
  // background-color: color("light", "base");
  color: color("shades", "white");
}

.mat-drawer {
  background-color: color("grey", "base");
  color: color("primary", "base");
  width: 30rem;

  &.mat-drawer-push {
    background-color: #424242;
  }

  &:not(.mat-drawer-side) {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
}

.mat-drawer-side {
  border-right: solid .1rem rgba(255, 255, 255, 0.12);

  &.mat-drawer-end {
    border-left: solid .1rem rgba(255, 255, 255, 0.12);
    border-right: none;
  }
}

[dir=rtl] .mat-drawer-side {
  border-left: solid .1rem rgba(255, 255, 255, 0.12);
  border-right: none;

  &.mat-drawer-end {
    border-left: none;
    border-right: solid .1rem rgba(255, 255, 255, 0.12);
  }
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}

.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-thumb {
      background-color: color("green", "base");
    }

    .mat-slide-toggle-bar {
      background-color: rgba(176, 190, 197, 0.54);
    }

    .mat-ripple-element {
      background-color: color("green", "base");
    }
  }

  &.mat-primary.mat-checked {
    .mat-slide-toggle-thumb {
      background-color: color("blue", "base");
    }

    .mat-slide-toggle-bar {
      background-color: rgba(194, 24, 91, 0.54);
    }

    .mat-ripple-element {
      background-color: color("blue", "base");
    }
  }

  &.mat-warn.mat-checked {
    .mat-slide-toggle-thumb {
      background-color: color('accent', 'base');
    }

    .mat-slide-toggle-bar {
      background-color: rgba(244, 67, 54, 0.54);
    }

    .mat-ripple-element {
      background-color: color('accent', 'base');
    }
  }

  &:not(.mat-checked) .mat-ripple-element {
    background-color: color("shades", "white");
  }
}

.mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  width: 16px;
  height: 16px;
  @include abs-position(3px, auto, auto, 4px);
}

.mat-slide-toggle-thumb {
  height: 16px !important;
  width: 16px !important;
  background-color: color("shades", "white");
  box-shadow: none;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  transform: translate3d(21px, 0, 0);
}

.mat-ink-bar {
  height: 4px !important;
  border-radius: $rounded-radius;
  background-color: color('accent', 'base');
  display: none;
}

.mat-expanded .mat-ink-bar {
  display: block;
}

.material-icons {
  color: color('primary', 'base');
    
  &.orange-icon{
    color: color('accent', 'base');
  }
  &.white-icon {
    color: color('shades', 'white');
  }

  &.red-icon {
    color: color('red', 'base') !important;
  }
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-list-base .mat-list-item {
  color: color('primary', 'base') !important;
  font-size: 1.6rem !important;
  height: auto !important;
}

.mat-tab-label {
  opacity: 1 !important;
  min-width: auto !important;
  padding: 0 1.5rem 0 0 !important;
  // margin: 0 1.5rem;
}

.mat-tab-label-active {
  .mat-tab-label-content {
    color: color('accent', 'base');
    font-weight: 500;
  }
}

.mat-tab-label-content {
  font-size: 1.8rem;
  color: color('primary', 'base');
}

.mat-tab-group {
  width: 100%;

  .mat-tab-body-wrapper,
  .mat-tab-body.mat-tab-body-active,
  .mat-tab-body-content {
    overflow: visible !important;
  }
}

.mat-slide-toggle.mat-checked .mat-ripple-element,
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: color('accent', 'base');
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: color("shades", "white");
}

.mat-slide-toggle-bar {
  background-color: color('grey', 'lighten-7');
  width: 44px !important;
  height: 22px !important;
  border-radius: $rounded-radius !important;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.3);
}

.mat-primary {

  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: color("blue", "base");
  }

  .mat-slider-thumb-label-text {
    color: color("shades", "white");
  }
}

.mat-accent {

  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: color("green", "base");
  }

  // .mat-slider-thumb-label-text {
  //   color: rgba(0, 0, 0, 0.87);
  // }
}

.mat-warn {

  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: color('accent', 'base');
  }

  .mat-slider-thumb-label-text {
    color: color("shades", "white");
  }
}

.mat-slider-focus-ring {
  background-color: rgba(176, 190, 197, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.3);
}

.mat-slider-disabled {

  .mat-slider-track-background,
  .mat-slider-track-fill,
  .mat-slider-thumb,
  &:hover .mat-slider-track-background {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.mat-slider-min-value {
  .mat-slider-focus-ring {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &.mat-slider-thumb-label-showing {

    .mat-slider-thumb,
    .mat-slider-thumb-label {
      background-color: color("shades", "white");
    }

    &.cdk-focused {

      .mat-slider-thumb,
      .mat-slider-thumb-label {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  &:not(.mat-slider-thumb-label-showing) {
    .mat-slider-thumb {
      border-color: rgba(0, 0, 0, 0.3);
      background-color: transparent;
    }

    &:hover .mat-slider-thumb,
    &.cdk-focused .mat-slider-thumb,
    &:hover.mat-slider-disabled .mat-slider-thumb,
    &.cdk-focused.mat-slider-disabled .mat-slider-thumb {
      border-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-step-header {
  padding: 0 !important;

  &.cdk-keyboard-focused,
  &.cdk-program-focused,
  &:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }

  .mat-step-label,
  .mat-step-optional {
    color: rgba(255, 255, 255, 0.7);
    background-color: color("shades", "white");
  }

  .mat-step-icon {
    font-size: 1.2rem;
    font-weight: 500;
    background-color: color("grey", "lighten-7");
    color: color("grey", "lighten-2");

    .mat-icon {
      color: color("shades", "white");
    }
  }

  .mat-step-icon-selected,
  .mat-step-icon-state-done,
  .mat-step-icon-state-edit {
    background-color: color("accent", "base");
    color: color("shades", "white");
  }

  .mat-step-icon-state-error {
    background-color: transparent;
    color: color('accent', 'base');
  }

  .mat-step-label {
    .mat-step-text-label {
      // color: color("grey", "lighten-4");
      background-color: color("shades", "white");
    }

    &.mat-step-label-active {
      color: color("grey", "lighten-4");
    }

    &.mat-step-label-error {
      color: color('accent', 'base');
    }
  }
}

@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: color('shades', 'transparent');
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}

.mat-horizontal-stepper-header {

  &::before,
  &::after {
    border-top-color: rgba(255, 255, 255, 0.12);
  }
}

.mat-stepper-horizontal-line {
  border-top-color: color('grey', 'lighten-7');
}

.mat-sort-header-arrow {
  color: #c6c6c6;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: .1rem solid rgba(255, 255, 255, 0.12);
}

.mat-tab-group-inverted-header {

  .mat-tab-nav-bar,
  .mat-tab-header {
    border-top: .1rem solid rgba(255, 255, 255, 0.12);
    border-bottom: none;
  }
}

.mat-tab-label,
.mat-tab-link {
  color: color("shades", "white");
}

.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}

.mat-tab-header-pagination-chevron {
  border-color: color("shades", "white");
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(233, 30, 99, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(233, 30, 99, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-primary {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(233, 30, 99, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(233, 30, 99, 0.3);
    }
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: color("blue", "base");
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: color("shades", "white");
}

.mat-tab-group.mat-accent {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(207, 216, 220, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(207, 216, 220, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-accent {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(207, 216, 220, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(207, 216, 220, 0.3);
    }
  }
}

.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: color("green", "base");
}

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-group.mat-warn {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-warn {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
}

.mat-tab-group.mat-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: color('accent', 'base');
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: color("shades", "white");
}

.mat-tab-group.mat-background-primary {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(233, 30, 99, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(233, 30, 99, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-background-primary {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(233, 30, 99, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(233, 30, 99, 0.3);
    }
  }
}

.mat-tab-group.mat-background-primary {

  .mat-tab-header,
  .mat-tab-links,
  .mat-tab-header-pagination {
    background-color: color("blue", "base");
  }
}

.mat-tab-nav-bar.mat-background-primary {

  .mat-tab-header,
  .mat-tab-links,
  .mat-tab-header-pagination {
    background-color: color("blue", "base");
  }
}

.mat-tab-group.mat-background-primary {

  .mat-tab-label,
  .mat-tab-link {
    color: color("shades", "white");
  }
}

.mat-tab-nav-bar.mat-background-primary {

  .mat-tab-label,
  .mat-tab-link {
    color: color("shades", "white");
  }
}

.mat-tab-group.mat-background-primary {

  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-nav-bar.mat-background-primary {

  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: color("shades", "white");
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-tab-group.mat-background-accent {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(207, 216, 220, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(207, 216, 220, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-background-accent {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(207, 216, 220, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(207, 216, 220, 0.3);
    }
  }
}

.mat-tab-group.mat-background-accent {

  .mat-tab-header,
  .mat-tab-links,
  .mat-tab-header-pagination {
    background-color: color("green", "base");
  }
}

.mat-tab-nav-bar.mat-background-accent {

  .mat-tab-header,
  .mat-tab-links,
  .mat-tab-header-pagination {
    background-color: color("green", "base");
  }
}

.mat-tab-group.mat-background-accent {

  .mat-tab-label,
  .mat-tab-link {
    color: rgba(0, 0, 0, 0.87);
  }
}

.mat-tab-nav-bar.mat-background-accent {

  .mat-tab-label,
  .mat-tab-link {
    color: rgba(0, 0, 0, 0.87);
  }
}

.mat-tab-group.mat-background-accent {

  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.4);
  }
}

.mat-tab-nav-bar.mat-background-accent {

  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.4);
  }
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}

.mat-tab-group.mat-background-accent .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-tab-group.mat-background-warn {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
}

.mat-tab-nav-bar.mat-background-warn {
  .mat-tab-label {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }

  .mat-tab-link {

    &.cdk-keyboard-focused:not(.mat-tab-disabled),
    &.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: rgba(255, 205, 210, 0.3);
    }
  }
}

.mat-tab-group.mat-background-warn {

  .mat-tab-header,
  .mat-tab-links,
  .mat-tab-header-pagination {
    background-color: color('accent', 'base');
  }
}

.mat-tab-nav-bar.mat-background-warn {

  .mat-tab-header,
  .mat-tab-links,
  .mat-tab-header-pagination {
    background-color: color('accent', 'base');
  }
}

.mat-tab-group.mat-background-warn {

  .mat-tab-label,
  .mat-tab-link {
    color: color("shades", "white");
  }
}

.mat-tab-nav-bar.mat-background-warn {

  .mat-tab-label,
  .mat-tab-link {
    color: color("shades", "white");
  }
}

.mat-tab-group.mat-background-warn {

  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-nav-bar.mat-background-warn {

  .mat-tab-label.mat-tab-disabled,
  .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: color("shades", "white");
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #212121;
  color: color("shades", "white");

  &.mat-primary {
    background: color("blue", "base");
    color: color("shades", "white");
  }

  &.mat-accent {
    background: color("green", "base");
    color: rgba(0, 0, 0, 0.87);
  }

  &.mat-warn {
    background: color('accent', 'base');
    color: color("shades", "white");
  }

  .mat-form-field-underline,
  .mat-form-field-ripple,
  .mat-focused .mat-form-field-ripple {
    background-color: currentColor;
  }

  .mat-form-field-label,
  .mat-focused .mat-form-field-label,
  .mat-select-value,
  .mat-select-arrow,
  .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit;
  }

  .mat-input-element {
    caret-color: currentColor;
  }
}

.mat-tooltip {
  background: color('shades', 'white');
  box-shadow: $box-shadow-white-container;
  font-size: 1.4rem;
  color: color('primary', 'base') !important;
  border: .1rem solid color('grey', 'lighten-7');
  max-width: 37rem !important;
  line-height: 1.5;
}

.mat-tree {
  background: #424242;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: color("shades", "white");
}

.mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  max-width: 80vw !important;
}

.mat-snack-bar-handset .mat-snack-bar-container {
  max-width: 100% !important;
}

.mat-simple-snackbar-action {
  color: inherit;
}

.mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}
