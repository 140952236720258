a {
  text-decoration: none;
}

body,
html {
  height: 100%;
  font-size: 10px;
  font-family: 'Roboto', sans-serif;
  background-color: color('grey', 'lighten-8');
  color: color('grey', 'lighten-1');
}

p {
  font-size: 1.5rem;
  color: color('grey', 'lighten-3');
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: color('grey', 'lighten-1');
  line-height: 1.3;
}

// Header Styles
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}

h1 {
  font-size: $h1-fontsize;
  line-height: 110%;
  @include margin-padding(all, 2rem 0, null, null);
}

h2 {
  font-size: $h2-fontsize;
  line-height: 110%;
  @include margin-padding(all, 2rem 0, null, null);
}

h3 {
  font-size: $h3-fontsize;
  line-height: 110%;
  @include margin-padding(all, 2rem 0, null, null);
}

h4 {
  font-size: $h4-fontsize;
  line-height: 110%;
  @include margin-padding(all, 2rem 0, null, null);
  font-weight: 500
}

h5 {
  font-size: $h5-fontsize;
  line-height: 110%;
  @include margin-padding(all, 2rem 0, null, null);
}

h6 {
  font-size: $h6-fontsize;
  line-height: 110%;
  @include margin-padding(all, 2rem 0, null, null);
}

// Text Styles
em {
  font-style: italic;
}

strong {
  font-weight: 500;
}

small {
  font-size: 75%;
}

.light {
  font-weight: 300;
}

.thin {
  font-weight: 200;
}

.flow-text {
  font-weight: 300;
  $i: 0;

  @while $i <=$intervals {
    @media only screen and (min-width: 360 + ($i * $interval-size)) {
      font-size: 1.2rem * (1 + (.02 * $i));
    }

    $i: $i+1;
  }

  // Handle below 360px screen
  @media only screen and (max-width: 360px) {
    font-size: 1.2rem;
  }
}
