body .mat-dialog-content {
  height: 100%;
  overflow-y: auto;
  max-height: 100%;

  @include breakpoint(medium) {
    max-height: 100%;
  }

  .go-live-social {
    span {
      font-weight: 400;
      color: color("grey", "lighten-2");
    }
  }
}

@include breakpoint(x-small) {
  .mat-dialog-container {
    .mat-paginator-range-actions {
      .mat-icon-button {
        width: 3rem;
      }

      .mat-paginator-range-label {
        @include margin-padding(all, 0 1rem 0 0.5rem, null, null !important);
      }
    }
  }
}

body .mat-dialog-container {
  background-color: color("shades", "white");
  position: relative;
  // @include margin-padding(null, null, all, 4rem 5rem 3.5rem);
  // overflow: hidden;
  border-radius: 1.2rem;
  max-height: 97vh;

  @include breakpoint(medium) {
    @include margin-padding(all, 1.5rem, all, 2.5rem !important);
  }

  .mat-dialog-title {
    @include margin-padding(all, 1rem, null, null);
    font-size: 2.4rem;
    @media (max-width:#{$small-screen}) {
      margin: 1rem 0;
      font-size: 2rem;
    }
  }

  .close {
    @include abs-position(2rem, 2rem, auto, auto);
    font-weight: 500;
    color: color("accent", "base");
    font-size: 1.6rem;
    &.skip {
      font-size: 2.2rem;
    }
    @include breakpoint(x-small) {
      top: 0;
      right: 0;
    }

    .mat-button-wrapper {
      @include flexbox;
      @include align-items(center);
    }

    .material-icons {
      color: color("accent", "base");
      @include margin-padding(right, 0.2rem, null, null);
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.33;
  }
}

body .mat-dialog-actions {
  @include margin-padding(bottom, 0, bottom, 0);
}

.button-two {
  @include margin-padding(left, 1.5rem !important, null, null);
}

.end-session-popup {
  .mat-dialog-container {
    @include margin-padding(null, null, all, 6.5rem 4rem);

    .mat-flat-button {
      min-width: 9rem;
      text-transform: initial;
    }

    img[src="assets/images/stop-rec.svg"] {
      @include margin-padding(null, null, left, 3rem);
    }
  }

  .mat-dialog-title {
    @include margin-padding(top, 1rem !important, null, null);
  }
}

.recording-progress {
  position: relative;
  text-align: center;

  .mat-progress-spinner {
    margin: auto;

    &:first-child {
      circle {
        stroke: #ebecee;
      }
    }

    &:nth-child(2) {
      @include abs-position(0, 0, 0, 0);
    }
  }

  .number {
    @include abs-position(0, 0, 0, 0);
    font-size: 4.2rem;
    font-weight: 700;
    color: color("grey", "lighten-2");
    height: max-content;
    width: max-content;
  }

  .count-down-timer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100vh;
    font-size: 8rem;
    width: 100vw;
    z-index: 999999;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    background-color: color("accent", "base");
    color: color("shades", "white");
    font-weight: 500;
  }

  .percent {
    @include abs-position(-4rem, -6rem, 0, 0);
    font-size: 2rem;
    color: color("grey", "lighten-4");
    height: max-content;
    width: max-content;
  }
}

.save-recording {
  text-align: center;

  p {
    font-size: 1.6rem;
  }
}

.social-details-outer {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  flex-wrap: wrap;
  border-bottom: 1px solid color("grey", "lighten-7");
  height: 7.3rem;

  @include breakpoint(iphone) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5rem !important;
  }

  &.social-info-outer .social-details p:last-child {
    width: 12rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.no-checkbox {
    @include margin-padding(null, null, left, 0);

    .social-details .social-media-image {
      @include margin-padding(left, 0, null, null);
    }
  }

  &:first-child {
    @include margin-padding(top, 1rem, null, null);
  }

  &:last-child {
    border: none;
  }

  .social-details {
    @include flexbox;
    @include align-items(center);

    .social-media-image {
      @include margin-padding(all, 0 0.8rem 0 0rem, null, null);
    }

    p {
      @include margin-padding(all, 0, null, null);
      text-align: left;

      &:first-child {
        font-size: 1.4rem;
        @include margin-padding(bottom, 0.3rem, null, null);
      }

      &:last-child {
        color: color("grey", "lighten-1");
      }
    }
  }

  .destination,
  .visibility {
    flex: 0 18rem;
    text-align: center;

    .mat-form-field-empty {
      display: none;
    }

    .mat-form-field-hide-placeholder .mat-select-placeholder {
      color: #787878;
      -webkit-text-fill-color: #787878;
    }
  }

  .destination {
    p {
      color: color("grey", "lighten-1");
    }
  }

  .mat-flat-button {
    padding: 0 !important;
    text-transform: initial;

    .material-icons {
      font-size: 2rem;
      transform: rotate(135deg);
      color: color("accent", "base");
    }
  }
}

.mat-option.visibility-select {
  @include margin-padding(null, null, all, 0 1.5rem !important);
  border-bottom: 1px solid color("grey", "lighten-7");
  height: 4.5rem !important;

  &:last-child {
    border: none;
  }

  &.mat-active {
    background-color: color("shades", "transparent");
  }

  .mat-option-text {
    @include margin-padding(null, null, all, 0);

    &:hover {
      background-color: color("shades", "transparent");
    }
  }
}

.link-social-media {
  .mat-dialog-container {
    padding: 5.2rem;
  }

  .mat-dialog-actions {
    @include flexbox;
    @include justify-content(space-between);
  }
}

/* Manage session popup css starts*/
.manage-session-room {
  .mat-dialog-container {
    @include margin-padding(null, null, all, 3.5rem 4rem 4rem !important);
  }

  .session-grouping {
    @include flexbox;
    @include align-items(center);
    position: relative;
    @include margin-padding(all, 2rem 0 1rem, null, null);

    &::after {
      @include abs-position(0, 0, 0, auto);
      width: 80%;
      height: 0.1rem;
      background-color: color("grey", "lighten-7");
      content: "";
    }

    .number {
      height: 1.9rem;
      width: 1.9rem;
      background-color: color("accent", "base");
      border-radius: $circular-radius;
      color: color("shades", "white");
      display: inline-flex;
      @include align-items(center);
      @include justify-content(center);
      @include margin-padding(right, 1rem, null, null);
      font-size: 1.3rem;
    }

    .group-name {
      font-size: 1.3rem;
      font-weight: 500;
      text-transform: uppercase;
      color: color("accent", "base");
      display: inline-block;
    }
  }

  .form-label-with-right {
    @include margin-padding(all, 0, null, null);

    &.go-live-toggle {
      @include margin-padding(bottom, 1rem, null, null);
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    border: none;
    @include margin-padding(null, null, all, 0);
  }

  .mat-button.mat-button-base {
    line-height: initial;
    @include margin-padding(null, null, all, 0);
  }

  .destination,
  .visibility {
    label {
      @include abs-position(-0.2rem, auto, auto, 1rem);
      @include margin-padding(null, null, all, 0 0.4rem);
      font-size: 1.2rem !important;
      background-color: color("shades", "white");
      color: color("grey", "lighten-4") !important;
    }
  }

  .add-more {
    @include margin-padding(all, 1rem auto 0, null, null);
    position: relative;
    top: -1.9rem;
    background-color: color("shades", "white");
    width: 11rem;
  }
}

/* Manage session popup css ends*/

.social-configure-heading {
  display: inline-flex !important;
  @include align-items(center);

  svg {
    @include margin-padding(right, 1rem, null, null);
  }
}

.popup-info-image {
  max-width: 12rem;
  @include margin-padding(all, auto, null, null);
}

.delete-session-popup {
  // .mat-dialog-container p {
  //   // max-width: 39rem;
  //   // @include margin-padding(all, 0 auto 2rem, null, null);
  // }

  .popup-info-image {
    max-width: 9rem;
    @include margin-padding(all, 0 auto 1rem, null, null);
  }
}

/* ==== Create session css starts ==== */
.create-session-popup {
  .equal-height {
    height: 30.5rem;
    display: block;
  }

  .mat-dialog-container {
    overflow-y: auto;
  }

  .add-more {
    @include margin-padding(bottom, 1rem, all, 0);
  }

  .delete-guest {
    min-width: auto;
    @include margin-padding(top, 3.4rem, all, 0);
  }
}

.create-session.half-center {
  max-width: 60rem;
  @include margin-padding(all, auto, null, null);
  position: relative;

  .mat-dialog-actions {
    @include margin-padding(bottom, 0, null, null);
  }

  .time-zone {
    @include flexbox;
    @include align-items(center);
    @include margin-padding(all, 1rem 0 3rem, bottom, 2rem);
    border-bottom: 0.1rem solid color("primary", "light");

    .mat-button {
      font-size: 1.6rem;
      color: color("accent", "base");

      &:hover {
        color: color("primary", "base");

        .material-icons {
          color: color("primary", "base");
        }
      }

      .material-icons {
        font-size: 1.8rem;
        color: color("accent", "base");
      }
    }

    p {
      @include margin-padding(all, 0, null, null);
    }
  }

  .mat-checkbox {
    @include margin-padding(all, 1rem 0 0, null, null);
    display: inline-block;
  }
}

/* ==== Create session css ends ==== */

/* ==== Manage session css starts ==== */

.manage-session {
  .video-cam {
    height: 15rem;
    background-color: color("grey", "lighten-6");
    @include margin-padding(bottom, 1.6rem, null, null);
    border-radius: 10px;

    video {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .session-settings {
    @include margin-padding(null, null, all, 4rem);

    @include breakpoint(medium) {
      @include margin-padding(null, null, all, 0);

      h1 {
        font-size: 2.3rem;
      }
    }
  }

  mat-label {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
  }

  .mat-slide-toggle {
    @include margin-padding(right, 4rem, null, null);
  }

  .mat-slide-toggle-bar::after {
    position: absolute;
    content: "Off";
    right: -3.4rem;
  }

  .mat-checked .mat-slide-toggle-bar::after {
    position: absolute;
    content: "On";
    right: -3.4rem;
  }

  label {
    .main-div {
      @include flexbox;
      @include justify-content(space-between);

      .inner-content {
        @include flexbox;
        @include align-items(center);

        .material-icons {
          font-size: 2.2rem;
          @include margin-padding(right, 0.8rem, null, null);
        }

        .play {
          padding: $no-padding;
          line-height: 0;

          .mat-button-wrapper {
            @include flexbox;
            @include align-items(center);
          }
        }
      }
    }
  }

  .mat-progress-bar {
    @include margin-padding(bottom, 1rem, null, null);
  }

  .echo {
    @include margin-padding(all, 1rem 0 1.4rem, null, null);
  }

  .get-help {
    text-align: center;
    @include margin-padding(top, 0, null, null);
    position: absolute;
    bottom: 5rem;
    display: none;

    @include breakpoint(medium) {
      position: static;
    }

    .mat-button {
      font-weight: 500;

      .mat-button-wrapper {
        @include flexbox;
        @include align-items(center);

        .material-icons {
          @include margin-padding(right, 0.8rem, null, null);
        }
      }
    }
  }
}

/* ==== Manage session css ends ==== */

/* ==== Manage session inner css starts ==== */

.manage-session-inner {
  .mat-dialog-container {
    @include margin-padding(null, null, all, 4rem 4rem 3.5rem);
  }

  &.manage-session {
    .mat-step-header {
      display: none;
    }

    .mat-dialog-title {
      @include margin-padding(bottom, 2rem, null, null);
    }
  }

  .manage-session {
    .mat-step-header {
      display: none;
    }

    .mat-dialog-title {
      @include margin-padding(bottom, 2rem, null, null);
    }

    .session-settings {
      @include margin-padding(null, null, all, 3rem 1.5rem);

      @include breakpoint(medium) {
        @include margin-padding(null, null, all, 0);
      }
    }
  }
}

/* ==== Manage session inner css ends ==== */

/* ==== Feedback popup css starts ==== */

.feedback-popup {
  max-width: 70rem;
  position: relative;

  h3 {
    @include margin-padding(all, 2rem 0 0.8rem !important, null, null);
  }

  .mat-dialog-title {
    @include margin-padding(top, 4rem, null, null);
  }

  .mat-dialog-content {
    @include margin-padding(top, 3rem, top, 3rem);
    border-top: 0.1rem solid color("primary", "light");
  }

  .mat-chip {
    @include margin-padding(top, 0.5rem, null, null !important);
  }

  .rating {
    .mat-list-item {
      display: inline-block;
      width: auto;

      .mat-button {
        min-width: auto;
        padding: $no-padding;
        line-height: 0;

        .material-icons {
          font-size: 5rem;
          color: color("accent", "base");
        }
      }
    }
  }
}

/*==== Feedback popup css ends ====/

/*==== Live stream popup css starts ====*/

.mat-dialog-container .live-stream-popup .mat-button.close {
  top: 1.5rem;
}

.live-stream-popup {
  &.manage-session.half-center {
    label .main-div .inner-content .material-icons {
      font-size: 2.4rem;
      @include margin-padding(right, 1rem, null, null);
      background-color: color("shades", "white");
      border-radius: $circular-radius;
      height: 4rem;
      width: 4rem;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);

      @include breakpoint(x-small) {
        background-color: color("shades", "transparent");
      }
    }

    .session-settings {
      padding: $no-padding;
    }
  }

  .header-div {
    @include margin-padding(null, null, all, 5.6rem);
    background-color: color("primary", "light");
    @include flexbox;
    flex-wrap: wrap;
    @include align-items(center);
    @include justify-content(center);
    text-align: center;

    @include breakpoint(small) {
      @include margin-padding(null, null, all, 5.6rem 1.5rem 4rem);
    }

    h2 {
      margin: $no-margin;
    }

    p {
      @include margin-padding(all, 1rem 0 0, null, null);
    }
  }

  .mat-dialog-content {
    @include margin-padding(all, 0, all, 4.4rem 4.4rem 4.8rem);

    @include breakpoint(small) {
      @include margin-padding(all, 0, all, 4.4rem 1.5rem 4.8rem);
    }
  }

  .form-label-with-right {
    background-color: color("primary", "light");
    @include margin-padding(all, 0 0 1rem, all, 2.5rem);
    border-radius: $default-radius;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);

    @include breakpoint(small) {
      @include margin-padding(all, 0 0 1rem, all, 1.5rem);
    }

    .label-right-side {
      position: static;
    }
  }

  span.detail {
    font-weight: 400;
    display: block;
  }

  .mat-slide-toggle-bar {
    background-color: color("shades", "white");
  }

  .mat-slide-toggle-thumb {
    background-color: color("primary", "light");
  }
}

/*==== Live stream popup css ends====*/

/*==== Access modal css starts====*/

.access-modal-content .heading-section {
  width: 100%;
  display: block;
  text-align: center;
  @include margin-padding(bottom, 2rem, null, null);
}

.access-modal-content .heading-section h2 {
  font-size: 1.8rem;
  @include margin-padding(all, 0.5rem 0 0.8rem, null, null);
}

.access-modal-content .heading-section span {
  font-size: 1.6rem;
  color: #57626f;
}

.access-section-info {
  background: #f5f5f5;
  @include margin-padding(null, null, all, 3rem);
  @include flexbox;
  @include align-items(flex-start);
}

.access-section-info .access-img {
  flex: 1;
  @include justify-content(center);
  display: inherit;
}

.access-section-info .access-info-msg {
  flex: 1;
  font-size: 1.4rem;
}

.access-section-info > div {
  @include margin-padding(null, null, all, 0 1.5rem);
}

.access-section-info .access-info-msg h3 {
  color: #293a43;
  line-height: 1.2;
  font-weight: 600;
  font-size: 2rem;
  position: relative;
  word-break: break-word;
  @include margin-padding(top, 0, null, null);
}

.access-section-info .access-info-msg h3 span {
  position: absolute;
  left: -4rem;
  width: 3rem;
  height: 3rem;
  background: #fff5ed;
  border-radius: 50%;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  font-size: 1.4rem;
  color: color("accent", "base");
}

.access-section-info .access-info-msg p {
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #293a43;
  @include margin-padding(all, 0.8rem 0 0, null, null);
}

.access-section-info.reverse-info {
  background: color("shades", "white");
}

/* responsive */

@include breakpoint(small) {
  .access-section-info {
    flex-direction: column;
    text-align: left;
    @include margin-padding(null, null, all, 1.5rem 0);
  }

  .access-section-info .access-img {
    flex: 1;
    @include justify-content(center);
    display: inherit;
    @include margin-padding(bottom, 2.5rem, null, null);
  }

  .access-section-info .access-info-msg h3 span {
    display: none;
  }

  .access-section-info.reverse-info .access-info-msg {
    order: 2;
  }

  button.modal-close {
    right: 1.5rem;
    top: 0.5rem;
  }

  .access-modal-content .heading-section h2 {
    font-size: 1.6rem;
    @include margin-padding(null, null, all, 0 2rem);
  }

  .access-modal-content .heading-section span {
    font-size: 1.4rem;
    @include margin-padding(null, null, all, 0 2rem);
  }
}

/*==== Access modal css ends====*/

/*==== Upgrade storage css starts ====*/
.upgrade-storage {
  text-align: center;
  @include margin-padding(null, null, bottom, 4rem);

  .storage-icon {
    font-size: 7rem;
    color: color("accent", "base");
    &.style-2 {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      border-radius: $circular-radius;
      height: 8.9rem;
      width: 8.9rem;
      flex: 0 0 8.9rem;
      background-color: color("grey", "lighten-8");
      @include margin-padding(right, 1rem, null, null);
      margin: 2rem auto;
      font-size: 5rem;
    }
  }

  p {
    @include margin-padding(top, 1.5rem, null, null);
    line-height: 1.33;
  }

  .mat-dialog-actions {
    .button {
      @include margin-padding(all, auto, null, null);
    }
  }
}

/*==== Upgrade storage css ends ====*/

/*==== Invoice css starts ====*/
.invoice-popup {
  .mat-dialog-container {
    background-color: color("light", "base");
    @media (max-width:#{$small-screen}) {
      padding: 1rem 1rem !important;
      overflow: auto;
    }
    .mat-dialog-content {
      @media (max-width:#{$small-screen}) {
        padding: 0;
      }
    }
    .heading {
      @media (max-width:#{$small-screen}) {
        font-size: 16px !important;
      }
    }
  }

  .invoice-table-outer {
    // width: 72.7rem;
    width: 100%;
    overflow-x: auto;
  }
}

/*==== Invoice css ends ====*/

/*==== Charges table css starts ====*/
.create-session.charges.half-center {
  max-width: 120rem;

  .mat-table {
    th,
    td {
      width: 25%;
    }
  }

  .mat-list-base {
    @include margin-padding(null, null, all, 0);
    @include flexbox;

    .mat-list-item {
      width: auto;
    }

    .mat-list-item-content {
      @include margin-padding(all, 0 4rem 1rem 0, all, 0);
      font-size: 1.4rem;
      color: color("primary", "light-base");

      .material-icons {
        font-size: 2rem;
        @include margin-padding(right, 0.5rem, null, null);
        color: color("primary", "light-base");
      }
    }
  }

  .mat-table {
    .material-icons {
      font-size: 2.1rem;
      color: color("primary", "light-base");
      @include margin-padding(right, 0.6rem, null, null);

      &:hover {
        color: color("primary", "base");
      }
    }
  }

  &.additional {
    .mat-table {
      th,
      td {
        width: auto;
      }
    }
  }

  &.alert {
    @include margin-padding(null, null, bottom, 2.4rem);
  }
}

/*==== Charges table css ends ====*/

/*==== Facebook connect starts ====*/
.connect-fb-yt {
  .facebook-live-connect {
    .logout-btn {
      @include margin-padding(left, 1rem, null, null);
    }
  }

  p {
    @include margin-padding(top, 0, null, null);
  }

  .fb-heading {
    @include margin-padding(bottom, 0, null, null !important);
  }
}

/*==== Facebook connect ends ====*/

/*==== Payment history css starts ====*/

.payment-history-popup.mat-dialog-content {
  @include margin-padding(all, 0, all, 0);
  overflow: auto !important;

  .scroll-table {
    width: 100%;
    overflow-x: auto;
    // @media (max-width:#{$medium-screen}) {
    //   width: 107rem;
    // }
    .material-icons {
      cursor: pointer;
    }
  }
}

/*==== Payment history css ends ====*/

.facebook-ls-info {
  @include flexbox;
  @include align-items(center);
  border: 1px solid rgba(241, 122, 126, 0.13);
  background-color: rgba(241, 122, 126, 0.07);
  border-radius: $default-radius;
  @include margin-padding(all, 3rem 0 1rem, all, 0.8rem 1.5rem);

  .material-icons {
    @include margin-padding(right, 0.6rem, null, null);
    color: color("accent", "base");
  }

  p {
    @include margin-padding(all, 0, null, null);
    color: color("accent", "base");
    font-size: 1.4rem !important;
    text-align: left;
    font-weight: 500;
  }

  &.expert-tip {
    @include align-items(center);

    p {
      font-size: 1.3rem !important;
      font-weight: 500;
    }
  }
}

.mat-dialog-content .steps {
  position: relative;

  &::before {
    position: absolute;
    left: 1rem;
    width: 0.1rem;
    height: 97%;
    content: "";
    top: 0;
    @include margin-padding(all, auto, null, null);
    background-color: color("grey", "lighten-7");
  }

  p {
    @include margin-padding(all, 1.1rem 0 !important, null, null);
  }

  .steps-inner {
    position: relative;
    @include margin-padding(null, null, left, 3rem);

    .mat-flat-button {
      background-color: #4867aa;
      color: color("shades", "white");

      .material-icons {
        color: color("shades", "white");
      }
    }

    span.number {
      position: absolute;
      height: 2rem;
      width: 2rem;
      text-align: center;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      background-color: color("accent", "base");
      color: color("shades", "white");
      font-size: 12px;
      border-radius: $default-radius;
      font-weight: 500;
      left: 0;
    }

    p.small {
      font-size: 1.4rem;
    }
  }
}

.getting-ready-popup {
  @include margin-padding(null, null, all, 3rem 0);
}

body .youtube-waiting-popup .mat-dialog-container {
  @include margin-padding(null, null, all, 3rem 3.5rem);
}

.youtube-waiting {
  .text-center {
    background-color: color("grey", "lighten-8");
    border-radius: $default-radius;
    @include margin-padding(bottom, 2rem, all, 3rem);

    .material-icons {
      color: color("shades", "white");
    }

    p {
      @include margin-padding(all, 0.7rem 0 1.5rem, null, null);
    }
  }

  .font-bold {
    color: color("grey", "lighten-1");
  }

  .steps-inner {
    @include margin-padding(bottom, 1.2rem, null, null);
  }
}

.close-with-btn {
  @include flexbox;
  @include align-items(center);
  @include justify-content(flex-end);
  width: 100%;
  position: relative;
  .download-btn {
    margin: 0 0rem 0 0;
  }
  .close {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    margin: 0 !important;
  }
}

.cdk-overlay-pane.download-popup {
  width: 100%;
  max-width: 70rem !important;
  width: 600px;
}

.check-with-info {
  margin: 0.5rem 0 0;
  display: block;
  .material-icons {
    color: #f17a7e;
    margin: 0 0 0 0.6rem;
  }
  .mat-checkbox-label {
    display: flex;
  }
}

.select-with-icon {
  .mat-icon {
    position: absolute;
    right: 1.2rem;
    top: 3.2rem;
    display: block;
    // font-size: 2rem;
    z-index: 11;
    opacity: 1;
    visibility: visible;
    color: #bbbbbb !important;
    pointer-events: none;
  }
  .mat-select-arrow-wrapper {
    display: none !important;
  }
}

.occur-time {
  position: absolute;
  margin: 1px 0 0;
  font-size: 1.3rem !important;
}

body .introjs-tooltip-header {
  h1.introjs-tooltip-title {
    font-size: 2rem;
    margin: 0;
    word-break: break-word;
  }
  .introjs-skipbutton {
    position: absolute;
    right: 1rem;
    top: 0;
    @include abs-position(0, 1rem, auto, auto);
  }
}



.banner-secondary-logo-wrap{
  p.banner-quote{
    z-index: 1;
  }
}

.green-room #conference{
  z-index: 1;
}

.minimul-block{
  z-index: 2;
}

.primaryLogoImg {
  z-index: 2;
}

.screen-fit-area{
  position: absolute;
  height: 100%;
  width: calc(100% - 0px);
  left: 0px;
  top: 0px;
  .screen-fit-area-inner{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
