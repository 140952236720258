.button,
.mat-button {
  font-size: 1.6rem;
  text-transform: capitalize;

  &.orange-text {
    color: color('accent', 'base');

    .material-icons {
      color: color('accent', 'base');
    }
  }
}

.button-small {
  font-size: 1.4rem;
  padding: $small-button-padding !important;
}

.button-medium {
  font-size: 1.6rem;
  padding: $medium-button-padding !important;
}

.button-large {
  font-size: 1.8rem;
  padding: $large-button-padding !important;
}

.icon-btn {
  .material-icons {
    @include margin-padding(right, .2rem, null, null);
    font-size: 1.8rem;
  }

  svg {
    @include margin-padding(right, .2rem, null, null);
  }
}

.button {
  font-weight: 600;
  border-radius: $default-radius !important;
  line-height: unset !important;
}

.button-small.filled,
.button-medium.filled,
.button-large.filled {
  background-color: color('accent', 'base');
  // @include box-shadow($box-shadow-orange);
  transition: all .3s ease-out;
  border: .1rem solid color('accent', 'base');

  &:hover {
    background-color: color('shades', 'transparent');
    color: color('accent', 'base');
    border: .1rem solid color('accent', 'base');
    box-shadow: none;

    .material-icons {
      color: color('accent', 'base');
    }
  }

  &:focus {
    background-color: color('accent', 'base');
    border: .1rem solid color('accent', 'base');
    color: color('shades', 'white');

    .material-icons {
      color: color('shades', 'white');
    }
  }
}

.button-small.outline,
.button-medium.outline,
.button-large.outline {
  background-color: color('shades', 'transparent');
  color: color('accent', 'base');
  border: .1rem solid color('accent', 'base');
  box-shadow: none;
}

.button-small.pressed,
.button-medium.pressed,
.button-large.pressed {
  background-color: color('accent', 'dark');
  box-shadow: none;
}

body .mat-mini-fab {
  box-shadow: none !important;
  @include abs-position (3.2rem, 0, auto, auto);
  height: 5rem;
  width: 5rem;
  border-radius: $default-radius;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: color('accent', 'base');
}
