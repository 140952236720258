// Utility Color Classes
//.success {
//
//}
// Google Color Palette defined: http://www.google.com/design/spec/style/color.html
$primary: ("base": #343434,
  "light-base": #88a4a7,
  "light": #FFEEE2);

$accent: ("base": #F17A7E,
  "dark": #da6367);

$light: ("base": #F9F9F9);
$red: ("base": #C20209);


$grey: ("base": #000000,
  "lighten-1": #272727,
  "lighten-2": #434343,
  "lighten-3": #6a6a6a,
  "lighten-4": #787878,
  "lighten-5" : #bdbdbd,
  "lighten-6" : #dadada,
  "lighten-7": #e5e5e5,
  "lighten-8": #f4f4f4,
  "lighten-9": #f8f8f8);


$shades: ("black": #000000,
  "white": #ffffff,
  "transparent": transparent);

$colors: ("primary": $primary,
  "accent": $accent,
  "light": $light,
  "red": $red,
  "shades": $shades,
  "grey": $grey) !default;

// Color Classes
@each $color_name,
$color in $colors {

  @each $color_type,
  $color_value in $color {
    @if $color_type=="base" {
      .#{$color_name} {
        background-color: $color_value !important;
      }

      .#{$color_name}-text {
        color: $color_value !important;
      }
    }

    @else if $color_name !="shades" {
      .#{$color_name}.#{$color_type} {
        background-color: $color_value !important;
      }

      .#{$color_name}-text.text-#{$color_type} {
        color: $color_value !important;
      }
    }
  }
}

// Shade classes
@each $color,
$color_value in $shades {
  .#{$color} {
    background-color: $color_value !important;
  }

  .#{$color}-text {
    color: $color_value !important;
  }
}

// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)
@function color($color, $type) {
  @if map-has-key($colors, $color) {
    $curr_color: map-get($colors, $color);

    @if map-has-key($curr_color, $type) {
      @return map-get($curr_color, $type);
    }
  }

  @return null;
}
