.user-home-info {
  @include flexbox;
  @include align-items(center);
  padding: 1rem 0;
  .img-text {
    height: 5rem;
    width: 5rem;
    min-width: 5rem;
    border-radius: 100%;
    background-color: color("accent", "base");
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    color: color("shades", "white");
    margin: 0 1.5rem 0 0;
    span {
      font-size: 1.8rem;
      text-transform: uppercase;
      display: block;
      font-weight: 600;
      color: color("shades", "white");
    }
  }
  .info {
    h4 {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin: 0;
      color: color("grey", "lighten-1");
      word-break: break-word;
      @media (max-width:#{$large-desktop-middle-down}) {
        font-size: 2rem;
        line-height: 2.4rem;
      }
      @media (max-width:#{$large-screen}) {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
    p {
      margin: 0;
      font-size: 2.2rem;
      line-height: 2.6rem;
      color: color("grey", "lighten-1");
      word-break: break-word;
      @media (max-width:#{$large-desktop-middle-down}) {
        font-size: 1.8rem;
        line-height: 2rem;
      }
      @media (max-width:#{$large-screen}) {
        font-size: 1.6rem;
        line-height: 1.8rem;
      }
      strong {
        font-weight: 500;
      }
    }
  }
}

.new-filter-heading {
  .search {
    margin: 0 0 0 1.5rem;
    @media (max-width:#{$large-desktop-middle-down}) {
      max-width: 20rem;
    }
  }
  .right-side {
    @include justify-content(flex-end);
    @media (max-width:#{$large-desktop-middle-down}) {
    }
  }
}

.mat-drawer-container.full-width-container.close {
padding-left: 9rem !important;
@media only screen and (max-width:1279px){
    padding-left: 0px !important;
  }
}

.plan-expiry-reminder.close {
  width: calc(100% - 80px) !important;
 
  @include breakpoint(xxxxxlarge) {
    width: calc(100% - 80px);
  }

  @include breakpoint(x2large) {
    width: 100%;
  }

  @media (max-width:#{$large-screen}) {
    // padding-left: 0px !important;
    width: 100% !important;
  }
}




.menu-collapse-area {
  // height: 20%;
  text-align: left;
  @include flexbox;
  @include align-items(center);
  justify-content: flex-end;
  margin: 1rem 0 0;
  @media only screen and (max-width:1279px) {
    height: 30px;
    div {
      // display: none;
    }
  }

  @media (max-width:#{$small-screen}) {
    // height: 30px;
    div {
      display: none;
    }
  }

  &.open {
    justify-content: center;
    .menu-lines {
      display: block;
    }
    .back-arrow {
      display: none;
    }
  }
  .material-icons {
    color: #fff;
    cursor: pointer;
  }
  .menu-lines {
    display: none;
  }
  img {
    cursor: pointer;
  }
}

.sidebar {
  @include box-shadow($box-shadow-white-container);
  &.close {
    max-width: 140px;
    .ng-scroll-content {
      padding: 2rem 1rem !important;
    }
    .mat-list .mat-list-item {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      a {
        padding: 1.5rem 1rem;
        width: 54px;
        @include justify-content(center);
        .menu-text {
          display: none;
        }
        span {
          margin: 0;
        }
      }
    }
  }
  .main-logo-image {
    text-align: center;
    display: block;
    height: 3.9rem;
    // width: 9.3rem;
    @include margin-padding(all, auto, null, null);
  }

 

  .user-image {
    height: 8rem;
    width: 8rem;
    @include margin-padding(all, 0rem auto 0rem, null, null);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(medium) {
      height: 7rem;
      width: 7rem;
      @include margin-padding(all, 2.5rem auto 0.5rem, null, null);
    }
  }

  .top-details {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    height: max-content;
    // border-bottom: .1rem solid color('grey', 'lighten-1');
    @include margin-padding(null, null, all, 0.5rem 0 1rem);
    .user-name {
      font-weight: 500;
      @include margin-padding(all, 0, null, null);
      text-transform: capitalize;
      color: color("shades", "white");
      font-size: 1.4rem;
    }
  }

  .plan-details {
    @include margin-padding(bottom, 1.5rem null, all, 2rem 2rem 2.5rem);
    background-color: color("light", "base");

    .storage,
    .time {
      p {
        @include margin-padding(all, 1rem auto 0, null, null);
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }

  .mat-drawer-inner-container {
    @include flexbox;
    @include flex-wrap(wrap);

    .ng-scrollbar {
      width: 100%;

      .ng-scroll-content {
        @include margin-padding(null, null, all, 2rem 3rem 0);
        @include flexbox;
        @include flex-wrap(wrap);
        height: 100vh;
      }
    }
  }

  .plan-details {
    .button {
      @include margin-padding(top, 1.5rem, null, null);
    }
  }

  .navigation-links {
    width: 100%;

    .mat-list {
      @include margin-padding(top, 0, null, null);

      @include breakpoint(medium) {
        @include margin-padding(top, 1rem, null, null);
      }
    }
  }

  .mat-list {
    .mat-list-item {
      height: auto;
      margin-bottom: 1rem;

      &.logout-mobile {
        display: none;

        @include breakpoint(x2large) {
          display: block;
        }
      }

      a {
        color: color("grey", "lighten-3");
        @include flexbox;
        align-items: center;
        @include margin-padding(null, null, all, 1.5rem 1.5rem 1.5rem 6.5rem);
        transition: all 0.3s;
        font-size: 1.5rem;
        @include flexbox;
        @include align-items(center);
        @include justify-content(flex-start);
        &.active,
        &:hover {
          background-color: color("accent", "base");
          color: color("shades", "white");
          border-radius: $default-radius;
          @include box-shadow($box-shadow-primary);

          .material-icons {
            color: color("shades", "white");
          }

          svg {
            path {
              fill: color("shades", "white");
            }
          }
        }
      }

      .material-icons {
        @include margin-padding(right, 0.8rem, null, null);
        color: color("grey", "lighten-3");
        font-size: 2rem;
      }

      svg {
        width: 2.4rem;
        color: 2.4rem;
        @include margin-padding(right, 1.4rem, null, null);

        path {
          fill: color("grey", "lighten-3");
        }
      }

      // .mat-list-item-content {
      //   padding: $no-padding;
      // }
    }
  }

  .log-out {
    @include margin-padding(bottom, 0.5rem, null, null);
    align-self: flex-end;
    width: 100%;



    @include breakpoint(x2large) {
      display: none;
    }

    @include breakpoint(medium) {
      @include margin-padding(bottom, 2.5rem, null, null);
    }
  }
}

//  updated menu


.mat-drawer {
  width: 22rem !important;
}

.sidebar .user-image {
  height: 5rem;
  width: 5rem;
}

.sidebar .mat-list .mat-list-item a {
  // padding: 1rem 1.5rem 1rem 2rem !important;
  padding: 1.1rem !important;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0px 14px !important;
}

.sidebar.close {
  max-width: 8rem !important;

  .user-image {
    height: 3.5rem;
    width: 3.5rem;
  }
  .mat-list .mat-list-item a {
    padding: 0.8rem 1rem !important;
    width: 37px;
    margin-top: 1rem;
  }

}


//tooltip

.mat-tooltip.ng-tns-c167-137.ng-trigger.ng-trigger-state.custom-tooltip {
  background-color:color('accent', 'base');
  border: none;
  color: #fff !important;
  font-size: 1.2rem !important;
}

.sidebar.close .mat-tooltip.custom-tooltip {
  display: none;
}

