img {
  max-width: 100%;
  height: auto;
}

.no-padding {
  padding: $no-padding;
}

.no-padding-left {
  padding: $no-padding;
}

.no-padding-right {
  padding: $no-padding;
}

.no-margin {
  margin: $no-margin;
}

.mt-0 {
  @include margin-padding(top, 0 !important, null, null);
}

.mt-1 {
  @include margin-padding(top, 1rem !important, null, null);
}

.mt-2 {
  @include margin-padding(top, 2rem !important, null, null);
}

.mt-3 {
  @include margin-padding(top, 2.5rem !important, null, null);
}

.mt-4 {
  @include margin-padding(top, 3rem !important, null, null);
}

.mt-5 {
  @include margin-padding(top, 0.5rem !important, null, null);
}

.mb-0 {
  @include margin-padding(bottom, 0 !important, null, null);
}

.mb-1 {
  @include margin-padding(bottom, 1rem !important, null, null);
}

.mb-2 {
  @include margin-padding(bottom, 2rem !important, null, null);
}

.ml-1 {
  @include margin-padding(left, 1rem !important, null, null);
}

.mr-1 {
  @include margin-padding(right, 1rem !important, null, null);
}

.ml-2 {
  @include margin-padding(left, 2rem !important, null, null);
}

.no-border {
  border: 0 !important;
}

.label,
label {
  opacity: 1 !important;

  .required {
    color: color("accent", "base");
    display: inline-block;
    @include margin-padding(left, 0.5rem, null, null);
  }
}

textarea,
.mat-form-field textarea.mat-input-element {
  height: 12rem;
  &.medium {
    height: 6rem;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: color("primary", "light-base") !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: color("primary", "light-base") !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: color("primary", "light-base") !important;
}

.justify-content-center {
  @include justify-content(center !important);
}

.justify-content-between {
  @include justify-content(space-between !important);
}

.justify-content-flex-end {
  @include justify-content(flex-end !important);
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.float-right {
  float: right !important;
}

.w-100 {
  width: 100% !important;
}

.no-shadow {
  box-shadow: none !important;
}

.tab-block {
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
}

.flex {
  @include flexbox;
}

.material-icons.facebook {
  color: #4867aa;
}

.container {
  width: 100%;
  @include margin-padding(all, auto, null, null);
  position: relative;
}

.container-box {
  background-color: color("shades", "white");
  @include box-shadow($box-shadow-white-container);
  border-radius: $default-radius;
}

.primary {
  background-color: color("primary", "base");
}

.primary-light {
  background-color: color("primary", "light");
}

.orange {
  background-color: color("accent", "base");
}

.orange-dark {
  background-color: color("accent", "dark");
}

.light {
  background-color: color("light", "base");
}

.text-primary {
  color: color("primary", "base");
}

.text-primary-light {
  color: color("primary", "light");
}

.text-orange {
  color: color("accent", "base");
}

.text-orange-dark {
  color: color("accent", "dark");
}

.text-light {
  color: color("light", "base");
}

.align-items-center {
  @include align-items(center);
}

input,
a,
span {
  -webkit-tap-highlight-color: transparent;
}

.full-width-container {
  height: 100%;
}

.container {
  max-width: 144rem;
  width: 100%;
  @include margin-padding(all, auto, null, null);
}

.font-medium {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.accent-text {
  color: color("accent", "base");
}

/*==== Snack bar css starts ====*/
.snack-error {
  &.mat-snack-bar-container {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
    box-shadow: none;

    button span {
      color: #721c24;
    }
  }
}

.snack-success {
  &.mat-snack-bar-container {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    box-shadow: none;

    button span {
      color: #155724;
    }
  }
}

.snack-warning {
  &.mat-snack-bar-container {
    color: color("shades", "white");
    background-color: color("accent", "base");
    box-shadow: none;

    button span {
      color: color("shades", "white");
    }
  }
}

/*==== Snack bar css ends ====*/

.logo-image {
  height: 3.9rem;
  width: 9.3rem;
  position: relative;

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include margin-padding(all, auto, null, null);
    height: 100%;
    width: auto;
  }
}

.medium-block {
  @include breakpoint(medium) {
    display: block;
  }
}

.orange-color {
  color: color("accent", "base");
  font-size: 1.4rem;

  .mat-icon {
    color: color("accent", "base") !important;
  }
}

.mat-chip-list {
  input.mat-input-element {
    @include margin-padding(null, null, all, 0);
    height: auto;
    font-size: 1.4rem;
    border-radius: 0;

    &:focus,
    &:hover {
      border: 0 !important;
    }
  }

  .mat-chip-list-wrapper {
    @include margin-padding(all, 0, null, null);

    .mat-standard-chip {
      @include margin-padding(all, 0 0.4rem 0 0, all, 0.2rem 1rem);
      text-overflow: ellipsis;
      word-break: break-all;
      height: auto;
      min-height: 2.6rem;
      font-size: 1.3rem;
      background-color: color("grey", "lighten-7");
      text-transform: capitalize;
      max-width: 14rem;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      line-height: 2.2rem;

      &.font-bold {
        font-size: 1.5rem;
      }
    }
  }
}

.time-input {
  input {
    cursor: pointer;
  }
}

.timepicker-dial__control {
  text-align: center !important;

  &:focus {
    outline: 0;
    background-color: color("accent", "dark") !important;
  }
}

.StripeElement {
  border: 0.1rem solid color("grey", "lighten-7") !important;
  height: 4.5rem;
  width: 100%;
  border-radius: 1rem;
  position: static !important;
  @include margin-padding(all, 1rem 0, all, 1.3rem 1.5rem);
}

.cursor-default {
  cursor: default !important;
}

/*==== Active check icon css starts ====*/
.active-check {
  border: 1px solid color("accent", "base");
  position: relative;

  &::before {
    @include abs-position(-0.8rem, -0.8rem, auto, auto);
    height: 2rem;
    width: 2rem;
    background-color: color("accent", "base");
    content: "done";
    font-family: material icons;
    text-align: center;
    font-size: 1.6rem;
    border-radius: $circular-radius;
    color: color("shades", "white");
    line-height: 1.8rem;
  }
}

/*==== Active check icon css ends ====*/

/*==== No record found css starts ====*/

.no-record {
  text-align: center;

  .no-record-image {
    width: 38rem;
    @include margin-padding(all, 2.5rem auto 0, null, null);

    @include breakpoint(x-small) {
      width: 28rem;
      @include margin-padding(top, 0, null, null);
    }
  }

  h2 {
    @include margin-padding(all, 1rem 0 0 !important, null, null);
  }

  p {
    @include margin-padding(top, 1rem !important, null, null);
    font-size: 1.6rem;

    @include breakpoint(x-small) {
      @include margin-padding(top, 0.5rem !important, null, null);
    }

    span {
      color: color("grey", "lighten-1");
      font-weight: 600;
    }
  }
}

/*==== No record found css ends ====*/

.mat-menu-panel {
  min-height: auto !important;

  .mat-menu-content {
    padding: 0 !important;
  }
}

/*==== Custom file upload css starts ====*/
.preview-img {
  width: 25.6rem;
  height: 14.4rem;
  position: absolute;
  border: 1px solid color("grey", "lighten-7");
  border-radius: $default-radius;
  overflow: hidden;
  text-align: center;
  // background-image: url("../images/default-thumbnail.jpg");
  background-size: contain;
}

.upload-text {
  position: absolute;
  bottom: 30%;
  left: 0;
  right: 0;
  top: auto;
  margin: auto;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  opacity: 0;
}

.custom-file-input {
  color: transparent;
  margin-top: 5px;
  position: relative;
  width: 260px;
  height: 150px;
  margin: 0;

  &:focus {
    outline: none;
  }
}

.thumbnail-outer {
  position: relative;
  display: inline-block;

  &:hover {
    .custom-file-input::before,
    .custom-file-input::after,
    .upload-text {
      opacity: 1;
    }
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 15px;
  height: 144px;
  width: 256px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0;
  opacity: 0;
}

.custom-file-input::after {
  content: "add_photo_alternate";
  color: #fff;
  left: 0;
  cursor: pointer;
  position: absolute;
  font-family: "Material Icons";
  right: 0;
  margin: auto;
  width: max-content;
  font-size: 28px;
  top: 30%;
  opacity: 0;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

label.file-upload input[type="file"] {
  position: absolute;
  top: -100rem;
}

.file-upload {
  display: inline-block;
  color: color("accent", "base") !important;
  cursor: pointer;
  font-size: 1.4rem !important;
}

/*==== Custom file upload css ends ====*/

/*==== Progress bar loader css starts ====*/
.circular-progress {
  position: relative;
  display: inline-block;
  width: 8.5rem;
  height: auto;
  border-radius: $circular-radius;

  svg {
    transform: rotate(270deg);
  }

  circle {
    stroke-width: 5;
    fill: none;
    stroke-linecap: round;

    &:nth-of-type(1) {
      stroke: color("primary", "light");
    }

    &:nth-of-type(2) {
      stroke: color("accent", "base");
      stroke-dasharray: 251.4285714286;
      stroke-dashoffset: 75.4285714286;
    }
  }

  .pct {
    position: absolute;
    top: 0;
    left: 0;
    transform: inherit;
    font-weight: 600;
    @include margin-padding(all, auto, null, null);
    height: 100%;
    width: 100%;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);

    &.days {
      font-weight: 400;
      font-size: 1.2rem;
    }
  }
}

.main-loader {
  left: 0% !important;
  @include margin-padding(left, 0, null, null);
  position: fixed !important;
  top: 0% !important;
  @include margin-padding(top, 0, null, null);
  width: 100%;
  z-index: 999999999 !important;
  background: rgba(255, 255, 255, 0.6);
  height: 100%;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  width: 10rem;
  height: 10rem;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  position: absolute;
  top: 0;
  left: 0;
  @include margin-padding(all, auto, null, null);
  right: 0;
  bottom: 0;
}

.loader-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes color {
  0% {
    stroke: color("accent", "dark");
  }

  40% {
    stroke: color("accent", "dark");
  }

  66% {
    stroke: color("accent", "dark");
  }

  80%,
  90% {
    stroke: color("accent", "dark");
  }
}

@keyframes color {
  0% {
    stroke: color("accent", "dark");
  }

  40% {
    stroke: color("accent", "dark");
  }

  66% {
    stroke: color("accent", "dark");
  }

  80%,
  90% {
    stroke: color("accent", "dark");
  }
}

.loader mat-spinner.mat-spinner.mat-progress-spinner.mat-primary.mat-progress-spinner-indeterminate-animation {
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  @include margin-padding(all, auto, null, null !important);
  width: 10rem;
  height: 10rem;
  position: absolute;
}

/*==== Progress bar css ends ====*/

/*==== Date picker css starts ====*/
.date-picker {
  .mat-form-field-suffix {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4.5rem;
    top: 3rem;

    .cal-ico {
      font-size: 2.4rem;
      color: color("accent", "base");
      right: 0;
      width: 100%;
      height: 4.5rem;
      top: 0;
      position: absolute;
      text-align: right;

      .mat-icon-button {
        width: 100% !important;
        text-align: right;
        height: 4.5rem !important;
        border-radius: 0;

        .mat-button-wrapper {
          svg {
            @include margin-padding(all, 0, null, null);
            width: 2.4rem;
            height: 2.4rem;
            fill: #bbbbbb;
            position: absolute;
            left: auto;
            top: 0;
            bottom: 0;
            margin: 1.5rem 0;
            right: 1.2rem;

            // @include breakpoint(small) {
            //   position: relative;
            //   top: -.5rem;
            //   width: 2rem;
            //   height: 2rem;
            // }
          }
        }
      }
    }
  }
}

/*==== Date picker css ends ====*/

.form-label-with-right {
  position: relative;
  @include margin-padding(all, 1rem 0 -1.5rem 0, null, null);
  z-index: 1;
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
}

.color-picker .box {
  height: auto;
  width: auto;
}

/*==== Blink animation css starts ====*/

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/*==== Blink animation css ends ====*/

.container-box-padding {
  @include margin-padding(all, 1rem 0 1rem 0, all, 1rem 2.5rem 1rem);

  @include breakpoint(xxxxxlarge) {
    @include margin-padding(all, 1rem 0 1rem 0, all, 1rem 2.5rem 1rem);
  }

  @include breakpoint(small) {
    // @include margin-padding(all, 1rem 0 3rem 0, all, 1.5rem);

    &.payment-info {
      @include margin-padding(all, 1rem 0 1.5rem 0, all, 1.5rem);
    }
  }
}

.facebook-btn {
  color: #4867aa !important;
  border-color: #4867aa !important;

  .material-icons {
    color: #4867aa !important;
  }
}

.youtube-btn {
  color: #ff0000 !important;
  border-color: #ff0000 !important;
}

/*==== Form no label css starts ====*/
.no-label {
  &.mat-form-field-appearance-legacy .mat-form-field-label {
    top: 3rem;
  }

  .mat-form-field-infix {
    border: none;
  }

  &.background-accent .mat-select {
    background-color: color("accent", "base");

    & + .mat-form-field-label-wrapper .mat-form-field-label {
      color: color("shades", "white") !important;
    }

    .mat-select-arrow,
    .mat-select-value-text {
      color: color("shades", "white") !important;
    }
  }

  &.date-picker .mat-form-field-suffix .cal-ico {
    top: -2.8rem;
  }
}

/*==== Form no label css ends ====*/

.with-rise-hand {
  @include flexbox;
  @include align-items(center);
  .hand {
    background: none !important;
    color: #ffb100 !important;
    font-size: 1.4rem !important;
    width: auto !important;
    margin: 0 0.7rem 0 0 !important;
    height: auto !important;
  }
}

.uploading-with-progress {
  @include flexbox;
  @include align-items(center);
  width: 100%;
  .uploading-text {
    min-width: 100px;
  }
  .mat-progress-bar {
    width: 100% !important;
    .mat-progress-bar-fill {
      &:after {
        background-color: color("accent", "base");
      }
    }
  }
}

// payment history

body .verticle-table {
  @media (max-width:#{$medium-screen}) {
    width: 100% !important; //$small-screen
  }
  thead {
    @media (max-width:#{$medium-screen}) {
      border: none; 
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
  tr {
    @media (max-width:#{$medium-screen}) {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
      height: auto !important;
    }
    td {
      @media (max-width:#{$medium-screen}) {
        display: block;
        text-align: right !important;
        padding: 10px !important;
      }
      .material-icons {
        @media (max-width:#{$medium-screen}) {
          opacity: 0.5;
          font-size: 2rem;
          margin: 0 0 0 0.5rem !important;
        }
      }
      &:before {
        @media (max-width:#{$medium-screen}) {
          content: attr(data-label);
          float: left;
          font-weight: 700;
          text-transform: uppercase;
          color: color("primary", "base");
        }
      }
    }
  }
}

body .payment-history-popup {
  .mat-dialog-container {
    @media (max-width:#{$medium-screen}) {
      overflow: auto;
      padding: 2.5rem 1.5rem !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

// proceed-popup-innersection

.ui-kit-heading-custom {
  margin-top: 2rem !important;
}

.proceed-popup-innersection {
  width: 100%;
  height: 100%;
  background-color: color("shades", "white");
  border-radius: 1.5rem;
  padding: 3rem 0;

  .proceed-popup-innersection-mic-img {
    @include flexbox;
    @include justify-content(center);
    // padding: 6.4rem 0 1.5rem 0;
  }
  .proceed-popup-innersection-heading {
    color: color("accent", "base");
    text-align: center;
    // padding-bottom: 0.5rem;
  }
  .procced-button {
    @include flexbox;
    @include justify-content(center);

    button {
      @include align-items(center);
      padding: 1.2rem 3.7rem !important;
    }
  }
}

//empty share recording popup

.empty-Share-recording-pop-up {
  width: 100%;
  height: 100%;
  background-color: color("shades", "white");
  border-radius: 1.5rem;
  padding: 0 2rem;
  @include flexbox;
  flex-direction: column;
  @include justify-content(space-between);

  .empty-share-recording-screen {
    color: color("accent", "base");
  }

  .empty-recording-screen-img {
    @include flexbox;
    @include justify-content(space-around);
  }

  .empty-Share-recording-pop-up-bottom {
    margin-top: 2rem;
    @include flexbox;
    justify-content: flex-end;

    button {
      @include align-items(center);
      padding: 1.2rem 3.7rem !important;
    }
  }
}
