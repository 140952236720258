// ==========================================================================
// Variables
// ==========================================================================
//
// Table of Contents:
//
//  1. Colors
//  2. Badges
//  3. Buttons
//  4. Cards
//  5. Carousel
//  6. Collapsible
//  7. Chips
//  8. Date + Time Picker
//  9. Dropdown
//  10. Forms
//  11. Global
//  12. Grid
//  13. Navigation Bar
//  14. Side Navigation
//  15. Photo Slider
//  16. Spinners | Loaders
//  17. Tabs
//  18. Tables
//  19. Toasts
//  20. Typography
//  21. Footer
//  22. Flow Text
//  23. Collections
//  24. Progress Bar

$default-radius: 1rem;
$rounded-radius: 2rem;
$circular-radius: 50%;

$box-shadow-primary: 1rem 2rem 3rem rgba(74, 97, 99, 0.18);
$box-shadow-orange: 1rem 2rem 3rem rgba(249, 166, 108, 0.2);
$box-shadow-white-container: 1rem 2rem 3rem rgba(74, 97, 99, 0.05);

$no-margin: 0 !important;
$no-padding: 0 !important;


// 1. Colors
// ==========================================================================

// $primary-color: color("blue", "base") !default;
// $primary-color-light: lighten($primary-color, 15%) !default;
// $primary-color-dark: color("plum-purple", "darken-1") !default;

// $secondary-color: color("green", "base") !default;
// $success-color: color("green", "base") !default;
// $error-color: color("red", "base") !default;
// $link-color: color("blue", "lighten-1") !default;
// $border-color: color("grey", "border") !default;


// 2. Badges
// ==========================================================================

// $badge-bg-color: $secondary-color !default;
$badge-height: 22px !default;


// 3. Buttons
// ==========================================================================

// Shared styles
$button-border: none !default;
// $button-background-focus: lighten($secondary-color, 4%) !default;
$button-font-size: 1.6rem !default;
$button-icon-font-size: 18px !default;
$button-height: 30px !default;
$button-padding: 0 1.071em !default;
$button-radius: 1rem !default;
$button-radius-default: 1rem;
$small-button-padding: .8rem 1.6rem;
$medium-button-padding: 1.2rem 2rem;
$large-button-padding: 1.5rem 3.2rem;

// Disabled styles
$button-disabled-background: #DFDFDF !default;
$button-disabled-color: #9F9F9F !default;

// Raised buttons
$button-raised-background: color("blue", "base") !default;
// $button-raised-background-hover: lighten($button-raised-background, 5%) !default;
$button-raised-color: #fff !default;

// Large buttons
$button-large-font-size: 1em !default;
$button-large-icon-font-size: 24px !default;
$button-large-height: $button-height * 1.5 !default;

// Flat buttons
$button-flat-color: #343434 !default;
$button-flat-disabled-color: lighten(#999, 10%) !default;

// Floating buttons
// $button-floating-background: $secondary-color !default;
// $button-floating-background-hover: $button-floating-background !default;
$button-floating-color: #fff !default;
$button-floating-size: 40px !default;
$button-floating-large-size: 56px !default;
$button-floating-radius: 50% !default;


// 4. Cards
// ==========================================================================

$card-padding: 17px 25px 20px !default;
$card-bg-color: #fff !default;
$card-link-color: color('accent', 'base') !default;
$card-link-color-light: lighten($card-link-color, 20%) !default;


// 5. Carousel
// ==========================================================================

$carousel-height: 400px !default;
$carousel-item-height: $carousel-height / 2 !default;
$carousel-item-width: $carousel-item-height !default;


// 6. Collapsible
// ==========================================================================

$collapsible-height: 3rem !default;
$collapsible-line-height: $collapsible-height !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;


// 7. Chips
// ==========================================================================

$chip-bg-color: #e4e4e4 !default;
$chip-border-color: #9e9e9e !default;
$chip-selected-color: #26a69a !default;
$chip-margin: 5px !default;


// 8. Date + Time Picker
// ==========================================================================

$datepicker-display-font-size: 2.8rem;
$datepicker-calendar-header-color: #999;
$datepicker-weekday-color: rgba(0, 0, 0, .87) !default;
// $datepicker-weekday-bg: darken($secondary-color, 7%) !default;
// $datepicker-date-bg: $secondary-color !default;
$datepicker-year: rgba(255, 255, 255, .7) !default;
$datepicker-focus: rgba(0, 0, 0, .05) !default;
// $datepicker-selected: $secondary-color !default;
// $datepicker-selected-outfocus: desaturate(lighten($secondary-color, 35%), 15%) !default;
// $datepicker-day-focus: transparentize(desaturate($secondary-color, 5%), .75) !default;
$datepicker-disabled-day-color: rgba(0, 0, 0, .3) !default;

$timepicker-clock-color: rgba(0, 0, 0, .87) !default;
$timepicker-clock-plate-bg: #eee !default;


// 9. Dropdown
// ==========================================================================

$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
// $dropdown-color: $secondary-color !default;
$dropdown-item-height: 50px !default;


// 10. Forms
// ==========================================================================

// Text Inputs + Textarea
$input-height: 44px !default;
$input-border-color: #ceced2 !default;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
// $input-error-color: $error-color !default;
// $input-success-color: $success-color !default;
// $input-focus-color: $secondary-color !default;
$input-font-size: 1rem !default;
$input-margin-bottom: 8px;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 0 15px !default;
$input-transition: all .3s !default;
$label-font-size: 1.5rem !default;
$input-disabled-color: #ceced2 !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-border-color !default;
// $input-invalid-border: 1px solid $input-error-color !default;
$placeholder-text-color: #a5a4a9 !default;

// Radio Buttons
$radio-fill-color: color("blue", "base") !default;
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
$range-height: 14px !default;
$range-width: 14px !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.90) !default;
// $select-focus: 1px solid lighten($secondary-color, 47%) !default;
$select-option-hover: rgba(0, 0, 0, .08) !default;
$select-option-focus: rgba(0, 0, 0, .08) !default;
$select-option-selected: rgba(0, 0, 0, .03) !default;
$select-padding: 5px !default;
$select-radius: 1rem !default;
$select-disabled-color: rgba(0, 0, 0, .3) !default;

// Switches
$switch-bg-color: $radio-fill-color !default;
// $switch-checked-lever-bg: desaturate(lighten($switch-bg-color, 25%), 25%) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: rgba(0, 0, 0, .38) !default;
$switch-radius: 15px !default;


// 11. Global
// ==========================================================================

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen-main: 767px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$large-screen-medium: 1299px !default;
$large-desktop-down: 1280px !default;
$large-desktop-up: 1366px !default;
$large-desktop-up-down: 1365px !default;
$large-desktop-middle-down: 1400px !default;
$large-desktop-middle-down-center: 1460px !default;
$large-desktop-down-center: 1570px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// 1200 - 1299
$medium-desktop-screen: "only screen and (min-width : #{$large-screen}) and (max-width : #{$large-screen-medium})" !default;

$large-desktop-up-down: "only screen and (max-width : #{$large-desktop-up-down})" !default;
$large-desktop-middle-down: "only screen and (max-width : #{$large-desktop-middle-down})" !default;
$large-desktop-middle-down-center: "only screen and (max-width : #{$large-desktop-middle-down-center})" !default;

// 1280 To 1366
$medium-desktop: "only screen and (min-width : #{$large-desktop-down}) and (max-width : #{$large-desktop-up})" !default;
// Max -width 1280
$small-desktop-down: "only screen and (max-width : #{$large-desktop-down})" !default;
// Max -width 1280
$large-desktop-down-center: "only screen and (max-width : #{$large-desktop-down})" !default;




// 12. Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 3rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;


// 13. Navigation Bar
// ==========================================================================

$navbar-height: 42px !default;
$navbar-line-height: $navbar-height !default;
$navbar-height-mobile: 42px !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
$navbar-font-size: 0.929em !default;
$navbar-font-color: #ab9ba9 !default;
$navbar-brand-font-size: 2.1rem !default;

// 14. Side Navigation
// ==========================================================================

$sidenav-font-size: 14px !default;
$sidenav-font-color: rgba(0, 0, 0, .87) !default;
$sidenav-bg-color: #fff !default;
$sidenav-padding: 16px !default;
$sidenav-item-height: 48px !default;
$sidenav-line-height: $sidenav-item-height !default;


// 15. Photo Slider
// ==========================================================================

$slider-bg-color: color('grey', 'base') !default;
$slider-bg-color-light: color('grey', 'lighten-2') !default;
$slider-indicator-color: color('green', 'base') !default;


// 16. Spinners | Loaders
// ==========================================================================

// $spinner-default-color: $secondary-color !default;


// 17. Tabs
// ==========================================================================

// $tabs-underline-color: $primary-color-light !default;
// $tabs-text-color: $primary-color !default;
$tabs-bg-color: #fff !default;


// 18. Tables
// ==========================================================================

// $table-border-color: $border-color !default;
$table-striped-color: rgba(242, 242, 242, 0.5) !default;


// 19. Toasts
// ==========================================================================

$toast-height: 48px !default;
$toast-color: #323232 !default;
$toast-text-color: #fff !default;
$toast-action-color: #eeff41;


// 20. Typography
// ==========================================================================

// $font-regular: 'Avenir Regular',
// sans-serif;
// $font-roman: 'Avenir Roman',
// sans-serif;
// $font-medium: 'Avenir Medium',
// sans-serif;
$off-black: color("grey", "base") !default;
// Header Styles
$h1-fontsize: 2.8rem !default;
$h2-fontsize: 2.1rem !default;
$h3-fontsize: 1.8rem !default;
$h4-fontsize: 1.6rem !default;
$h5-fontsize: 1.4rem !default;
$h6-fontsize: 1.4rem !default;


// 21. Footer
// ==========================================================================

$footer-font-color: #fff !default;
// $footer-bg-color: $primary-color !default;
$footer-copyright-font-color: rgba(255, 255, 255, .8) !default;
$footer-copyright-bg-color: rgba(51, 51, 51, .08) !default;


// 22. Flow Text
// ==========================================================================

$range : $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;


// 23. Collections
// ==========================================================================

$collection-border-color: #e0e0e0 !default;
$collection-bg-color: #fff !default;
// $collection-active-bg-color: $secondary-color !default;
// $collection-active-color: lighten($secondary-color, 55%) !default;
$collection-hover-bg-color: #ddd !default;
// $collection-link-color: $secondary-color !default;
$collection-line-height: 1.5rem !default;


// 24. Progress Bar
// ==========================================================================

// $progress-bar-color: $secondary-color !default;
