.container {
  @include margin-padding(null, null, all, 0 1.5rem);
}

@media (max-width:#{$large-screen-up}) {
  .green-room header.logo p .button {
    @include margin-padding(left, 1.9rem, null, null);
  }
}

@media (max-width:#{$medium-screen-up}) {
  .manage-session.half-center .row {
    @include breakpoint(small) {
      flex-direction: column;
    }
  }

  .sidenav.mystyle {
    width: 100% !important;
  }
}

@media (max-width:#{$small-screen}) {
  .float-sm-none {
    float: none !important;
  }

  .half-center {
    @include margin-padding(all, 1.5rem auto, all, 3rem 2.2rem);
    width: calc(100% - 1.5rem);

    h1 {
      @include margin-padding(bottom, 0, null, null)
    }
  }

  .feedback-popup .rating .mat-list-item .mat-button .material-icons {
    font-size: 3.7rem;
  }

  .material-icons {
    font-size: 2.4rem;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 6rem;
    padding: 0 2rem;
  }

  .payment.half-center {
    .mat-list {
      padding: $no-padding;

      .mat-list-item {
        @include margin-padding(null, null, all, 1.6rem 0);

        .title,
        .description {
          width: 100%;
          float: none;
          display: block;
        }
      }
    }
  }


  .green-room header.logo p .button {
    display: block;
    @include margin-padding(all, 1rem auto 0, null, null);
  }

  .green-room header.logo p span {
    font-size: $h5-fontsize;
  }

  .feedback-popup .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0 .8rem;
  }
}

@media (max-width:#{$medium-screen}) {
  .float-m-none {
    float: none !important;
  }

  .choose-plan .plan-content {
    @include margin-padding(all, 1.5rem 0, null, null);
    overflow: hidden;
  }
}

@media #{$medium-and-up} {

  /* .container {
    width: 85%;
  } */
}

@media #{$large-and-up} {
  /* .container {
    width: 70%;
  } */
}

.col .row {
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }

  &.no-pad-bot {
    padding-bottom: 0;
  }

  &.no-pad-top {
    padding-top: 0;
  }
}

// Mixins to eliminate code repitition
@mixin reset-offset {
  left: auto;
  right: auto;
}

@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }

  &.pull-#{$size}#{$i} {
    right: $perc;
  }

  &.push-#{$size}#{$i} {
    left: $perc;
  }
}

.row {
  @include margin-padding(all, 0 -1.5rem, null, null);

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    float: left;
    box-sizing: border-box;
    padding: 0 $gutter-width / 2;
    min-height: 1px;

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");

      &.s#{$i} {
        width: $perc;
        @include reset-offset;
      }

      $i: $i+1;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("s", $i, $perc);
      $i: $i + 1;
    }

    @media #{$medium-and-up} {
      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.m#{$i} {
          width: $perc;
          @include reset-offset;
        }

        $i: $i+1
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("m", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {
      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.l#{$i} {
          width: $perc;
          @include reset-offset;
        }

        $i: $i+1;
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("l", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$extra-large-and-up} {
      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        &.xl#{$i} {
          width: $perc;
          @include reset-offset;
        }

        $i: $i+1;
      }

      $i: 1;

      @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("xl", $i, $perc);
        $i: $i + 1;
      }
    }
  }
}
